import { css } from 'styled-components';
import { media } from './MediaQuery';

/**
 * Commonly used text sizes
 */
export const TextSize = {
  ExtraLarge: css`
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1.6px;

    ${media.mobile`
      font-size: 40px;
      line-height: 48px;
    `};
  `,
  Large: css`
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1.2px;

    ${media.mobile`
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.8px;
    `};
  `,
  Default: css`
    font-size: 14px;
    line-height: 24px;
  `,
  SemiMedium: css`
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.8px;
  `,
  Medium: css`
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.64px;
  `,
  Small: css`
    font-size: 12px;
    line-height: 16px;
  `,
};
