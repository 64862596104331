import React from 'react';
const SvgMoreIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg
      height={16}
      viewBox="0 0 16 16"
      width={16}
      ref={ref}
      color={color}
      {...props}
      data-testid="more-icon">
      <path
        d="M2.5 9.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5.5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5.5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgMoreIcon;
