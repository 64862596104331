import React from 'react';
import styled, { css } from 'styled-components';

import InfoIcon from '../icons/small/InfoIcon';
import { Colors } from '../Colors';
import { Tooltip } from './Tooltip';
import { TextSize } from '../TextSize';
import { Input } from './Input';
import { StyledTextarea, CharactersLeftText } from './Textarea';
import { SelectElement } from './Dropdown';
import { InputGroupWrapper } from './InputGroup';
import { ToggleContainer } from './Toggle';

/**
 * Inline form field props.
 */
export interface Props {
  className?: string;
  /**
   * Error message under field
   * Setting this also highlights input field
   */
  error?: string;
  /**
   * If field has been touched, will control error rendering
   */
  touched?: boolean;
  /**
   * Info tooltip
   */
  tooltip?: string;
  /**
   * FieldContainer test id
   */
  testId?: string;
}

/**
 * Form input wrapper without label and other help texts - still has tooltip and proper error stylizing.
 */
export const InlineFormField: React.FC<Props> = ({
  className,
  error,
  tooltip,
  children,
  testId,
  ...props
}) => {
  const showError = ('touched' in props ? props.touched || false : true) && !!error;

  return (
    <FieldContainer className={className} error={showError} data-testid={testId}>
      {children}
      {showError && <ErrorMessage data-testid="validation-error" className="validationError">{error}</ErrorMessage>}
      {tooltip && (
        <Tooltip content={tooltip}>
          <TooltipHandle />
        </Tooltip>
      )}
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  position: relative;

  ${(props: { error?: boolean }) =>
    props.error &&
    css`
      ${Input}, ${SelectElement}, ${StyledTextarea}, ${InputGroupWrapper}, .MultiSelect__control {
        box-shadow: 0 0 0 2px ${Colors.Orange(0.72)}, 0 1px 4px 0 ${Colors.DarkGray(0.08)};
      }

      ${CharactersLeftText} {
        display: none;
      }
    `};

  > ${Input /* sc-sel */}, > ${StyledTextarea /* sc-sel */}, > ${SelectElement} {
    width: 100%;
    box-sizing: border-box;
  }

  > ${ToggleContainer} {
    margin-top: 8px;
  }
`;

const ErrorMessage = styled.div`
  ${TextSize.Small};
  color: ${Colors.Orange()};
  margin-top: 8px;
`;

const TooltipHandle = styled(InfoIcon)`
  position: absolute;
  left: 100%;
  top: 6px;

  width: 24px;
  height: 24px;

  margin-left: 16px;
  padding: 4px;
  border-radius: 100%;
  background-color: ${Colors.DarkGray(0.08)};
  outline: none;
`;
