import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, FormikHelpers, Form } from 'formik';
import queryString from 'query-string';
import styled from 'styled-components';

import { useErrorHandler } from '@topia.com/shared/error-handler';
import { useAsyncAction } from '@topia.com/ui-kit/hooks';

import { getAuthenticationType, authenticate } from '../../../api';
import { AuthenticationStatus, AuthenticationType } from '../../../constants';
import { loginValidationSchema } from '../../data/validation-schema';
import { FormPage } from '../FormPage';
import { PasswordPanel } from './PasswordPanel';
import { UsernamePanel } from './UsernamePanel';
import { LoginFormValues } from './types';

enum PanelType {
  Username = 'Username',
  Password = 'Password',
  TwoFA = '2FA',
}

export const SignInView = () => {
  const [getAuthenticationTypeAction] = useAsyncAction(getAuthenticationType);
  const [authenticateAction] = useAsyncAction(authenticate);
  const { onAsyncError } = useErrorHandler();
  const location = useLocation();
  const navigate = useNavigate();
  const [panelType, setPanelType] = useState(PanelType.Username);

  const onUsernameSubmit = async (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>,
  ) => {
    try {
      const { type, ssoId } = await getAuthenticationTypeAction({ email: values.username });
      if (type === AuthenticationType.Sso) {
        const compassLogin = queryString.parse(location.search).compassLogin === 'true';
        void navigate(`/sso/${ssoId}${compassLogin ? `?compassLogin=true` : ''}`);
      } else {
        formikHelpers.resetForm({ values });
        setPanelType(PanelType.Password);
      }
    } catch (error) {
      onAsyncError(error);
    }
  };

  const onPasswordSubmit = async (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>,
  ) => {
    try {
      const { status, details } = await authenticateAction({
        email: values.username,
        password: values.password,
      });

      if (status === AuthenticationStatus.Ok) {
        const hostOverride = queryString.parse(location.search).host;
        navigate(
          `/authenticated?${queryString.stringify({
            ...queryString.parse(location.search),
            ...details,
            ...(hostOverride ? { hostOverride } : {}),
          })}`,
          { replace: true },
        );
      } else if (status === AuthenticationStatus.ExpiredPassword) {
        navigate(
          `/reset-password/${details.token}?${queryString.stringify({
            reason: 'password-expired',
          })}`,
        );
      } else {
        console.error('Unsupported authentication status/flow', status);
      }
    } catch (error) {
      if (error.response.status === 401) {
        const errorData = await error.response.json();

        if (errorData.code === 'EXP_011') {
          navigate('/account-deactivated');
          return;
        }

        formikHelpers.setErrors({
          username: errorData.message,
          password: errorData.message,
        });
      } else {
        onAsyncError(error);
      }
    }
  };

  const onSubmit = async (
    { username, password }: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>,
  ) => {
    if (panelType === PanelType.Username) {
      await onUsernameSubmit({ username, password }, formikHelpers);
      return;
    }

    await onPasswordSubmit({ username, password }, formikHelpers);
  };

  return (
    <FormPage>
      <Formik
        initialValues={{ username: '', password: '' }}
        validateOnBlur={false}
        onSubmit={onSubmit}
        validationSchema={loginValidationSchema[panelType === PanelType.Password ? 1 : 0]}>
        {({ resetForm, values }) => (
          <SignInForm>
            {panelType === PanelType.Username && <UsernamePanel />}
            {panelType === PanelType.Password && (
              <PasswordPanel
                onBack={() => {
                  resetForm({ values });
                  setPanelType(PanelType.Username);
                }}
              />
            )}
          </SignInForm>
        )}
      </Formik>
    </FormPage>
  );
};

const SignInForm = styled(Form)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;
