import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Heading, Spacer } from '@topia.com/topia-ui';
import { ErrorOverlay, media, Spinner } from '@topia.com/ui-kit';
import { useAsync } from '@topia.com/ui-kit/hooks';

import { getUserProducts } from '../../../api';
import { Product } from '../../../constants';
import { UserProduct } from '../../../types';
import { SsoProductPanel } from './SsoProductPanel';

interface Props {
  token: string;
  redirectToApp: (userProduct: UserProduct) => void;
}

const productProperties = (productType: Product) => {
  if ([Product.Go, Product.Compass].includes(productType)) {
    return {
      title: 'Manage my own mobility and travel',
      description: 'Topia Go',
      color: 'var(--topia-ui-tint-sky)',
    };
  }
  return {
    title: 'Manage my team’s mobility program',
    description: 'Topia Suite',
    color: 'var(--topia-ui-tint-navy)',
  };
};

export const ProductSelectionPanel = ({ token, redirectToApp }: Props) => {
  const navigate = useNavigate();
  const getUserProductsCall = useAsync(getUserProducts, { params: { token } });

  const [filteredProducts, setFilteredProducts] = useState<UserProduct[]>([]);

  useEffect(() => {
    if (getUserProductsCall.data) {
      const userProducts = getUserProductsCall.data;
      const scopedFilteredProducts = userProducts.filter(
        ({ product }) =>
          !(
            product === Product.Compass && userProducts.some((item) => item.product === Product.Go)
          ),
      );
      if (scopedFilteredProducts.length === 1) {
        redirectToApp(scopedFilteredProducts[0]);
      }
      setFilteredProducts(scopedFilteredProducts);
    }
  }, [!getUserProductsCall.data]);

  if (getUserProductsCall.error) {
    return (
      <ErrorOverlay
        error={getUserProductsCall.error}
        onClose={() => navigate('/')}
        buttonText="Go to Login"
        title="Failed to login."
      />
    );
  }

  const hasSsoProduct = filteredProducts.some(({ product }) =>
    [Product.RemoteWork, Product.TravelRequest].includes(product),
  );

  if (hasSsoProduct) {
    return (
      <SsoProductPanel
        token={token}
        products={filteredProducts}
        redirectToStandardApp={redirectToApp}
      />
    );
  }

  const standardProducts = filteredProducts.filter(
    ({ product }) => ![Product.RemoteWork, Product.TravelRequest].includes(product),
  );

  return (
    <Panel>
      {(getUserProductsCall.isLoading || standardProducts.length <= 1) && (
        <Spinner label="Loading..." large />
      )}
      {standardProducts.length > 1 &&
        standardProducts.map((userProduct) => {
          const { title, description, color } = productProperties(userProduct.product);
          return (
            <PanelItem
              key={userProduct.product}
              onClick={() => redirectToApp(userProduct)}
              $color={color}>
              <Heading variant="heading1" color="inherit">
                <PanelItemTitle>{title}</PanelItemTitle>&nbsp;→
              </Heading>
              <Spacer size={16} />
              <Heading variant="heading3" color="heading-secondary">
                {description}
              </Heading>
            </PanelItem>
          );
        })}
    </Panel>
  );
};

const Panel = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 64px;
  position: relative;
  min-height: 192px;
  min-width: 392px;
  padding: 32px;
  border-radius: 24px;
  background: var(--topia-ui-color-background);
  transition: width, height 0.3s ease-in-out;
  overflow: hidden;

  ${media.mobile`
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-width: 0;
    min-height: 100%;
    padding: 64px 0 0 0;
    border-radius: 0;
  `};
`;

const PanelItemTitle = styled.span``;

const PanelItem = styled.button.attrs({ type: 'button' })<{ $color: string }>`
  width: 328px;
  min-width: 0;
  padding: 0;
  text-align: left;
  color: ${(props) => props.$color};
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    ${PanelItemTitle} {
      text-decoration: underline;
    }
  }

  ${media.mobile`
    width: 100%;
    padding: 0;
  `};
`;
