import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../Colors';
import ArrowAltIcon from '../../icons/ArrowAltIcon';
import { getTranslationWithDefault } from '../../utility-components/getTranslationWithDefault';
import { Dropdown } from '../Dropdown';

/**
 * Pagination attributes
 */
export interface PaginationDefinition {
  /**
   * Active page
   */
  currentPage: number;
  /**
   * Total amount of items to paginate
   */
  total: number;
  onChange: (currentPage: number, perPage: number) => void;
  perPage?: number;
  perPageOptions?: number[];
  pagesVisible?: number;
}

interface PaginationProps extends PaginationDefinition {
  hidePerPageSwitcher: boolean;
}

const calculatePages = (length: number, current: number, total: number) => {
  if (length === 0) return [];
  if (length === 1) return [current];
  if (total <= length + 4) return Array.from({ length: total }, (_v, i) => i + 1);

  const jumpSize = Math.ceil(length / 2);
  const centerStart = Math.min(Math.max(current - (length - jumpSize), 3), total - length - 1);
  const jumpStart = current <= length + 1 ? 2 : `-${jumpSize}`;
  const jumpEnd = current >= total - length ? total - 1 : `+${jumpSize}`;

  return [1, jumpStart, ...Array.from({ length }, (_v, i) => centerStart + i), jumpEnd, total];
};

/**
 * Table pagination component
 */
export const Pagination: React.FC<PaginationProps> = ({
  total,
  currentPage,
  onChange,
  perPage = 10,
  perPageOptions = [5, 10, 20, 50],
  pagesVisible = 3,
  hidePerPageSwitcher,
}) => {
  const pageCount = Math.ceil(total / perPage);
  const pageItems = calculatePages(pagesVisible, currentPage, pageCount);
  const changePage = (p: number) => onChange(Math.max(1, Math.min(pageCount, p)), perPage);

  const perPageOpts = perPageOptions.filter((p) => p <= total);
  if (perPageOpts.length < perPageOptions.length && perPageOpts[perPageOpts.length - 1] !== total) {
    perPageOpts.push(total);
  }

  const closestPerPage = perPageOpts.reduce((p, c) =>
    Math.abs(c - perPage) < Math.abs(p - perPage) ? c : p,
  );

  return (
    <Wrapper data-testid="pagination-container">
      <PerPageContainer data-testid="results-per-page">
        {!hidePerPageSwitcher && (
          <>
            <PerPageDropdown
              options={perPageOpts.map((n) => ({ value: n.toString(), label: n.toString() }))}
              value={closestPerPage.toString()}
              onChange={(event) => {
                const newPerPage = parseInt(event.target.value, 10);
                const clampedCurrentPage = Math.min(Math.ceil(total / newPerPage), currentPage);
                onChange(clampedCurrentPage, newPerPage);
              }}
            />{' '}
            <span data-testid="items-count-text">
              {getTranslationWithDefault('shared.packages.itemsNumber')} {total}
            </span>
          </>
        )}
      </PerPageContainer>
      {pageCount > 1 && (
        <PageWrapper data-testid="pages-container">
          <PrevButton
            data-testid="prev-button"
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {pageItems.map((p, idx) => (
            <PageItem
              data-testid="page-number"
              active={p === currentPage}
              key={idx}
              onClick={() => changePage(typeof p === 'number' ? p : currentPage + parseInt(p, 10))}>
              {typeof p === 'number' ? p : '…'}
            </PageItem>
          ))}
          <NextButton
            data-testid="next-button"
            onClick={() => changePage(currentPage + 1)}
            disabled={currentPage === pageCount}
          />
        </PageWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`;

const PerPageContainer = styled.div`
  line-height: 24px;
  color: ${Colors.DarkGray(0.4)};
`;

const PerPageDropdown = styled(Dropdown)`
  padding: 6px 12px;
  margin-right: 16px;
  line-height: 20px;
  padding-right: 32px;
`;

const PageWrapper = styled.div`
  display: flex;
`;

const pageItemStyle = css`
  border-radius: 6px;
  padding: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.DarkGray(0.04)};
  }
`;

const NextButton = styled(ArrowAltIcon).attrs({ width: 32, height: 32 })`
  ${pageItemStyle};
  margin-left: 16px;

  ${(props: { disabled: boolean }) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    `};
`;

const PrevButton = styled(NextButton)`
  transform: rotateZ(180deg);
  margin-right: 16px;
`;

const PageItem = styled.div`
  ${pageItemStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  width: auto;
  height: 32px;
  margin: 0 2px;

  ${(props: { active: boolean }) =>
    props.active &&
    css`
      background-color: ${Colors.DarkGray(0.04)};
    `}
`;
