import React, { useState, useRef, useEffect } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Heading, Button, Spacer, Text, Stack, TextField, FormField } from '@topia.com/topia-ui';

const code = Array(6).fill('');

export const TwoFAPanel = ({
  onBack,
  onResend,
}: {
  onBack: () => void;
  onResend: () => Promise<void>;
}) => {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const { values, handleSubmit } = useFormikContext<{ code?: string[] }>();

  useEffect(() => {
    const filtered = values.code?.filter((value) => !isNaN(parseInt(value, 10)));

    if (filtered?.length === 6) {
      handleSubmit();
    }
  }, [values.code?.join('')]);

  return (
    <>
      <Heading as="h1" variant="huge" color="heading-secondary">
        Enter the
        <br />
        <Heading color="heading-primary">passcode.</Heading>
      </Heading>
      <Spacer size={32} />
      <Text>We just sent you an email with a 6-digit one-time passcode.</Text>
      <Spacer size={16} />
      <Field name="code">
        {({ form, field, meta }: FieldProps) => (
          <FormField error={meta.error} touched={meta.touched} variant="inline">
            <Stack spacing={8} direction="horizontal" wrap="no-wrap">
              {code.map((_, index) => {
                return (
                  <Input
                    index={index}
                    focusedIndex={focusedIndex}
                    value={values.code?.[index] || ''}
                    key={index}
                    onFocus={() => setFocusedIndex(index)}
                    onBackspace={() => setFocusedIndex(focusedIndex - 1 || 0)}
                    onChange={(event) => {
                      event.target.value = event.target.value.replace(/\D+/g, '');
                      setFocusedIndex(Math.min(5, focusedIndex + 1));

                      form.setFieldValue(field.name, [
                        ...(values.code || []).slice(0, index),
                        event.target.value,
                        ...(values.code || [])?.slice(index + 1),
                      ]);
                    }}
                    onPaste={(pastedValues: string) => {
                      const filtered = pastedValues
                        .split('')
                        .filter((pastedValue) => !isNaN(parseInt(pastedValue, 10)));
                      form.setFieldValue(field.name, filtered);
                      setFocusedIndex(Math.min(5, filtered.length - 1));
                      if (filtered.length === 6) {
                        form.handleSubmit();
                      }
                    }}
                  />
                );
              })}
            </Stack>
          </FormField>
        )}
      </Field>
      <Spacer size={24} />
      <Button type="submit" size="large" isFullWidth>
        Submit
      </Button>
      <Spacer size={24} />
      <Stack distribution="center" spacing={24}>
        <Button variant="link" onClick={onBack}>
          Back to Sign In
        </Button>
        <Button variant="link" onClick={onResend}>
          Resend Passcode
        </Button>
      </Stack>
    </>
  );
};

const Input = ({
  index,
  focusedIndex,
  onChange,
  value,
  onBackspace,
  onFocus,
  onPaste,
}: {
  index: number;
  focusedIndex: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  onBackspace: () => void;
  onFocus: () => void;
  onPaste: (pastedValue: string) => void;
}) => {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref.current !== document.activeElement && index === focusedIndex) {
      ref?.current?.focus();
    }
  }, [index, focusedIndex]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      onBackspace();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    onPaste(event.clipboardData.getData('text'));
  };

  return (
    <TextFieldWrapper>
      <TextField
        ref={ref}
        maxLength={1}
        name={`code.${index}`}
        key={index}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onPaste={handlePaste}
        inputMode="numeric"
      />
    </TextFieldWrapper>
  );
};

const TextFieldWrapper = styled.div`
  label {
    width: 48px;
    height: 48px;
  }

  input {
    height: 100%;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
  }
`;
