import React from 'react';
import Responsive, { MediaQueryProps, useMediaQuery } from 'react-responsive';
import { css, CSSObject } from 'styled-components';

/**
 * Topia breakpoints
 */
enum Breakpoint {
  Large = 1200,
  Desktop = 992,
  TabletTill = 991,
  TabletFrom = 768,
  MobileTill = 767,
}

const buildCondition = (minWidth?: Breakpoint, maxWidth?: Breakpoint) => {
  const result: string[] = [];
  if (minWidth) result.push(`(min-width: ${minWidth}px)`);
  if (maxWidth) result.push(`(max-width: ${maxWidth}px)`);

  return result.join(' and ');
};

type SupportedSizes = 'large' | 'desktop' | 'tablet' | 'mobile' | 'default';

const conditions = {
  large: buildCondition(Breakpoint.Large),
  desktop: buildCondition(Breakpoint.Desktop),
  tablet: buildCondition(Breakpoint.TabletFrom, Breakpoint.TabletTill),
  mobile: buildCondition(undefined, Breakpoint.MobileTill),
  default: buildCondition(Breakpoint.TabletFrom),
};

const mqWrapper = (param: string) => (
  first: TemplateStringsArray | CSSObject,
  ...interpolations: any[]
) => css`
  @media ${param} {
    ${css(first, ...interpolations)};
  }
`;

/**
 * Hook API for checking matching media query
 */
export const useMedia = (size: SupportedSizes) => useMediaQuery({ query: conditions[size] });

/**
 * Media query helpers
 */
export const media = {
  large: mqWrapper(conditions.large),
  desktop: mqWrapper(conditions.desktop),
  tablet: mqWrapper(conditions.tablet),
  mobile: mqWrapper(conditions.mobile),
  default: mqWrapper(conditions.default),
};

/**
 * Media query components
 */
export const MediaQuery = {
  Large: (props: MediaQueryProps) => <Responsive {...props} minWidth={Breakpoint.Large} />,
  Desktop: (props: MediaQueryProps) => <Responsive {...props} minWidth={Breakpoint.Desktop} />,
  Tablet: (props: MediaQueryProps) => (
    <Responsive {...props} minWidth={Breakpoint.TabletFrom} maxWidth={Breakpoint.TabletTill} />
  ),
  Mobile: (props: MediaQueryProps) => <Responsive {...props} maxWidth={Breakpoint.MobileTill} />,
  Default: (props: MediaQueryProps) => <Responsive {...props} minWidth={Breakpoint.TabletFrom} />,
};
