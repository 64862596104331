import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../variables';
import { media } from '../MediaQuery';
import { Button } from './Button';
import { Colors } from '../Colors';

type UpdateHandler = (setVisibility: (visible: boolean) => void) => () => void;

interface Props {
  updateHandler: UpdateHandler;
  onReload?: () => void;
}

/**
 * Update handler for event source updates
 */
export const createEventSourceUpdateHandler: (url: string, gitCommit: string) => UpdateHandler = (
  url,
  gitCommit,
) => setVisibility => {
  if (!('EventSource' in window) || (module as any).hot) return () => undefined;

  const source = new EventSource(url);
  source.onmessage = ({ data }) => {
    if (data !== gitCommit) {
      console.info('Application updated to %s from %s', data, gitCommit);
      setVisibility(true);
      source.close();
    }
  };

  return () => source.close();
};

/**
 * Show application update notifications
 */
export const UpdateNotification = ({ updateHandler, onReload }: Props) => {
  const [visible, setVisibility] = useState(false);
  useEffect(updateHandler(setVisibility), []);

  if (!visible) return null;

  return (
    <Notification>
      Application update available.
      <Button
        secondary
        onClick={() => {
          if (onReload) onReload();
          window.location.reload(true); // tslint:disable-line deprecation
        }}>
        Reload
      </Button>
    </Notification>
  );
};

const Notification = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 10000;
  margin-left: 32px;
  padding: 8px 16px;

  border-radius: ${BORDER_RADIUS};
  background-color: ${Colors.White()};
  color: ${Colors.NavyBlue()};
  box-shadow: ${DEFAULT_BOX_SHADOW};

  ${media.mobile`
    bottom: 24px;
    right: 24px;
    margin-left: 24px;
  `};

  ${Button} {
    flex-shrink: 0;
    margin-left: 16px;
    background-color: ${Colors.YellowSoda()};
  }
`;
