import React from 'react';
import styled, { css } from 'styled-components';

import DropdownIcon from '../../icons/small/DropdownIcon';
import { ColumnDefinition, SortDefinition } from './types';

interface Props<D> {
  column: ColumnDefinition<D>;
  sort?: SortDefinition<D>;
}

/**
 * Table header cell element
 */
export const InternalTableHeader = <D extends object>({ column, sort }: Props<D>) => {
  const { align = 'left', sortAllowed = true } = column;
  const label = typeof column.label === 'function' ? column.label() : column.label;
  const sortField = 'field' in column ? column.sortField || column.field : column.sortField;

  if (sort && sortAllowed && sortField) {
    const isColumnSorted = sort.column === sortField && sort.direction;
    const nextDirection = sortField !== sort.column || sort.direction === 'desc' ? 'asc' : 'desc';

    return (
      <SortableTh
        style={{ textAlign: align, ...column.cellStyle }}
        onClick={() => sort.onChange({ column: sortField, direction: nextDirection })}>
        {label}
        {isColumnSorted && <SortIcon width={14} height={14} direction={sort.direction || ''} />}
      </SortableTh>
    );
  }

  return <th style={{ textAlign: align, ...column.cellStyle }}>{label}</th>;
};

const SortIcon = styled(DropdownIcon)`
  margin-left: 4px;

  ${(props: { direction: string }) =>
    props.direction === 'asc' &&
    css`
      transform: rotateZ(180deg);
    `}
`;

const SortableTh = styled.th`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
  }
`;
