import React from 'react';
import styled from 'styled-components';
import { CaptionElementProps, DayPickerProps, BeforeAfterModifier } from 'react-day-picker';

import DropdownIcon from '../../icons/small/DropdownIcon';
import { Dropdown } from '../Dropdown';
import { Colors } from '../../Colors';

interface YearMonthSwitcher
  extends Pick<CaptionElementProps, 'localeUtils' | 'date'>,
    Pick<DayPickerProps, 'fromMonth' | 'toMonth' | 'disabledDays'> {
  onChange: (switcherDate: Date) => void;
  defaultYearRange?: number;
}

/**
 * Dropdown header for easy year-month switching
 */
export const YearMonthSwitcher = ({
  date,
  localeUtils,
  fromMonth,
  toMonth,
  onChange,
  defaultYearRange = 100,
  disabledDays,
}: YearMonthSwitcher) => {
  const months: string[] = localeUtils.getMonths();
  const currentYear = new Date().getFullYear();
  const fromYear = (() => {
    if (disabledDays && (disabledDays as BeforeAfterModifier).before) {
      return (disabledDays as BeforeAfterModifier).before.getFullYear();
    }
    if (fromMonth) {
      return fromMonth.getFullYear();
    }
    return currentYear - defaultYearRange;
  })();
  const toYear = (() => {
    if (disabledDays && (disabledDays as BeforeAfterModifier).after) {
      return (disabledDays as BeforeAfterModifier).after.getFullYear();
    }
    if (toMonth) {
      return toMonth.getFullYear();
    }
    return currentYear + defaultYearRange;
  })();

  const years: number[] = [];
  for (let i = fromYear; i <= toYear; i += 1) {
    years.push(i);
  }

  return (
    <div className="DayPicker-Caption">
      <MonthLabel>
        {months[date.getMonth()]} <DropdownIcon color={Colors.DarkGray()} />
        <Dropdown
          options={months.map((m, i) => ({ label: m, value: i.toString() }))}
          value={date.getMonth().toString()}
          onChange={event => {
            const result = new Date();
            result.setMonth(parseInt(event.target.value, 10), 1);
            result.setFullYear(date.getFullYear());
            onChange(result);
          }}
        />
      </MonthLabel>
      <br />
      <YearLabel>
        {date.getFullYear()} <DropdownIcon color={Colors.DarkGray()} />
        <Dropdown
          options={years.map(y => ({ label: y.toString(), value: y.toString() }))}
          value={date.getFullYear().toString()}
          onChange={event => {
            const result = new Date();
            result.setFullYear(parseInt(event.target.value, 10));
            result.setMonth(date.getMonth(), 1);
            onChange(result);
          }}
        />
      </YearLabel>
    </div>
  );
};

const MonthLabel = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    opacity: 0;
  }

  svg {
    margin-left: 4px;
    opacity: 0.24;
  }

  &:hover svg {
    opacity: 1;
  }
`;

const YearLabel = styled(MonthLabel)`
  color: ${Colors.DarkGray(0.24)};

  &:hover {
    color: ${Colors.DarkGray(0.8)};
  }
`;
