import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ky from 'ky';

import { AlertStatus, useAlert } from '@topia.com/ui-kit';
import { useAlertContext } from '@topia.com/ui-kit/components/AlertProvider/AlertProvider';

interface Props {
  onAsyncError: (message?: string) => string;
  dismissAlert: (alertId: string) => void;
}

const ErrorHandlerContext = React.createContext<Props | null>(null);

// tslint:disable-next-line:completed-docs
export const ErrorHandlerProvider = ({ children }: { children: React.ReactNode }) => {
  const showAlert = useAlert();
  const { dismissAlert } = useAlertContext();
  const currentAlertRef = useRef<string>();
  const { t } = useTranslation();

  const dismissCurrentAlert = () => {
    if (currentAlertRef.current) {
      dismissAlert(currentAlertRef.current);
    }

    currentAlertRef.current = undefined;
  };

  useEffect(() => dismissCurrentAlert, []);

  const onAsyncError = (message?: string) => {
    dismissCurrentAlert();

    currentAlertRef.current = showAlert(message || t('shared.packages.errorHandler.message'), {
      status: AlertStatus.Error,
      button: {
        label: t('shared.packages.errorHandler.label'),
        onClick: dismissCurrentAlert,
      },
    });

    return currentAlertRef.current;
  };

  return (
    <ErrorHandlerContext.Provider value={{ dismissAlert, onAsyncError }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};

const useErrorHandlerContext = () => {
  const errorHandlerContext = useContext(ErrorHandlerContext);

  if (!errorHandlerContext) {
    console.error('ErrorHandler cannot be used outside <ErrorHandlerProvider>!');
    throw new Error('Missing ErrorHandler');
  }

  return errorHandlerContext;
};

/**
 * The `onAsyncError` is a hook to handle errors in general manner, which
 * 1. shows at most 1 alert globally,
 * 2. dismisses alert from component when component is de-rendered and the alert was from this component,
 * 3. otherwise shows alerts until user dismisses them.
 */
export const useErrorHandler = () => {
  const errorHandler = useErrorHandlerContext();
  const currentAlertRef = useRef<string>();

  useEffect(
    () => () => {
      if (currentAlertRef.current) {
        errorHandler.dismissAlert(currentAlertRef.current);
      }
    },
    [],
  );

  return {
    onAsyncError: (requestError?: any) => {
      if (requestError && requestError instanceof ky.HTTPError) {
        requestError.response
          .text()
          .then((errorMessage: string) => {
            const response = JSON.parse(errorMessage);
            const message = (() => {
              if (response.errors) {
                return Object.values(response.errors[0]).flat()[0];
              }

              return response.message;
            })();
            currentAlertRef.current = errorHandler.onAsyncError(message);
          })
          .catch(() => {
            currentAlertRef.current = errorHandler.onAsyncError();
          });
      } else {
        currentAlertRef.current = errorHandler.onAsyncError();
      }
    },
  };
};
