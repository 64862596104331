import React from 'react';
import { Link } from 'react-router-dom';
import { Field as FormikField, FieldProps, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Button, TextField, FormField, Stack, Heading, Spinner } from '@topia.com/topia-ui';

export const UsernamePanel = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Wrapper>
      <Heading as="h1" variant="huge" color="heading-secondary">
        Sign in to <br />
        <Heading color="heading-primary">Topia.</Heading>
      </Heading>
      <Stack direction="vertical" spacing={24}>
        <>
          <FormikField name="username">
            {({ field, meta }: FieldProps) => (
              <>
                {isSubmitting ? (
                  <Stack distribution="center">
                    <Spinner />
                  </Stack>
                ) : (
                  <FormField variant="inline" error={meta.error} touched={meta.touched}>
                    <TextField
                      {...field}
                      placeholder="Email"
                      type="email"
                      size="large"
                      autoFocus
                      isFullWidth
                    />
                  </FormField>
                )}
              </>
            )}
          </FormikField>
          <FormikField name="password">
            {({ field }: FieldProps) => <PasswordInput {...field} type="password" />}
          </FormikField>
        </>
        <Button type="submit" variant="accent" size="large" disabled={isSubmitting} isFullWidth>
          Continue
        </Button>
        <Stack distribution="center">
          <Link to="/forgot-password">Forgot Password?</Link>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: 100%;
`;

const PasswordInput = styled.input`
  display: none;
`;
