import React from 'react';
const SvgInfoIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg data-testid="info-icon" height={16} viewBox="0 0 16 16" width={16} ref={ref} color={color} {...props}>
      <path d="M8 5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-.8 2h1.6v7H7.2z" fill="currentColor" />
    </svg>
  ),
);
export default SvgInfoIcon;
