import React from 'react';
import Tippy, { TippyProps } from '@tippy.js/react';
import styled from 'styled-components';

import { Colors } from '../Colors';
import { TextSize } from '../TextSize';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../variables';

interface Props extends TippyProps {
  noPadding?: boolean;
}

/**
 * Popover component - used for interactive content
 */
export const Popover = ({
  noPadding = false,
  duration = [160, 120],
  placement = 'bottom',
  ...props
}: Props) => (
  <StyledTippy
    duration={duration}
    animation="shift-toward"
    interactive
    trigger="click"
    interactiveBorder={8}
    distance={16}
    placement={placement}
    noPadding={noPadding}
    {...props}
  />
);

const TippyWrapper: React.FC<Props> = ({ noPadding, ...props }) => <Tippy {...props} />;

const StyledTippy = styled(TippyWrapper)`
  ${TextSize.Default};

  border-radius: ${BORDER_RADIUS};
  box-shadow: ${DEFAULT_BOX_SHADOW};

  &.tippy-tooltip {
    ${TextSize.Default};
    color: ${Colors.DarkGray()};
    background-color: ${Colors.White()};

    > .tippy-arrow {
      filter: drop-shadow(0 0 0.75rem ${Colors.DarkGray(0.16)});
    }
  }

  .tippy-content {
    padding: ${(props: Props) => (props.noPadding ? 0 : '24px')};
  }

  & .tippy-backdrop {
    background-color: ${Colors.White()};
  }

  &.tippy-tooltip[data-placement^='top'] > .tippy-arrow {
    border-top-color: ${Colors.White()};
  }

  &.tippy-tooltip[data-placement^='bottom'] > .tippy-arrow {
    border-bottom-color: ${Colors.White()};
  }

  &.tippy-tooltip[data-placement^='left'] > .tippy-arrow {
    border-left-color: ${Colors.White()};
  }

  &.tippy-tooltip[data-placement^='right'] > .tippy-arrow {
    border-right-color: ${Colors.White()};
  }
`;
