import React from 'react';
const SvgCheckIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={16} viewBox="0 0 16 16" width={16} ref={ref} color={color} {...props}>
      <path
        d="M2.566 7.434L1.434 8.566 6 13.13l8.566-8.565-1.132-1.132L6 10.87z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgCheckIcon;
