import * as Yup from 'yup';

export const loginValidationSchema = [
  Yup.object().shape({
    username: Yup.string()
      .email('Invalid email address.')
      .required('This field is required.'),
  }),
  Yup.object().shape({
    password: Yup.string().required('This field is required.'),
  }),
];

export const forgotPasswordValidationSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid email address.')
    .required('This field is required.'),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required.'),
  passwordRepeat: Yup.string().required('Repeat password is required.'),
});
