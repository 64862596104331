import React from 'react';
const SvgDeleteIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg
      data-testid="delete-icon"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      ref={ref}
      color={color}
      {...props}>
      <path
        d="M10 5V3h4v2h6v2h-1.125l-.816 13.062a1 1 0 01-.998.938H6.939a1 1 0 01-.998-.938L5.125 7H4V5h6zm6.871 2H7.13l.75 12h8.242l.75-12z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  ),
);
export default SvgDeleteIcon;
