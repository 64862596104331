import { Operator, DatePeriod } from './constants';

export const operatorLabels = {
  [Operator.Equals]: 'shared.packages.equals',
  [Operator.NotEquals]: 'shared.packages.notEquals',
  [Operator.Empty]: 'shared.packages.empty',
  [Operator.NotEmpty]: 'shared.packages.notEmpty',
  [Operator.Contains]: 'shared.packages.contains',
  [Operator.NotContains]: 'shared.packages.notContains',
  [Operator.LessThan]: 'shared.packages.lessThan',
  [Operator.LessThanOrEquals]: 'shared.packages.lessThanOrEquals',
  [Operator.GreaterThan]: 'shared.packages.greaterThan',
  [Operator.GreaterThanOrEquals]: 'shared.packages.greaterThanOrEquals',
  [Operator.Between]: 'shared.packages.between',
  [Operator.NotBetween]: 'shared.packages.notBetween',
  [Operator.In]: 'shared.packages.isIn',
  [Operator.InRange]: 'shared.packages.inRange',
  [Operator.InYear]: 'shared.packages.inYear',
  [Operator.InPast]: 'shared.packages.inPast',
  [Operator.InNext]: 'shared.packages.inNext',
  [Operator.Before]: 'shared.packages.before',
  [Operator.OnOrAfter]: 'shared.packages.onOrAfter',
};

export const datePeriodLabels = {
  [DatePeriod.ThisWeek]: 'shared.packages.thisWeek',
  [DatePeriod.ThisMonth]: 'shared.packages.thisMonth',
  [DatePeriod.ThisQuarter]: 'shared.packages.thisQuarter',
  [DatePeriod.ThisYear]: 'shared.packages.thisYear',
  [DatePeriod.ThisWeekToDate]: 'shared.packages.thisWeekToDate',
  [DatePeriod.ThisMonthToDate]: 'shared.packages.thisMonthToDate',
  [DatePeriod.ThisQuarterToDate]: 'shared.packages.thisQuarterToDate',
  [DatePeriod.ThisYearToDate]: 'shared.packages.thisYearToDate',
  [DatePeriod.Today]: 'shared.packages.timeline.today',
  [DatePeriod.TodayTitleCase]: 'shared.packages.timeline.today',
  [DatePeriod.Tomorrow]: 'shared.packages.tomorrow',
  [DatePeriod.Yesterday]: 'shared.packages.yesterday',
};
