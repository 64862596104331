import React, { useRef, useImperativeHandle } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../Colors';
import SearchIcon from '../icons/SearchIcon';
import CloseIcon from '../icons/small/CloseIcon';
import { BORDER_RADIUS } from '../variables';
import { Input as InputEl } from './Input';

type Props = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'className' | 'value' | 'onChange' | 'onBlur' | 'placeholder' | 'disabled'
>;

export const SearchInput = React.forwardRef<HTMLInputElement | null, Props>(
  ({ className, disabled, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    // @ts-ignore // not how `useImperativeHandle` should be used 🤷‍♂️
    useImperativeHandle(ref, () => inputRef.current);

    const clear = () => {
      if (!inputRef.current) return;
      const descriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
      if (descriptor && descriptor.set) descriptor.set.call(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      inputRef.current.blur();
    };

    return (
      <Wrapper className={className}>
        <Input
          type="text"
          ref={inputRef}
          disabled={disabled}
          prefix={<SearchIconWrapper disabled={disabled} />}
          suffix={
            props.value && <CloseButton disabled={disabled} onClick={() => !disabled && clear()} />
          }
          {...props}
        />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const SearchIconWrapper = styled(SearchIcon)<{ disabled?: boolean }>`
  width: 24px;
  height: 100%;
  cursor: text;
  pointer-events: none;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

const Input = styled(InputEl)`
  width: 100%;
`;

const CloseButton = styled(CloseIcon)<{ disabled?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;

  &:hover {
    color: ${Colors.NavyBlue(0.64)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        color: initial;
      }
    `}
`;
