import React, { useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../Colors';
import MinusIcon from '../../icons/MinusIcon';
import PlusIcon from '../../icons/PlusIcon';

import { ColumnDefinition, SubcontentDefinition } from './types';

interface Props<D, SCK extends keyof D> {
  columns: ColumnDefinition<D>[];
  subcontent?: SubcontentDefinition<D, SCK>;
}

/**
 * Table row subcontent helper hook
 */
export const useRowSubcontent = <D extends object, SCK extends keyof D>({
  columns,
  subcontent,
}: Props<D, SCK>): [ColumnDefinition<D>[], Set<D[SCK]>] => {
  const [internalExpandedRows, setExpandedRows] = useState<Set<D[SCK]>>(new Set());

  if (!subcontent) return [columns, internalExpandedRows];

  const expandedRows = subcontent.expandedRows || internalExpandedRows;

  const toggleExpansion = (key: D[SCK], value: boolean) => {
    if (subcontent.onExpand && subcontent.onCollapse) {
      return value ? subcontent.onExpand(key) : subcontent.onCollapse(key);
    }
    setExpandedRows(value ? new Set([key]) : new Set());
  };

  return [
    [
      {
        label: '',
        cellStyle: {
          position: 'relative',
          padding: 0,
        },
        render(row: D) {
          if (!subcontent.render(row)) {
            return;
          }

          const key = row[subcontent.expansionColumn];
          const isExpanded = expandedRows.has(key);

          return (
            <IconButton
              secondary={subcontent.secondaryButton}
              onClick={() => toggleExpansion(key, !isExpanded)}>
              {isExpanded ? <MinusIcon /> : <PlusIcon />}
            </IconButton>
          );
        },
      },
      ...columns,
    ],
    expandedRows,
  ];
};

const IconButton = styled.div<{ secondary?: boolean }>`
  padding: 6px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: -40px;

  svg {
    padding: 3px;
    border-radius: 100%;
    background-color: ${props => (props.secondary ? Colors.Selection() : Colors.LightBlue())};
    width: 20px;
    height: 20px;
  }
`;
