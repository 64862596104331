import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import * as queryString from 'query-string';

import { useErrorHandler } from '@topia.com/shared/error-handler';
import { Spinner } from '@topia.com/ui-kit';
import { useAsyncAction } from '@topia.com/ui-kit/hooks';

import { validateToken, activate } from '../../../api';
import { ValidateTokenResponse } from '../../../types';
import { FormPage } from '../FormPage';
import { ExpiredPanel } from './ExpiredPanel';
import { PasswordPanel } from './PasswordPanel';

export const ActiveOrResetPasswordView = () => {
  const [validateTokenResponse, setValidateTokenResponse] = useState<ValidateTokenResponse>();
  const { onAsyncError } = useErrorHandler();
  const navigate = useNavigate();
  const location = useLocation();
  const { token = '' } = useParams();
  const [validateTokenAction] = useAsyncAction(validateToken);
  const [activateAction] = useAsyncAction(activate);

  useEffect(() => {
    const validate = async () => {
      try {
        const response = await validateTokenAction({ token });

        if (response.valid && response.sso) {
          if (!response.activated) {
            await activateAction({ token });
          }

          navigate('/');
          return;
        }

        setValidateTokenResponse(response);
      } catch (error) {
        onAsyncError(error);
      }
    };
    void validate();
  }, []);

  if (!validateTokenResponse) {
    return (
      <FormPage>
        <Spinner label="Loading" large />
      </FormPage>
    );
  }

  if (!validateTokenResponse.valid) {
    return (
      <FormPage>
        <ExpiredPanel />
      </FormPage>
    );
  }

  return (
    <FormPage>
      <PasswordPanel
        token={token}
        type={(() => {
          if (queryString.parse(location.search).reason === 'password-expired') {
            return 'password-expired';
          }

          if (!validateTokenResponse.activated) {
            return 'activation';
          }

          return 'password-reset';
        })()}
      />
    </FormPage>
  );
};
