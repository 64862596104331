import React from 'react';
import styled from 'styled-components';
import {
  MenuButton as ReachMenuButton,
  MenuPopover,
  MenuItems,
  MenuListProps,
  MenuLink as ReachMenuLink,
  MenuLinkProps,
} from '@reach/menu-button';
import { forwardRefWithAs } from '@reach/utils';
import { positionDefault, positionRight } from '@reach/popover';

import DropdownIcon from '../icons/small/DropdownIcon';
import { buttonStyle, ButtonProps } from './Button';
import { Colors } from '../Colors';
import { TextSize } from '../TextSize';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../variables';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';

export { Menu, MenuItem } from '@reach/menu-button';

export const MenuLink = forwardRefWithAs<MenuLinkProps & { state?: Record<any, any> }, 'a'>(
  (props, ref) => {
    return <ReachMenuLink {...props} ref={ref} />;
  },
);

/**
 * Expose raw Reach menu button for custom dropdown triggers
 */
export const UnstyledMenuButton = ReachMenuButton;

/**
 * Wraps a DOM button that toggles the opening and closing of the dropdown menu. Must be rendered inside of a <Menu>.
 */
export const MenuButton: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLElement>> = ({
  children,
  ...props
}) => (
  <LabelMenuButton secondary {...props}>
    {children}
    <DropdownIcon aria-hidden />
  </LabelMenuButton>
);

const LabelMenuButton = styled(ReachMenuButton)`
  ${buttonStyle};
  display: flex;
  align-items: center;
  padding-right: 16px;

  svg {
    margin-left: 4px;
  }
`;

/**
 * Same purpose as MenuButton, but for iconic content.
 */
export const IconMenuButton: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLElement>> = ({
  children,
  ...props
}) => <IconStyledButton {...props}>{children}</IconStyledButton>;

const IconStyledButton = styled(UnstyledMenuButton)`
  appearance: none;
  background-color: ${Colors.DarkGray(0.04)};
  border: none;
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  // gets rid of some extra pixels in parent height - see https://stackoverflow.com/a/22300226/4193304
  line-height: 1;
  padding: 4px;
  transition: background-color 240ms ${Easings.ExpoOut};

  &:hover,
  &[aria-expanded='true'] {
    background-color: ${Colors.DarkGray(0.08)};
  }
`;

const UnstyledMenuList = React.forwardRef<
  HTMLDivElement,
  MenuListProps & { align?: 'left' | 'right' }
>(({ portal = true, align = 'left', ...props }, forwardedRef) => (
  <MenuPopover portal={portal} position={align === 'right' ? positionRight : positionDefault}>
    <MenuItems
      data-testid="meatball-menu-items"
      {...props}
      ref={forwardedRef}
      data-reach-menu-list=""
    />
  </MenuPopover>
));

/**
 * Wraps a DOM element that renders the menu items. Must be rendered inside of a <Menu>.
 */
export const MenuList = styled(UnstyledMenuList)`
  &[data-reach-menu-list] {
    display: block;
    white-space: nowrap;
    outline: none;
    box-shadow: ${DEFAULT_BOX_SHADOW};
    background: ${Colors.White()};
    border: none;
    margin: 8px 0;
    border-radius: ${BORDER_RADIUS};
    padding: 8px 0;
  }

  [data-reach-menu-item] {
    cursor: pointer;
    display: block;
    ${TextSize.Default};
    line-height: 24px;
    padding: 4px 32px 4px 16px;
    font-weight: ${FontWeight.SemiBold};
    text-decoration: initial;
    color: ${Colors.DarkGray()};

    &[data-selected] {
      background-color: ${Colors.DarkGray(0.04)};
    }
  }

  [data-reach-menu-item][aria-disabled] {
    color: ${Colors.Disabled()};
  }
`;
