import React from 'react';
import styled from 'styled-components';

import { TextSize } from '../TextSize';
import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';

import { Button } from './Button';
import { formatError, DebugText } from './ErrorMessage';
import { DEFAULT_BOX_SHADOW } from '../variables';

/**
 * Props for Overlay
 */
export interface Props {
  /**
   * Error to show some debug details about
   */
  error?: Error;
  /**
   * Custom button label
   * @default 'Close'
   */
  buttonText?: string;
  /**
   * Button action handler
   */
  onClose: () => any;
  /**
   * Optional title
   */
  title?: React.ReactNode;
  className?: string;
}

/**
 * Overlay for error case - can accept action
 */
export const ErrorOverlay: React.FC<Props> = ({
  children,
  error,
  buttonText = 'Close',
  onClose,
  title,
  className,
}) => (
  <Overlay className={className}>
    <Wrapper data-testid="error-overlay">
      <Message>
        {title && <Title>{title}</Title>}
        {children}
      </Message>
      {error && (
        <DebugInfo>
          {new Date().toISOString()}
          <br />
          {formatError(error)}
        </DebugInfo>
      )}
      <Button onClick={_e => onClose()}>{buttonText}</Button>
    </Wrapper>
  </Overlay>
);

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background-color: ${Colors.White(0.8)};
`;

const Wrapper = styled.div`
  position: relative;
  background-color: ${Colors.White()};
  box-shadow: ${DEFAULT_BOX_SHADOW};
  padding: 32px 40px;
  min-width: 360px;
  max-width: 424px;
`;

const Message = styled.div`
  margin-bottom: 32px;
  ${TextSize.Medium};
  color: ${Colors.NavyBlue()};
`;

const DebugInfo = styled(DebugText)`
  position: absolute;
  right: 8px;
  bottom: 8px;
`;

const Title = styled.div`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 16px;
`;
