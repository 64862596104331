import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { TextSize } from '../TextSize';
import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { Tooltip } from './Tooltip';
import CheckIcon from '../icons/small/CheckIcon';
import { IconButton } from './IconButton';

/**
 * model for Timeline Component (array of)
 */
export interface TimelineItem {
  label: string;
  secondaryLabel?: string;
  date: Date;
  displayOrder?: number,
  important?: boolean; // extra-big and darkened Dot
  confirmed?: boolean; // shows circled checkmark
  grouped?: boolean; // UI use only
}

interface TimelineProps {
  items: TimelineItem[];
  order?: 'asc' | 'desc';
  earlyDate?: Date;
  lateDate?: Date;
  dateFormat?: string;
}

/**
 * Timeline
 */
export const Timeline = ({
  items,
  earlyDate,
  lateDate,
  order = 'asc',
  dateFormat = 'd MMM yyyy',
}: TimelineProps) => {
  const sortConstant = order === 'asc' ? -1 : 1;
  const models = (items || []).sort((a, b) =>
    a.date === b.date ? 0 : a.date < b.date ? sortConstant : sortConstant * -1,
  );
  const isDistant = (model: TimelineItem) =>
    (earlyDate && model.date < earlyDate) || (lateDate && model.date > lateDate);

  return (
    <Wrapper>
      {models.map((model, i) => (
        <Item data-testid="timeline-item" key={i} last={i === models.length - 1}>
          {model.grouped ? <Band /> : <></>}
          <Dot distant={isDistant(model)} important={model.important} />
          <Text distant={isDistant(model)} data-testid={model.label}>
            {model.label}
            <SubText data-testid="item-date">
              {model.secondaryLabel || format(model.date, dateFormat)}
              {model.confirmed && (
                <Tooltip content="Date confirmed.">
                  <ConfirmedCheck>
                    <CheckIcon color={Colors.NavyBlue()} />
                  </ConfirmedCheck>
                </Tooltip>
              )}
            </SubText>
          </Text>
        </Item>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Item = styled.div<{ last: boolean }>`
  border-left: ${props =>
    props.last ? 'solid 2px transparent' : `solid 2px ${Colors.Separator()}`};
  display: flex;
  margin-left: 5px;
  padding-bottom: 16px;
  position: relative;
`;

const Band = styled.div`
  position: absolute;
  left: -9px;
  top: -2px;
  z-index: -1;
  width: 16px;
  height: 75px;
  border-radius: 8px;
  background-color: ${Colors.LightBlue(0.4)};
`;

const Dot = styled.div<{ distant?: boolean; important?: boolean; }>`
  background-color: ${props =>
    props.distant ? Colors.LightGray() : props.important ? Colors.NavyBlue() : Colors.LightBlue()};
  border: solid 2px ${Colors.White()};
  border-radius: 50%;
  height: 12px;
  margin-left: -7px;
  margin-right: 20px;
  width: 12px;
`;

const Text = styled.div<{ distant?: boolean }>`
  margin-top: -6px;
  ${TextSize.Default};
  color: ${Colors.DarkGray()};
  font-weight: ${FontWeight.SemiBold};
`;

const SubText = styled.div<{ distant?: boolean }>`
  display: flex;
  color: ${props => (props.distant ? Colors.LightGray() : Colors.SecondaryGray())};
  font-weight: ${FontWeight.Regular};
`;

const ConfirmedCheck = styled(IconButton)`
  background-color: ${Colors.LightBlue()};
  border-radius: 50%;
  height: 20px;
  margin-left: 10px;
  padding: 0;
  width: 20px;
`;
