import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { TileIcon, Button, Stack, Heading } from '@topia.com/topia-ui';

import { FormPage } from './FormPage';

export const AccountDeactivatedView = () => {
  const navigate = useNavigate();

  return (
    <FormPage>
      <Stack direction="vertical" spacing={56} alignment="center">
        <DeactivatedIcon>
          <TileIcon icon="unavailable" />
        </DeactivatedIcon>
        <Stack direction="vertical" spacing={32}>
          <Content>
            <Heading variant="heading2" as="p" color="heading-secondary">
              <Heading color="heading-primary">Account deactivated.</Heading>
              <br />
              To reactivate your account, please contact your Topia administrator.
            </Heading>
          </Content>
          <Button onClick={() => navigate('/')} variant="accent" size="large" isFullWidth>
            Back to Sign In
          </Button>
        </Stack>
      </Stack>
    </FormPage>
  );
};
const DeactivatedIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 152px;
  width: 152px;
  margin: 0 auto;
  color: var(--topia-ui-tint-navy);
  border-radius: 50%;
  background-color: var(--topia-ui-tint-limestone);
  background-image: linear-gradient(225deg, hsla(0, 0%, 100%, 0.56), hsla(0, 0%, 100%, 0) 100%);

  svg {
    --icon-color: inherit;
  }
`;

const Content = styled.div`
  text-align: center;
`;
