import { useLayoutEffect, useCallback, useState, RefObject } from 'react';

function getElementRect(element: HTMLElement) {
  return element.getBoundingClientRect();
}

export function useElementRect<T extends HTMLElement>(ref: RefObject<T>) {
  const [rect, setRect] = useState<ClientRect | DOMRect | null>(
    ref && ref.current ? getElementRect(ref.current) : null,
  );

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    setRect(getElementRect(ref.current));
  }, [ref]);

  useLayoutEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    handleResize();

    if (typeof ResizeObserver === 'function') {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref.current]);

  return rect;
}
