import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../../Colors';
import { FontWeight } from '../../../FontWeight';
import { TextSize } from '../../../TextSize';
import DeleteIcon from '../../../icons/DeleteIcon';
import ReloadIcon from '../../../icons/ReloadIcon';
import { getTranslationWithDefault } from '../../../utility-components/getTranslationWithDefault';
import { Button } from '../../Button';
import { Checkbox } from '../../Checkbox';
import { Dropdown, SelectElement } from '../../Dropdown';
import { FormField } from '../../FormField';
import { IconButton } from '../../IconButton';
import { Input } from '../../Input';
import { Modal, showModal } from '../../Modal';
import { Tooltip } from '../../Tooltip';
import { SavedViewsDefinition, SavedView } from './types';

interface Props {
  isChanged: boolean;
  definition: SavedViewsDefinition;
  onClose: () => void;
}

/**
 * UI for saving filters
 */
export const SavedViewPane = ({ definition, isChanged, onClose }: Props) => {
  const views = definition.list || [];
  const view = definition.current || views[0];

  return (
    <Container>
      <Title>{getTranslationWithDefault('shared.packages.savedViews')}</Title>
      {views.length === 0 ? (
        <CreateSavedView definition={definition} onClose={onClose} />
      ) : (
        <InnerSavedViewPane
          definition={definition}
          views={views}
          onClose={onClose}
          view={view}
          whichChanged={(isChanged && view.id) || ''}
        />
      )}
    </Container>
  );
};

interface InnerProps {
  whichChanged: string;
  definition: SavedViewsDefinition;
  views: SavedView[];
  view: SavedView;
  onClose: () => void;
}

const InnerSavedViewPane = ({ definition, whichChanged, onClose, views, view }: InnerProps) => {
  const [selected, setSelected] = useState<SavedView>(view);
  useEffect(() => {
    if (view.id === selected.id) {
      setSelected(view);
    }
  }, [view]);

  if ((!selected || !selected.id) && views && views[0] && views[0].id) setSelected(views[0]); // re-initialize

  const updating = async () => {
    onClose();
    return selected.isReadOnly ? definition.onSelect(selected) : definition.onUpdate(selected);
  };

  const deleting = async () => {
    const yes = await showDeleteModal();
    if (yes) {
      onClose();
      const reloadedViews = await definition.onDelete(selected);
      const defaultView =
        reloadedViews!.find(
          ({ isDefault, isReadOnly }) => (isDefault && !isReadOnly) || isDefault,
        ) || reloadedViews![0];

      if (defaultView) {
        setSelected(defaultView);
        definition.onSelect(defaultView);
      }
    }
  };

  const savingAs = async () => {
    const sv = await showModal<SaveAsResult>((closeModal) => (
      <SaveAs done={closeModal} views={views} />
    ));
    if (sv) {
      onClose();
      const newView = { ...selected, name: sv.name, isDefault: sv.isDefault, isReadOnly: false };
      const list = await definition.onCreate(newView);
      const reselect = list!.find((v) => v.name === newView.name);
      if (reselect) {
        setSelected(reselect);
        definition.onSelect(reselect);
      }
    }
  };

  const revertFilter = async () => {
    onClose();
    return definition.onRevert();
  };

  return (
    <>
      <ListWrapper>
        <Dropdown
          options={views
            .map((sv) => ({ value: sv.id, label: sv.name || '' }))
            .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : +1))}
          value={selected.id}
          onChange={(event) => {
            const newId = event.target.value;
            setSelected(views.find((v) => v.id === newId)!);
          }}
        />
        {selected.id === whichChanged ? (
          <IconButton large onClick={revertFilter}>
            <Tooltip content={getTranslationWithDefault('shared.packages.revert')}>
              <ReloadIcon />
            </Tooltip>
          </IconButton>
        ) : (
          <DisableableIconButton large onClick={(_) => deleting()} disabled={selected.isReadOnly}>
            <DeleteIcon />
          </DisableableIconButton>
        )}
      </ListWrapper>
      <SetDefaultWrapper disabled={selected.isReadOnly}>
        <Checkbox
          label={getTranslationWithDefault('shared.packages.setAsPreferredView')}
          disabled={selected.isReadOnly}
          value={selected.isDefault || false}
          onChange={(checked) => {
            selected.isDefault = checked;
            setSelected({ ...selected });
          }}
        />
      </SetDefaultWrapper>
      <ButtonWrapper>
        {selected.id === whichChanged && (
          <Button secondary onClick={savingAs}>
            {getTranslationWithDefault('shared.packages.saveAs')}
          </Button>
        )}
        {selected.id === whichChanged && (
          <Button disabled={selected.isReadOnly} onClick={updating}>
            {getTranslationWithDefault('shared.packages.update')}
          </Button>
        )}
        {selected.id !== whichChanged && (
          <Button secondary onClick={updating}>
            {getTranslationWithDefault('shared.packages.apply')}
          </Button>
        )}
      </ButtonWrapper>
    </>
  );
};

const CreateSavedView = ({
  definition,
  onClose,
}: {
  definition: SavedViewsDefinition;
  onClose: () => void;
}) => {
  const [name, setName] = useState('');
  const [isDefault, setIsDefault] = useState(false);

  return (
    <>
      <TitleNote>{getTranslationWithDefault('shared.packages.savedViewExplanation')}</TitleNote>
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={getTranslationWithDefault('shared.packages.name')}
      />
      <SetDefaultWrapper>
        <Checkbox
          label={getTranslationWithDefault('shared.packages.setAsPreferredView')}
          value={isDefault}
          onChange={setIsDefault}
        />
      </SetDefaultWrapper>
      <ButtonWrapper>
        <Button secondary onClick={onClose}>
          {getTranslationWithDefault('shared.packages.cancel')}
        </Button>
        <Button onClick={() => definition.onCreate({ name, isDefault }).then(onClose)}>
          {getTranslationWithDefault('shared.packages.save')}
        </Button>
      </ButtonWrapper>
    </>
  );
};

type SaveAsResult = Pick<SavedView, 'name' | 'isDefault'> | undefined;

const SaveAs = ({ done, views }: { done: (x?: SaveAsResult) => void; views: SavedView[] }) => {
  const [name, setName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const isExist = views.some((view) => view.name === name);

  return (
    <ModalAboveTippy>
      <Modal
        visible
        title={getTranslationWithDefault('shared.packages.newSavedView')}
        actions={
          <>
            <Button size="large" secondary onClick={(_) => done()}>
              {getTranslationWithDefault('shared.packages.cancel')}
            </Button>
            <Button
              size="large"
              disabled={isExist || name === ''}
              onClick={(_) => done({ name, isDefault })}>
              {getTranslationWithDefault('shared.packages.save')}
            </Button>
          </>
        }>
        <FormMatting>
          <FormField
            label={getTranslationWithDefault('shared.packages.name')}
            error={
              isExist
                ? getTranslationWithDefault('shared.packages.error.nameInUse')
                : undefined || name.length === 0
                ? getTranslationWithDefault('shared.packages.fieldIsRequired')
                : undefined
            }
            touched>
            <Input value={name} onChange={(event) => setName(event.target.value)} />
          </FormField>
          <FormField label="">
            <Checkbox
              label={getTranslationWithDefault('shared.packages.setAsPreferredView')}
              value={isDefault}
              onChange={setIsDefault}
            />
          </FormField>
        </FormMatting>
      </Modal>
    </ModalAboveTippy>
  );
};

const showDeleteModal = async () =>
  showModal<boolean>((closeModal) => (
    <ModalAboveTippy>
      <Modal
        visible
        actions={
          <>
            <Button size="large" secondary onClick={(_) => closeModal(false)}>
              {getTranslationWithDefault('shared.packages.notNow')}
            </Button>
            <Button size="large" onClick={(_) => closeModal(true)}>
              {getTranslationWithDefault('shared.packages.delete')}
            </Button>
          </>
        }>
        <Text>{getTranslationWithDefault('shared.packages.deleteThisView')}</Text>
      </Modal>
    </ModalAboveTippy>
  ));

const Container = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 32px;
  width: 288px;

  ${SelectElement} {
    width: 100%;
  }
`;

const Title = styled.div`
  ${TextSize.SemiMedium};
  font-size: 20px;
  font-weight: ${FontWeight.Bold};
  color: ${Colors.NavyBlue()};
  margin-bottom: 8px;
`;

const TitleNote = styled.div`
  ${TextSize.Small};
  color: ${Colors.DarkGray(0.48)};
  margin-bottom: 16px;
`;

const Text = styled.p`
  ${TextSize.SemiMedium};
  font-weight: ${FontWeight.Bold};
  color: ${Colors.NavyBlue()};
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 8px;
    flex-shrink: 0;
  }

  > *:first-child {
    width: 100%;
  }
`;

const DisableableIconButton = styled(IconButton)`
  :disabled {
    opacity: 0.2;
  }
`;

const SetDefaultWrapper = styled.div<{ disabled?: boolean }>`
  margin-top: 16px;
  opacity: ${(p) => (p.disabled ? 0.2 : 1.0)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -4px;

  button {
    flex: 1;
    margin: 16px 4px 0 4px;
  }
`;

const ModalAboveTippy = styled.div`
  position: relative;
  z-index: 12001;
`;

const FormMatting = styled.div`
  margin-top: 24px;

  > * {
    margin-bottom: 16px;
  }
`;
