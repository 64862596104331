import React from 'react';
import { Title } from 'react-head';
export { HeadProvider, Link, Style, Meta } from 'react-head';

interface Props {
  children?: string;
}

const PageTitle = ({ children }: Props) => (
  <Title>{children ? `${children} - Topia` : 'Topia'}</Title>
);

export default PageTitle;
