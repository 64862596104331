import styled from 'styled-components';

import { Colors, Toggle, FontWeight } from '@topia.com/ui-kit';

export const MiniToggle = styled(Toggle)`
  display: flex;
  font-size: 12px;
  font-weight: ${FontWeight.Regular};
  color: ${Colors.DarkGray(0.4)};

  ${Toggle.Knob} {
    width: 32px;
    height: 16px;

    &::after {
      width: calc(50% - 4px);
      height: calc(100% - 4px);
      margin: 2px;
    }
  }

  input:checked + ${Toggle.Knob} {
    background-color: ${Colors.LightBlue()};
  }

  input[disabled]:checked + ${Toggle.Knob} {
    background-color: ${Colors.LightBlue(0.4)};
  }
`;
