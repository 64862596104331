import React, { useEffect, useRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { useTransition, animated } from 'react-spring';

import CloseIcon from '../icons/small/CloseIcon';
import { Colors } from '../Colors';
import { useKeyPress, useOnClickOutside } from '../hooks';
import { BORDER_RADIUS_LARGE, DEFAULT_BOX_SHADOW } from '../variables';
import { TextSize } from '../TextSize';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';
import { media } from '../MediaQuery';

// tslint:disable-next-line:completed-docs
export const MODAL_BODY_PADDING = '32px';

interface Props {
  id?: string;
  className?: string;
  /**
   * Show or hide the modal
   */
  visible: boolean;
  /**
   * Show X button for closing
   * @default false
   */
  closeButton?: boolean;
  /**
   * When user presses escape, clicks on background or X button
   */
  onClose?: () => void;
  /**
   * Action buttons
   */
  actions?: React.ReactNode;
  /**
   * Title
   */
  title?: React.ReactNode;
}

type ModalType<P> = React.FunctionComponent<P> & {
  ButtonGroup: typeof ButtonGroup;
};

/**
 * Modal component
 */
export const Modal: ModalType<Props> = ({
  visible,
  id,
  className,
  children,
  actions,
  title,
  onClose = () => undefined,
  closeButton = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useKeyPress('escape', onClose);
  useOnClickOutside(containerRef, onClose);

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [visible]);

  const transitions = useTransition(visible, null, {
    config: { tension: 500 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { to: { opacity: 0 }, duration: 0 },
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Wrapper key={key} style={props} className="modal-wrapper">
              <Container
                id={id}
                className={className}
                ref={containerRef}
                data-testid="modal-container">
                {closeButton && (
                  <CloseButton
                    id={id && `${id}-close`}
                    className="modal-close-btn"
                    onClick={onClose}>
                    <CloseIcon />
                  </CloseButton>
                )}
                {title && <Title>{title}</Title>}
                <Content>{children}</Content>

                {actions && <ButtonGroup>{actions}</ButtonGroup>}
              </Container>
            </Wrapper>
          ),
      )}
    </>
  );
};

/**
 * TODO FIXME delete this.
 *
 * modal-as-a-promise. Accepts function and returns a promise.
 * The inputted function accepts closeFunction & returns React Component.
 * The react component calls the closeFunction with the modal's "answer".
 * If your component doesn't need hooks then you can inline the component.
 * Still must wrap your component in <Modal></Modal>
 */
export async function showModal<T>(
  getComponent: (closeModal: (answer?: T) => void) => JSX.Element,
) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  return new Promise<T>(closeModal => render(getComponent(closeModal), div)).finally(
    () => unmountComponentAtNode(div) && document.body.removeChild(div),
  );
}

const moveDown = keyframes`
  from { transform: translateY(-24px); }
  to { transform: translateY(0); }
`;

const Container = styled.div`
  position: relative;
  z-index: 2;
  height: auto;
  max-height: 100%;
  min-width: 568px;
  padding: ${MODAL_BODY_PADDING};
  background-color: ${Colors.White()};
  border-radius: ${BORDER_RADIUS_LARGE};
  overflow: auto;
  box-shadow: ${DEFAULT_BOX_SHADOW};
  animation: ${moveDown} ${Easings.ExpoOut} 560ms;

  ${media.mobile`
    min-width: 0;
    flex-basis: 568px;
    max-width: 100%;
  `}
`;

const CloseButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
  display: flex;
  padding: 4px;
  background-color: ${Colors.DarkGray(0.08)};
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.DarkGray(0.16)};
  }
`;

const Wrapper = styled(animated.div as any)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1002;
  padding: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: ${Colors.White(0.56)};
`;

export const Title = styled.div`
  ${TextSize.Large};
  color: ${Colors.NavyBlue()};
  font-weight: ${FontWeight.Bold};
  margin-bottom: 16px;
`;

const Content = styled.div``;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: solid 2px ${Colors.Separator()};
  padding-top: 24px;
  margin-top: 56px;

  > *:nth-last-child(2) {
    margin-right: auto !important;
  }

  > *:not(:only-child):not(:last-child) {
    margin-right: 16px;
  }
`;

Modal.ButtonGroup = ButtonGroup;
