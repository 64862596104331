import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Spacer, TileIcon, Heading } from '@topia.com/topia-ui';

export const SuccessPanel = () => {
  const navigate = useNavigate();

  return (
    <Wrapper data-testid="success-panel">
      <SuccessIcon data-testid="success-icon">
        <TileIcon icon="mail" />
      </SuccessIcon>
      <Spacer size={56} />
      <Heading as="p" variant="heading2" data-testid="description">
        Email sent. <br />
        <Heading color="heading-secondary">Check your inbox for further instructions.</Heading>
      </Heading>
      <Spacer size={32} />
      <Button onClick={() => navigate('/')} variant="accent" size="large" isFullWidth>
        Back to Sign In
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const SuccessIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  height: 152px;
  width: 152px;
  color: var(--topia-ui-color-text-light);
  border-radius: 50%;
  background-color: var(--topia-ui-color-success);
  background-image: linear-gradient(225deg, hsla(0, 0%, 100%, 0.56), hsla(0, 0%, 100%, 0) 100%);

  svg {
    --icon-color: inherit;
  }
`;
