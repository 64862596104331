import React from 'react';
const SvgPlusIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      ref={ref}
      color={color}
      {...props}
      data-testid="plus-icon">
      <path d="M13 11h9v2h-9v9h-2v-9H2v-2h9V2h2z" fill="currentColor" />
    </svg>
  ),
);
export default SvgPlusIcon;
