import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import * as queryString from 'query-string';

import { useErrorHandler } from '@topia.com/shared/error-handler';
import { ErrorOverlay, useAlert } from '@topia.com/ui-kit';
import { useAsyncAction } from '@topia.com/ui-kit/hooks';

import config from '../../../../config';
import { resendPasscode, validatePasscode } from '../../../api';
import { Product } from '../../../constants';
import { getLoginHostOverride } from '../../../shared/utils/loginHost';
import { UserProduct } from '../../../types';
import { FormPage } from '../FormPage';
import { ProductSelectionPanel } from './ProductSelectionPanel';
import { TermsAndConditionsPanel } from './TermsAndConditionsPanel';
import { TwoFAPanel } from './TwoFAPanel';
import { CompassLoginPanel } from './CompassLoginPanel';

enum PanelType {
  TermsAndCondition = 'TERMS_AND_CONDITIONS',
  ProductSelection = 'PRODUCT_SELECTION',
  TwoFA = '2FA',
  CompassLogin = 'COMPASS_LOGIN',
}

export const AuthenticatedView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const showAlert = useAlert();
  const [validatePasscodeAction] = useAsyncAction(validatePasscode);
  const [resendPasscodeAction] = useAsyncAction(resendPasscode);
  const { onAsyncError } = useErrorHandler();

  const params = queryString.parse(location.search);
  const token = params.token as string;
  const customerName = params.customerName as string;
  const eulaCustomUrl = params.eulaCustomUrl as string;
  const compassShortSessionToken = params.compassShortSessionToken as string;
  const eulaRequired = params.eulaRequired === 'true';
  const twoFactorRequired = params.twoFactorRequired === 'true';  
  const compassLogin = params.compassLogin === 'true';

  const getPanelType = () => {
    if (params.eulaAcceptanceRequired === 'true') {
      if ((eulaCustomUrl === undefined || customerName === undefined) && !eulaRequired) {
        return compassLogin ? PanelType.CompassLogin : PanelType.ProductSelection;
      }
      return PanelType.TermsAndCondition;
    }
    return compassLogin ? PanelType.CompassLogin : PanelType.ProductSelection;
  };

  const [panelType, setPanelType] = useState(twoFactorRequired ? PanelType.TwoFA : getPanelType());

  const redirectToApp = (userProduct: UserProduct) => {
    const { overrideHost, hostOverride } = getLoginHostOverride();
    const host = hostOverride || userProduct.host;

    switch (userProduct.product) {
      case Product.Manage:
        window.location.href = `${host}${
          config.manageContextPathApp
        }/auth/login?${queryString.stringify({
          authCode: token,
          next: params.next || '/',
          ...(overrideHost ? { host } : {}),
        })}`;
        break;
      case Product.Go:
      case Product.Compass:
        window.location.href = `${host}${
          config.goContextPathApp
        }/auth/login?${queryString.stringify({
          authCode: token,
          next: params.next || '/',
          productCode: userProduct.product,
        })}`;
        break;
      default:
        setError(true);
        break;
    }
  };

  if (error) {
    return (
      <ErrorOverlay
        onClose={() => navigate('/')}
        buttonText="Go to Login"
        title="Failed to login."
      />
    );
  }

  const onSubmit = async (
    { code }: { code?: string[] },
    formikHelpers: FormikHelpers<{ code?: string[] }>,
  ) => {
    try {
      const passcode = code?.join('') || '';
      await validatePasscodeAction({ token, passcode });
      setPanelType(getPanelType());
    } catch (error) {
      if (error.response.status === 401) {
        const errorData = await error.response.json();

        formikHelpers.setErrors({
          code: errorData.message,
        });
      } else {
        onAsyncError(error);
      }
    }
  };

  return (
    <>
      {panelType === PanelType.TwoFA && (
        <FormPage>
          <Formik
            initialValues={{ code: Array(6).fill('') }}
            validateOnBlur={false}
            onSubmit={onSubmit}>
            {({ resetForm }) => (
              <Form>
                <TwoFAPanel
                  onBack={() => {
                    resetForm();
                    void navigate('/');
                  }}
                  onResend={async () => {
                    try {
                      resetForm();
                      await resendPasscodeAction({ token });
                      showAlert('New passcode sent');
                    } catch (error) {
                      onAsyncError(error);
                    }
                  }}
                />
              </Form>
            )}
          </Formik>
        </FormPage>
      )}
      {panelType === PanelType.TermsAndCondition && (
        <FormPage>
          <TermsAndConditionsPanel
            onContinue={() => setPanelType(compassLogin ? PanelType.CompassLogin : PanelType.ProductSelection)}
            customUrl={eulaCustomUrl}
            customerName={customerName}
            eulaRequired={eulaRequired}
          />
        </FormPage>
      )}
      {panelType === PanelType.ProductSelection && (
        <ProductSelectionPanel redirectToApp={redirectToApp} token={token} />
      )}
      {panelType === PanelType.CompassLogin && (
        <CompassLoginPanel shortSessionToken={compassShortSessionToken} />
      )}
    </>
  );
};
