import React from 'react';
import styled, { css } from 'styled-components';

import { BORDER_RADIUS_LARGE } from '../variables';
import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';
import { TextSize } from '../TextSize';

interface Props {
  value: React.ReactNode;
  label: string;
  color?: string;
  align?: 'left' | 'center';
  disabled?: boolean;
  testId?: string;
  isClickable?: boolean;
  valueColor?: string;
  smallerText?: boolean;
}

/**
 * Number tile
 */
export const NumberTile = ({ isClickable = true, ...props }: Props) => (
  <TileWrapper
    color={props.color}
    align={props.align}
    disabled={props.disabled}
    data-testid={props.testId}
    $isClickable={isClickable}>
    {React.isValidElement(props.value) ? (
      props.value
    ) : (
      <TileValue $color={props.valueColor} $smallerText={props.smallerText}>
        {props.value}
      </TileValue>
    )}
    <TileLabel data-testclass="tile-label">{props.label}</TileLabel>
  </TileWrapper>
);

const TileWrapper = styled.div<{
  color?: string;
  align?: string | number;
  disabled?: boolean;
  $isClickable?: boolean;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${props => (props.align === 'center' ? 'center' : 'flex-start')};
  padding: 12px 16px;
  color: ${Colors.NavyBlue()};
  background: ${props =>
    `linear-gradient(225deg, rgba(255,255,255,.64) 0%, rgba(255,255,255,0) 100%),
    ${props.color || Colors.LightBlue()}`};
  border-radius: ${BORDER_RADIUS_LARGE};
  transition: all 560ms ${Easings.ExpoOut};
  cursor: ${props => {
    if (props.$isClickable) {
      if (props.disabled) {
        return 'not-allowed';
      }
      return 'pointer';
    }
    return 'default';
  }};

  &:hover {
    ${props => props.$isClickable && css`
    transform: translateY(-1px);
    box-shadow: 0 16px 40px 0 ${Colors.DarkGray(0.08)};`
  }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &:active {
        pointer-events: none;
      }
    `}
`;

const TileValue = styled.div.attrs({
  'data-testclass': 'tile-value',
})<{ $color?: string; $smallerText?: boolean }>`
  ${props => (props.$smallerText ? TextSize.Large : TextSize.ExtraLarge)};
  font-weight: ${FontWeight.Bold};
  margin-bottom: auto;
  color: ${props => props.$color};
`;

const TileLabel = styled.div`
  ${TextSize.Small};
  font-weight: ${FontWeight.SemiBold};
`;
