import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../Colors';
import { TextSize } from '../TextSize';

const TRACK_HEIGHT = 4;
const THUMB_SIZE = 16;

const track = css`
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  min-width: 176px;
  height: ${TRACK_HEIGHT}px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: ${Colors.DarkGray(0.12)};
`;

const thumb = css`
  box-sizing: border-box;
  border: none;
  border-radius: 100%;
  width: ${THUMB_SIZE}px;
  height: ${THUMB_SIZE}px;
  box-shadow: 0 1px 4px 0 ${Colors.DarkGray(0.24)};
  background-color: ${Colors.White()};
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

/**
 * Range input element
 */
export const RangeInput = styled.input.attrs({ type: 'range' })`
  margin: 0;
  min-width: 176px;
  height: ${THUMB_SIZE}px;
  padding: ${(THUMB_SIZE - TRACK_HEIGHT) / 2}px 0;
  background: transparent;
  outline: none;
  cursor: pointer;

  &,
  &::-webkit-slider-thumb {
    appearance: none;
  }

  /* These pseudo styles can only be declared separately */
  &::-webkit-slider-runnable-track {
    ${track}
  }

  &::-moz-range-track {
    ${track}
  }

  &::-ms-track {
    ${track}
    background: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    ${track}
  }

  &::-ms-fill-upper {
    ${track}
  }

  &::-webkit-slider-thumb {
    margin-top: ${(TRACK_HEIGHT - THUMB_SIZE) * 0.5}px;
    ${thumb}
  }

  &::-moz-range-thumb {
    ${thumb}
  }

  &::-ms-thumb {
    margin-top: 0;
    ${thumb}
  }

  &::-ms-tooltip {
    display: none;
  }

  &::-ms-ticks-before {
    display: none;
  }

  &::-ms-ticks-after {
    display: none;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${RangeInput} {
    width: 100%;
  }
`;

const SliderLabel = styled.div`
  margin-left: 12px;
  min-width: 52px;
  text-align: right;
  ${TextSize.Small};
  white-space: nowrap;
  color: ${Colors.DarkGray(0.4)};
`;

interface RangeSliderProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> {
  value: number;
  onChange: (value: number) => void;
  formatLabel?: (value: number) => string | undefined;
}

/**
 * Range slider with label
 */
export const RangeSlider = ({
  formatLabel = (value: number) => value.toString(),
  onChange,
  ...props
}: RangeSliderProps) => (
  <SliderWrapper>
    <RangeInput {...props} onChange={event => onChange(parseFloat(event.target.value))} />
    <SliderLabel>{formatLabel(props.value)}</SliderLabel>
  </SliderWrapper>
);
