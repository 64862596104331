import React from 'react';
import styled from 'styled-components';

import { TileIcon, Stack, Heading } from '@topia.com/topia-ui';

import { FormPage } from './FormPage';

export const AccountLockedView = () => (
  <FormPage>
    <Stack direction="vertical" spacing={56} alignment="center">
      <LockedIcon>
        <TileIcon icon="unavailable" />
      </LockedIcon>
      <Stack direction="vertical" spacing={32}>
        <Content>
          <Heading variant="heading2" as="p" color="heading-secondary">
            <Heading color="heading-primary">Account locked.</Heading>
            <br />
            Please contact a Topia administrator to have your account unlocked.
          </Heading>
        </Content>
      </Stack>
    </Stack>
  </FormPage>
);

const LockedIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 152px;
  width: 152px;
  margin: 0 auto;
  color: var(--topia-ui-tint-navy);
  border-radius: 50%;
  background-color: var(--topia-ui-tint-limestone);
  background-image: linear-gradient(225deg, hsla(0, 0%, 100%, 0.56), hsla(0, 0%, 100%, 0) 100%);

  svg {
    --icon-color: inherit;
  }
`;

const Content = styled.div`
  text-align: center;
`;
