import React from 'react';
import Tippy, { TippyProps } from '@tippy.js/react';
import styled from 'styled-components';

import { Colors } from '../Colors';
import { TextSize } from '../TextSize';
import { FontWeight } from '../FontWeight';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../variables';

interface Props extends Omit<TippyProps, 'arrow'> {
  /**
   * Visual style
   * @default primary
   */
  type?: 'primary' | 'secondary';
}

/**
 * Styled tooltip
 */
export const Tooltip = ({ duration = [160, 120], type = 'primary', ...props }: Props) => (
  <StyledTippy duration={duration} theme={type} animation="shift-toward" {...props} arrow={false} />
);

const StyledTippy = styled(Tippy)`
  ${TextSize.Default};
  font-weight: ${FontWeight.SemiBold};
  text-align: left;

  border-radius: ${BORDER_RADIUS};
  box-shadow: ${DEFAULT_BOX_SHADOW};

  .tippy-content {
    padding: 6px 12px;
    max-width: 320px;
  }

  &.tippy-tooltip,
  .tippy-backdrop {
    background-color: ${({ theme }: TippyProps) =>
      theme === 'secondary' ? Colors.NavyBlue() : Colors.White()};
  }

  &.tippy-tooltip {
    color: ${({ theme }: TippyProps) =>
      theme === 'secondary' ? Colors.White() : Colors.DarkGray()};
  }
`;
