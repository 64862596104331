import { PasswordRules } from '@topia.com/shared/utils/passwordHelpers';

import api from './shared/api';
import {
  AuthenticationTypeResponse,
  UserProduct,
  ValidateTokenResponse,
  AuthenticationResponse,
  ApplicationInfo,
} from './types';

export const authenticate = async (payload: { email: string; password: string }) =>
  api.post('v3/authenticate', { json: payload, timeout: 60000 }).json<AuthenticationResponse>();

export const sendForgotPassword = async (payload: { email: string }) =>
  api.post(`v3/password-reset/send`, { timeout: 60000, json: payload });

export const validateToken = async (params: { token: string }) =>
  api
    .get(`v2/password-reset/validate/${params.token}`, { timeout: 60000 })
    .json<ValidateTokenResponse>();

export const activate = async (payload: { token: string }) =>
  api.post('v1/users/activate', { json: payload, timeout: 60000 });

export const resetPassword = async (payload: { token: string; password: string }) =>
  api.post('v3/password-reset', { timeout: 60000, json: payload });

export const getAuthenticationType = async (payload: { email: string }) =>
  api
    .post('v1/authenticate/type', { json: payload, timeout: 60000 })
    .json<AuthenticationTypeResponse>();

export const getUserProducts = async (payload: { token: string }) =>
  api.post(`v1/products`, { json: payload, timeout: 60000 }).json<UserProduct[]>();

export const getSsoApplicationInfo = async (payload: { token: string }) =>
  api.post('v1/sso-application/info', { json: payload, timeout: 60000 }).json<ApplicationInfo>();

export const getPasswordRules = async (payload: { code: string }) =>
  api.get(`v1/groups/${payload.code}/password/rules`).json<PasswordRules>();

export const findToken = async (payload: { token: string }) =>
  api.post(`v1/users/find/password-reset-token`, { json: payload, timeout: 60000 }).json<{
    group: string;
    topiaPersonId: number;
  }>();

export const validatePassword = async (payload: {
  group: string;
  topiaPersonId: number;
  password: string;
}) =>
  api.post(`v1/groups/${payload.group}/users/${payload.topiaPersonId}/validate-password/new`, {
    json: { password: payload.password },
  });

export const validatePasscode = async (payload: { token: string; passcode: string }) =>
  api.post('v1/passcode/validate', {
    json: payload,
  });

export const resendPasscode = async (payload: { token: string }) =>
  api.post('v1/passcode', {
    json: payload,
  });
