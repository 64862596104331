import React from 'react';
import { ErrorMessage } from './ErrorMessage';
import { ErrorOverlay } from './ErrorOverlay';

interface Props {
  /**
   * Display custom message
   * @default "Unexpected error occured."
   */
  message?: string;
  /**
   * Show <ErrorMessage /> instead of <ErrorOverlay />
   */
  inline?: boolean;
  /**
   * Render custom error component instead
   */
  fallback?: ((error: Error) => React.ReactNode) | React.ReactNode;
}

interface State {
  error: Error | undefined;
}

/**
 * Error boundary wrapper
 */
export class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  state: State = { error: undefined };

  componentDidCatch(error: Error, info: { componentStack: string }) {
    if ((window as any).Rollbar) (window as any).Rollbar.error(error, info);
  }

  render() {
    const { error } = this.state;
    const {
      children,
      fallback,
      inline,
      message = 'An unexpected error has occurred.',
    } = this.props;

    if (!error) return children;
    if (typeof fallback === 'function') return fallback(error);
    if (fallback) return fallback;

    return inline ? (
      <ErrorMessage error={error}>{message}</ErrorMessage>
    ) : (
      <ErrorOverlay
        buttonText="Reload"
        error={error}
        onClose={() => {
          window.location.reload(true); // tslint:disable-line deprecation
        }}>
        {message}
      </ErrorOverlay>
    );
  }
}
