import React from 'react';
const SvgSettingsIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path
        d="M3.126 7a4.002 4.002 0 017.748 0H24v2H10.874a4.002 4.002 0 01-7.748 0H0V7zM7 10a2 2 0 100-4 2 2 0 000 4zm13.874 5H24v2h-3.126a4.002 4.002 0 01-7.748 0H0v-2h13.126a4.002 4.002 0 017.748 0zM17 18a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgSettingsIcon;
