import React from 'react';
import { format } from 'date-fns';

import { toUtcDate } from '../components/DatePickerInput/utils';

const DateFormatter = ({ date }: { date: string }) => (
  <>{format(toUtcDate(new Date(date)), 'dd MMM yy')}</>
);

export default DateFormatter;
