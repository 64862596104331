import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Spinner } from '@topia.com/ui-kit';

export const SsoRedirectView = () => {
  const { ssoId } = useParams();
  const search = useLocation().search;
  const compassLogin = new URLSearchParams(search).get('compassLogin');

  useEffect(() => {
    const relayState = compassLogin ? `?RelayState=${btoa(JSON.stringify({ compassLogin: true }))}` : '';
    window.location.href = `${window.location.origin?.toString() || ''}/api/saml2/authenticate/${ssoId}${relayState}`;
  }, []);

  return <Spinner label="Loading" />;
};
