import ky, { Input, Options } from 'ky';

/**
 * APIError used for logging
 */
class APIError extends Error {
  constructor(response: Response) {
    const url = response.url.replace(window.location.href, '');
    super(`${response.status} ${url}`);
    this.name = 'APIError';
  }
}

const logErrorResponse = async (_i: Input, _: Options, response: Response) => {
  if (window.Rollbar && !response.ok) {
    const body = await response.clone().text();
    const error = new APIError(response);
    window.Rollbar.error(error, { body, correlationId: response.headers.get('correlation-id') });
  }
  return response;
};

/**
 * Default client
 */
export default ky.extend({
  prefixUrl: '/api',
  retry: 1,
  hooks: {
    afterResponse: [logErrorResponse],
  },
});
