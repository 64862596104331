import { createGlobalStyle } from 'styled-components';
// tslint:disable-next-line no-implicit-dependencies
import tippyJsStyles from '!raw-loader!tippy.js/dist/tippy.css';
// tslint:disable-next-line no-implicit-dependencies
import tippyShiftTowardAnimation from '!raw-loader!tippy.js/animations/shift-toward.css';

export const GlobalComponentStyles = createGlobalStyle`
  ${tippyJsStyles};
  ${tippyShiftTowardAnimation};

  [data-reach-menu] {
    font-family: inherit;
  }
`;
