import React from 'react';
const SvgArrowIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg
      height={16}
      viewBox="0 0 16 16"
      data-testid="arrow-icon"
      width={16}
      ref={ref}
      color={color}
      {...props}>
      <path
        d="M3.566 5.434L2.434 6.566 8 12.13l5.566-5.565-1.132-1.132L8 9.87z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgArrowIcon;
