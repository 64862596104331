import React from 'react';
import styled from 'styled-components';
import { NavbarElementProps } from 'react-day-picker';

import ArrowIcon from '../../icons/small/ArrowIcon';
import { BORDER_RADIUS } from '../../variables';
import { Colors } from '../../Colors';

/**
 * Custom arrow navigation
 */
export const NavBarElement = ({
  onPreviousClick,
  onNextClick,
  showNextButton,
  showPreviousButton,
}: NavbarElementProps) => (
  <>
    {showPreviousButton && <PrevMonth onClick={_e => onPreviousClick()} />}
    {showNextButton && <NextMonth onClick={_e => onNextClick()} />}
  </>
);

const PrevMonth = styled(ArrowIcon)`
  position: absolute;
  top: 16px;
  right: 32px;
  width: 24px;
  height: 24px;
  padding: 4px;
  transform: rotateZ(90deg);
  cursor: pointer;
  border-radius: ${BORDER_RADIUS};

  &:hover {
    background-color: ${Colors.DarkGray(0.04)};
  }
`;

const NextMonth = styled(PrevMonth)`
  right: 8px;
  transform: rotateZ(-90deg);
`;
