import { ConfigFunctionHelpers } from '@topia.com/env-config';

/**
 * Frontend configuration to expose through server
 */
export function clientConfiguration({
  getStringFromEnv,
  getBooleanFromEnv,
}: ConfigFunctionHelpers) {
  return {
    uiDebugger: getBooleanFromEnv('UI_DEBUGGER', false),
    logoTag: getStringFromEnv('LOGO_TAG', ''),
    goContextPathApp: getStringFromEnv('GO_CONTEXT_PATH_APP', ''),
    developmentRedirect: getBooleanFromEnv('DEVELOPMENT_REDIRECT', false),
    manageContextPathApp: getStringFromEnv('MANAGE_CONTEXT_PATH_APP', ''),
  };
}
