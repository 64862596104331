export enum AuthenticationStatus {
  Ok = 'OK',
  ExpiredPassword = 'PASSWORD_EXPIRED',
}

export enum AuthenticationType {
  Password = 'PASSWORD',
  Sso = 'SSO',
}

export enum Product {
  Go = 'GO',
  Manage = 'MANAGE',
  Compass = 'COMPASS_EMPLOYEE',
  RemoteWork = 'REMOTE_WORK',
  TravelRequest = 'TRAVEL_REQUEST',
}