import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../Colors';

interface RadioButtonGroupContext {
  name?: string;
  value?: string;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean;
  isInvalid?: boolean;
}

interface RadioButtonProps
  extends React.PropsWithChildren<{}>,
    React.InputHTMLAttributes<HTMLInputElement> {}

const RadioContext = React.createContext<RadioButtonGroupContext | null>(null);

const RadioButton = React.forwardRef(
  ({ children, className, ...props }: RadioButtonProps, ref: React.Ref<HTMLInputElement>) => {
    const radioContext = React.useContext(RadioContext);

    if (!radioContext) {
      throw Error('RadioButtonGroup.RadioButton should be used inside RadioButtonGroup');
    }

    const {
      name,
      value,
      defaultValue,
      isInvalid,
      disabled: isGroupDisabled,
      ...radioButtonGroupProps
    } = radioContext;

    const isDisabled = props.disabled || isGroupDisabled;

    const inputProps = {
      ...radioButtonGroupProps,
      ...props,
      ...(defaultValue ? { defaultChecked: props.value === defaultValue } : {}),
      ...(value || value === '' ? { checked: props.value === value } : {}),
      name,
      disabled: isDisabled,
    };

    return (
      <RadioButtonWrapper className={className}>
        <StyledRadio ref={ref} type="radio" {...inputProps} />
        <StyledChildren
          $checked={inputProps.checked}
          $disabled={inputProps.disabled}
          $isInvalid={isInvalid}>
          {children}
        </StyledChildren>
      </RadioButtonWrapper>
    );
  },
);

export interface RadioButtonGroupProps
  extends Omit<
      React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>,
      'onChange' | 'onFocus' | 'onBlur' | 'defaultValue'
    >,
    RadioButtonGroupContext {}

export function RadioButtonGroup({
  children,
  className,
  name,
  value,
  defaultValue,
  onChange,
  onFocus,
  onBlur,
  disabled,
  required,
  isInvalid,
  ...props
}: RadioButtonGroupProps): JSX.Element {
  return (
    <RadioButtonGroupWrapper {...props}>
      <RadioContext.Provider
        value={{
          name,
          value,
          defaultValue,
          onChange,
          onFocus,
          onBlur,
          disabled,
          required,
          isInvalid,
        }}>
        {children}
      </RadioContext.Provider>
    </RadioButtonGroupWrapper>
  );
}

const RadioButtonGroupWrapper = styled.div`
  display: grid;
  row-gap: 16px;
`;

const RadioButtonWrapper = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  min-width: 0;
  word-break: break-all;

  > * {
    margin: 0;
  }
`;

const StyledRadio = styled.input`
  display: inline;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  background: none;
  box-shadow: none;
  border: none;
  outline: none;
  appearance: none;
  opacity: 0;
  cursor: inherit;
`;

const StyledChildren = styled.span<{
  $checked?: boolean;
  $disabled?: boolean;
  $isInvalid?: boolean;
}>`
  display: block;
  padding: 16px 24px;
  min-height: 64px;
  color: ${Colors.DarkGray()};
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1.2px;
  font-weight: 400;
  cursor: inherit;
  border: ${props =>
    props.$checked ? `2px solid ${Colors.SkyBlue()}` : `2px solid ${Colors.Selection()}`};
  border-radius: 12px;
  transition: border-color 0.3s ease-in-out;

  & > * {
    margin: 0;
  }

  ${({ $checked }) =>
    !$checked &&
    css`
      &:hover {
        border-color: ${Colors.Separator()};
      }
    `};

  &:focus {
    border-color: ${Colors.LightBlue()};
  }

  &:focus-visible {
    border-color: ${Colors.LightBlue()};
  }

  &:focus:not(:focus-visible) {
    border-color: ${Colors.Selection()};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      border-color: ${Colors.Separator()};
      cursor: not-allowed;
      color: ${Colors.Disabled()};
    `};

  ${({ $isInvalid, $disabled }) =>
    $isInvalid &&
    !$disabled &&
    css`
      border-color: ${Colors.Blonde()};
    `};
`;

RadioButtonGroup.RadioButton = RadioButton;
