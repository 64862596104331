import React from 'react';
const SvgFilterIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props} data-testid="filter-icon">
      <path
        d="M3.95 4h16.1a.5.5 0 01.412.783l-5.874 8.089a.5.5 0 00-.088.283v7.962a.5.5 0 01-.757.429l-4-2.4a.5.5 0 01-.243-.43v-5.56a.5.5 0 00-.088-.284L3.538 4.783A.5.5 0 013.951 4zm2.852 2l4.258 5.739c.287.417.44.91.44 1.416v4.713l1 .6v-5.313c0-.505.153-1 .44-1.416L17.198 6z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgFilterIcon;
