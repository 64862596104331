import React from 'react';
import styled, { css } from 'styled-components';

import CloseIcon from '../icons/small/CloseIcon';
import { TextSize } from '../TextSize';
import { BORDER_RADIUS } from '../variables';
import { Colors } from '../Colors';
import { darken } from 'polished';

type Priority = 'low' | 'normal' | 'medium' | 'high' | 'higher' | 'critical';

const priorityColors = {
  low: Colors.DarkGray(0.04),
  normal: Colors.LightBlue(),
  medium: Colors.Blonde(),
  high: Colors.YellowSoda(),
  higher: Colors.Orange(),
  critical: Colors.Red(),
};

type Size = 'small' | 'normal' | 'large';

interface Props {
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Optional remove handler
   */
  onRemove?: () => void;
  /**
   * Declare priority color
   * @default normal
   */
  priority?: Priority;
  /**
   * Declare size
   * @default normal
   */
  size?: Size;
  children: React.ReactText | React.ReactNode;
  className?: string;
  color?: string;
  testId?: string;
  testClass?: string;
}

const getTextColor = (bgColor: string) =>
  [Colors.Red(), Colors.Orange(), Colors.Green(), Colors.NavyBlue(), Colors.SkyBlue()].includes(
    bgColor,
  )
    ? Colors.White()
    : Colors.DarkGray();

/**
 * Simple tag element
 */
export const Tag = ({
  className,
  children,
  onClick,
  onRemove,
  priority = 'normal',
  size = 'normal',
  color,
  testId,
  testClass,
}: Props) => (
  <Container
    className={className}
    bgColor={color ? color : priorityColors[(priority || 'normal').toLowerCase() as Priority]}
    hasOnClick={!!onClick}
    size={size}
    hasRemove={!!onRemove}>
    <Label data-testid={testId} data-testclass={testClass} size={size}>
      {children}
    </Label>
    {onRemove && (
      <CloseButton onClick={onRemove} size={size}>
        <CloseIcon />
      </CloseButton>
    )}
  </Container>
);

const Label = styled.div<{ size: Size }>`
  ${({ size }) => (size === 'small' ? TextSize.Small : TextSize.Default)};
  line-height: 16px;
  padding: ${({ size }) => labelPadding[size]};
  display: flex;
  align-items: center;
`;

const labelPadding = {
  small: '2px 8px',
  normal: '4px 8px',
  large: '8px 12px',
};

const CloseButton = styled.button<{ size: Size }>`
  display: inline-flex;
  border: none;
  outline: none;
  background: transparent;
  padding: ${({ size }) => closeButtonPadding[size]};
  cursor: pointer;

  &:hover {
    opacity: 0.72;
  }

  svg {
    transition: color 80ms;
  }
`;

const closeButtonPadding = {
  small: '0px 4px 0px 2px',
  normal: '0px 8px 0px 4px',
  large: '8px',
};

interface ContainerProperties {
  bgColor: string;
  hasRemove: boolean;
  hasOnClick: boolean;
  size: Size;
}

const Container = styled.div<ContainerProperties>`
  display: inline-flex;
  align-items: center;
  border-radius: ${BORDER_RADIUS};
  background-color: ${({ bgColor }) => bgColor};
  transition: background-color 80ms;

  &,
  svg {
    ${({ bgColor }) => css`
      color: ${getTextColor(bgColor)};
      box-shadow: ${bgColor === Colors.White() ? `inset 0 0 0 1px ${Colors.Selection()}` : 'none'};
    `}
  }

  ${({ hasOnClick, bgColor }) =>
    hasOnClick &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${darken(0.04, bgColor)};
      }
    `};

  ${({ hasRemove }) =>
    hasRemove &&
    css`
      ${Label} {
        padding-right: 0;
      }
    `};
`;
