import React from 'react';
const SvgAlertIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} color={color} {...props}>
      <path
        d="M11 6.5h2v6h-2v-6zM12 19a7 7 0 100-14 7 7 0 000 14zm0 2a9 9 0 110-18 9 9 0 010 18zm0-4a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  ),
);
export default SvgAlertIcon;
