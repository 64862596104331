import styled from 'styled-components';

import { BORDER_RADIUS } from '../variables';
import { Colors } from '../Colors';
import { Easings } from '../Easings';

interface Props {
  className?: string;
  /**
   * Should card float
   */
  floatable?: boolean;
  background?: string;
}

/**
 * Card
 */
export const Card = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 16px 20px;

  border-radius: ${BORDER_RADIUS};
  background-color: ${props => props.background || Colors.White()};
  box-shadow: ${props =>
    props.floatable
      ? `0 1px 2px 0 ${Colors.DarkGray(0.16)},
         0 32px 48px -8px ${Colors.DarkGray(0.08)},
         0 4px 32px 0 ${Colors.DarkGray(0.12)};`
      : `0 1px 2px 0 ${Colors.DarkGray(0.16)},
         0 8px 48px -8px ${Colors.DarkGray(0.04)},
         0 8px 24px 0 ${Colors.DarkGray(0.04)}`};
  cursor: pointer;
  transition: all 560ms ${Easings.ExpoOut};

  &:hover {
    box-shadow: 0 1px 2px 0 ${Colors.DarkGray(0.16)}, 0 8px 48px -8px ${Colors.DarkGray(0.04)},
      0 8px 24px 0 ${Colors.DarkGray(0.08)};
  }
`;
