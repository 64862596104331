import React, { useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import styled, { css, keyframes } from 'styled-components';

import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { TextSize } from '../TextSize';
import { BORDER_RADIUS } from '../variables';

export interface SpinnerProps {
  className?: string;
  /**
   * Optional label to show on the right
   */
  label?: string;
  /**
   * Optional secondary label to show at the bottom, when spinner is large
   */
  secondaryLabel?: string;
  /**
   * Optional large boolean filter to change size of spinner to large
   */
  large?: boolean;
  /**
   * Overlay opacity
   */
  overlayOpacity?: number;
  /**
   * Delay spinner showing
   */
  delay?: number;
  /**
   * Force spinner to be fullscreen
   */
  fullscreen?: boolean;
}

/**
 * Spinner with optional label
 */
export const Spinner = ({
  className,
  overlayOpacity,
  delay = 400,
  label,
  secondaryLabel,
  large,
  fullscreen,
}: SpinnerProps) => {
  const isDelayDefined = typeof delay === 'number';
  const [visible, setVisibility] = useState(!isDelayDefined);

  const transitions = useTransition(visible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    if (!isDelayDefined) return;
    const timer = setTimeout(() => setVisibility(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Overlay
              style={props}
              key={key}
              className={className}
              data-testid="spinner"
              opacity={overlayOpacity}
              $fullscreen={fullscreen}>
              <SpinnerContainer large={large}>
                <SpinnerCircle large={large} />
                {label && <Label large={large}>{label}</Label>}
                {secondaryLabel && <SecondaryLabel>{secondaryLabel}</SecondaryLabel>}
              </SpinnerContainer>
            </Overlay>
          ),
      )}
    </>
  );
};

interface LargeSpinner {
  large?: boolean;
}

const Label = styled.div`
  ${({ large }: LargeSpinner) => {
    return large
      ? css`
          margin-top: 24px;
          margin-bottom: 8px;
          ${TextSize.SemiMedium};
          font-weight: ${FontWeight.Regular};
          color: ${Colors.NavyBlue()};
        `
      : css`
          margin-left: 12px;
          ${TextSize.Small}
          color: ${Colors.DarkGray(0.4)};
        `;
  }}
`;

const SecondaryLabel = styled.div`
  ${TextSize.SemiMedium};
  color: ${Colors.TertiaryGray()};
  font-weight: ${FontWeight.Regular};
`;

interface OverlayProps {
  opacity?: number;
  $fullscreen?: boolean;
}

const Overlay = styled(animated.div as any)`
  position: ${({ $fullscreen }: OverlayProps) => ($fullscreen ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ opacity = 0.8 }: OverlayProps) => Colors.White(opacity)};
`;

/**
 * Spinner container
 */
export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: ${({ large }: LargeSpinner) => (large ? 'column' : 'row')};
  align-items: center;
  padding: 8px 12px;

  background-color: ${Colors.White()};
  font-weight: ${FontWeight.SemiBold};
  border-radius: ${BORDER_RADIUS};
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

/**
 * just the spinning icon itself
 */
export const SpinnerCircle = styled.div`
  font-size: ${({ large }: LargeSpinner) => (large ? '62px' : '16px')};
  border: 2px solid ${Colors.NavyBlue(0.2)};
  border-left-color: ${Colors.NavyBlue()};
  transform: translateZ(0);
  animation: ${rotate} 1s infinite linear;

  &,
  &::after {
    border-radius: 100%;
    width: 1em;
    height: 1em;
  }
`;
