import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer as SortableWrapper,
  SortableElement,
  SortableHandle,
  SortEnd,
} from 'react-sortable-hoc';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';

import { Colors } from '../../Colors';
import { TextSize } from '../../TextSize';
import ListIcon from '../../icons/ListIcon';
import { getTranslationWithDefault } from '../../utility-components/getTranslationWithDefault';
import { Z_INDEX_LARGE } from '../../variables';
import { Button } from '../Button';
import { Card } from '../Card';
import { Checkbox } from '../Checkbox';
import { ItemDefinition, SortableProps } from './types';

interface SortableItemProps {
  key: string;
  label: string | React.ReactNode;
  idx: number;
  handleChange: (idx: number) => void;
  sortable: boolean | undefined;
  disabled?: boolean;
}

interface SortableContainerProps<T> {
  items: ItemDefinition<T | any>[];
  handleChange: (idx: number) => void;
}

const DragHandle = SortableHandle(() => <ListIconWrapper />);

const SortableItem = SortableElement(
  ({ label, handleChange, idx, sortable = false }: SortableItemProps) => (
    <List>
      <Checkbox onChange={() => handleChange(idx)} value={sortable} label={label} />
      <DragHandle />
    </List>
  ),
);

const SortableContainer = SortableWrapper(
  <T extends object>({ items, handleChange }: SortableContainerProps<T>) => {
    return (
      <ListWrapper>
        {items &&
          items.map((item, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              idx={index}
              label={'label' in item ? item.label : item.render()}
              sortable={item.visible}
              handleChange={handleChange}
            />
          ))}
      </ListWrapper>
    );
  },
);

/**
 * Drag and Drop Sortable Component
 */
export const Sortable = <T extends object>({
  items,
  title,
  onApply,
  onCancel,
}: SortableProps<T>) => {
  const {
    // @ts-ignore
    i18n: { resolvedLanguage: lang },
  } = useTranslation();
  const [sortableItems, setSortableItemsState] = useState<ItemDefinition<T | any>[]>([]);

  useEffect(() => {
    if (sortableItems.length > 0) return;
    setSortableItemsState(cloneDeep(items));
  }, [items.length, sortableItems.length]);

  useEffect(() => {
    setSortableItemsState(cloneDeep(items));
  }, [lang]);

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    setSortableItemsState(swapItems(sortableItems, oldIndex, newIndex));
  };

  const handleCheckboxChange = (idx: number) => {
    setSortableItemsState((prevState) => {
      return [...prevState].map((item, index) => ({
        ...item,
        visible: idx === index && !item.fixed ? !item.visible : item.visible,
      }));
    });
  };

  const apply = () => {
    setSortableItemsState(cloneDeep(sortableItems));
    if (onApply) onApply(cloneDeep(sortableItems));
    if (onCancel) onCancel(); // closes popover
  };

  const reset = () => {
    setSortableItemsState(cloneDeep(items));
    if (onCancel) onCancel();
  };

  return (
    <Wrapper>
      <CardWrapper>
        <Header>{title && <Title>{title}</Title>}</Header>
        <StyledSortableContainer
          onSortEnd={onSortEnd}
          useDragHandle
          items={sortableItems}
          handleChange={handleCheckboxChange}
        />
        <ActionContainer>
          <CancelButton onClick={reset}>
            {getTranslationWithDefault('shared.packages.cancel')}
          </CancelButton>
          <ApplyButton onClick={apply}>
            {getTranslationWithDefault('shared.packages.apply')}
          </ApplyButton>
        </ActionContainer>
      </CardWrapper>
    </Wrapper>
  );
};

/**
 * function to move items in array from one position to another
 */
const swapArrayItems = (array: any[], from: number, to: number) => {
  const startIndex = to < 0 ? array.length + to : to;
  const item = array.splice(from, 1)[0];
  array.splice(startIndex, 0, item);
};

/**
 * wrapper around the arrayMoveMutate to prevent moving unsortable items and also sorting the resulting array
 */
const swapItems = (array: any[], from: number, to: number) => {
  const newarray = array.slice();
  swapArrayItems(newarray, from, to);
  return newarray;
};

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
`;

const CardWrapper = styled(Card)`
  min-width: 288px;
  padding: 24px 0px;
  z-index: ${Z_INDEX_LARGE};
  box-sizing: content-box;
  height: auto;
`;

const Header = styled.div`
  padding: 0px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  ${TextSize.Medium};
  margin: 0px;
  color: ${Colors.NavyBlue()};
`;

// const StyledReloadIcon = styled(ReloadIcon)`
//     -webkit-transform: scaleX(-1);
//     float: right;
//     transform: scaleX(-1);
// `;

const ListIconWrapper = styled(ListIcon)`
  cursor: row-resize;
  color: ${Colors.DarkGray(0.4)};
`;

const List = styled.li`
  list-style: none;
  padding: 0px 24px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  user-select: none;
  z-index: ${Z_INDEX_LARGE + 1};
  background-color: ${Colors.White()};
`;

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  background-color: ${Colors.DarkGray(0.04)};
  max-height: 400px;
  overflow-y: auto;
`;

const StyledSortableContainer = styled(SortableContainer).attrs({
  helperClass: 'helper-class',
})`
  &.helper-class {
    box-shadow: 0 0 1px 0 rgba(35, 31, 32, 0.08), 0 4px 16px -2px rgba(35, 31, 32, 0.08),
      0 8px 40px -8px rgba(35, 31, 32, 0.04), 0 24px 40px 0 rgba(35, 31, 32, 0.02) !important;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  width: 100%;
  margin-top: 40px;
`;

const CancelButton = styled(Button)`
  color: ${Colors.NavyBlue()};
  background-color: ${Colors.LightBlue()};
  width: 50%;
  margin-right: 4px;

  &:hover {
    color: ${Colors.NavyBlue()};
  }
`;

const ApplyButton = styled(Button)`
  background-color: ${Colors.NavyBlue()};
  width: 50%;
  margin-left: 4px;
`;
