import { OperatorTypeMapping } from './types';

/**
 * Available column types
 */
export enum FilterColumnType {
  Boolean = 'BOOLEAN',
  String = 'STRING',
  Number = 'NUMBER',
  Date = 'DATE',
  Array = 'ARRAY',
}

/**
 * List of available operators. Note: Operators which work with dropdown are suffixed as "Relative"
 */
export enum Operator {
  /* General */
  Empty = 'EMPTY',
  NotEmpty = 'NOT_EMPTY',
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',

  /* String specific */
  Contains = 'CONTAINS',
  NotContains = 'NOT_CONTAINS',

  /* Number specific */
  LessThan = 'LESS_THAN',
  LessThanOrEquals = 'LESS_THAN_OR_EQUALS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEquals = 'GREATER_THAN_OR_EQUALS',
  Between = 'BETWEEN',
  NotBetween = 'NOT_BETWEEN',

  /* Date specific */
  In = 'IN',
  InRange = 'IN_RANGE',
  InYear = 'IN_YEAR',
  InPast = 'IN_PAST',
  InNext = 'IN_NEXT',
  Before = 'BEFORE',
  OnOrAfter = 'ON_OR_AFTER',
}

/**
 * Whether an operator is absolute or relative or both
 */
export enum OperatorType {
  None = 'NONE', // Used in case of booleans
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE',
  Range = 'RANGE',
}

/**
 * Available relative date periods
 */
export enum DatePeriod {
  ThisWeek = 'THIS_WEEK',
  ThisMonth = 'THIS_MONTH',
  ThisQuarter = 'THIS_QUARTER',
  ThisYear = 'THIS_YEAR',
  ThisWeekToDate = 'THIS_WEEK_TO_DATE',
  ThisMonthToDate = 'THIS_MONTH_TO_DATE',
  ThisQuarterToDate = 'THIS_QUARTER_TO_DATE',
  ThisYearToDate = 'THIS_YEAR_TO_DATE',
  Today = 'TODAY',
  TodayTitleCase = 'Today',
  Yesterday = 'YESTERDAY',
  Tomorrow = 'TOMORROW',
}

/**
 * Operators supported by given type
 */
export const availableOperators = {
  [FilterColumnType.Boolean]: [Operator.Equals],
  [FilterColumnType.String]: [
    Operator.Contains,
    Operator.NotContains,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Empty,
    Operator.NotEmpty,
  ],
  [FilterColumnType.Number]: [
    Operator.Equals,
    Operator.NotEquals,
    Operator.LessThan,
    Operator.LessThanOrEquals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEquals,
    Operator.Between,
    Operator.NotBetween,
  ],
  [FilterColumnType.Date]: [
    Operator.Equals,
    Operator.Empty,
    Operator.NotEmpty,
    Operator.In,
    Operator.InRange,
    Operator.InYear,
    Operator.InPast,
    Operator.InNext,
    Operator.Before,
    Operator.OnOrAfter,
  ],
  [FilterColumnType.Array]: [Operator.Contains, Operator.NotContains],
};

/**
 * Default mapping for all Operators to their types
 */
export const masterOperatorTypeMappings: OperatorTypeMapping = new Map([
  [Operator.Equals, [OperatorType.Absolute, OperatorType.Relative]],
  [Operator.NotEquals, [OperatorType.Absolute, OperatorType.Relative]],
  [Operator.Empty, [OperatorType.None]],
  [Operator.NotEmpty, [OperatorType.None]],
  [Operator.Contains, [OperatorType.Absolute]],
  [Operator.NotContains, [OperatorType.Absolute]],
  [Operator.LessThan, [OperatorType.Absolute]],
  [Operator.LessThanOrEquals, [OperatorType.Absolute]],
  [Operator.GreaterThan, [OperatorType.Absolute]],
  [Operator.GreaterThanOrEquals, [OperatorType.Absolute]],
  [Operator.Between, [OperatorType.Range]],
  [Operator.NotBetween, [OperatorType.Range]],
  [Operator.In, [OperatorType.Relative]],
  [Operator.InRange, [OperatorType.Range]],
  [Operator.InYear, [OperatorType.Absolute]],
  [Operator.InPast, [OperatorType.Relative]],
  [Operator.InNext, [OperatorType.Relative]],
  [Operator.Before, [OperatorType.Absolute, OperatorType.Relative]],
  [Operator.OnOrAfter, [OperatorType.Absolute, OperatorType.Relative]],
]);
