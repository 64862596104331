import * as queryString from 'query-string';

import config from '../../../config';

export const getLoginHostOverride = () => {
  const params = queryString.parse(location.search);
  const hostOverride = (params.hostOverride as string) || '';
  const overrideHost =
    config.developmentRedirect &&
    (hostOverride.startsWith('http://') || hostOverride.startsWith('https://'));
  return { overrideHost, hostOverride: overrideHost ? hostOverride : undefined };
};
