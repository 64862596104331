import React from 'react';
const SvgQuicklookIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props} data-testid="quick-look-icon">
      <path
        d="M12 18.5c-4 0-7.333-2.167-10-6.5 2.667-4.333 6-6.5 10-6.5s7.333 2.167 10 6.5c-2.667 4.333-6 6.5-10 6.5zm0-11c-2.933 0-5.435 1.441-7.605 4.5 2.17 3.059 4.672 4.5 7.605 4.5 2.933 0 5.435-1.441 7.605-4.5-2.17-3.059-4.672-4.5-7.605-4.5zm0 7a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgQuicklookIcon;
