import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Icon, Stack } from '@topia.com/topia-ui';

export const PasswordCriterion = ({
  matches,
  children,
}: PropsWithChildren<{ matches: boolean }>) => (
  <Stack alignment="center" spacing={8} data-testid="password-criterion">
    <StateCheck data-state-checked={matches}>
      <Icon size="small" icon="check" />
    </StateCheck>
    {children}
  </Stack>
);

const StateCheck = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: var(--topia-ui-color-text-light);
  border-radius: 50%;
  background-color: var(--topia-ui-color-slate-200);
  transition: background-color 0.3s ease-in-out;

  &[data-state-checked='true'] {
    background-color: var(--topia-ui-color-success);
  }

  svg {
    --icon-color: inherit;
  }
`;
