"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRawEnvValueMap = exports.getBooleanFromEnv = exports.getNumberFromEnv = exports.getStringFromEnv = exports.BROWSER_KEY = void 0;
/**
 * Key where to look browser config in `window`
 */
exports.BROWSER_KEY = '__CONFIG__';
/**
 * Load env value from process.env or from window object
 */
function getValue(key, required) {
    var value;
    if (process.hasOwnProperty('browser')) {
        var browserValues = window[exports.BROWSER_KEY];
        if (!browserValues)
            throw new Error("window." + exports.BROWSER_KEY + " is undefined, check HTML.");
        value = browserValues[key];
    }
    else {
        value = process.env[key];
    }
    if (required && typeof value === 'undefined') {
        throw new Error("Required env variable " + key + " is undefined!");
    }
    return value;
}
/**
 * Cast string to floating point number
 */
function castToNumber(key, value) {
    var numberValue = parseFloat(value);
    if (typeof numberValue === 'number')
        return numberValue;
    throw new Error("Env variable " + key + ": " + value + " is not numeric!");
}
/**
 * Case string to boolean
 */
function castToBoolean(key, value) {
    if (value === 'true')
        return true;
    if (value === 'false')
        return false;
    throw new Error("Env variable " + key + ": " + value + " is not boolean!");
}
/**
 * Load string value
 */
function getStringFromEnv(key, initial) {
    if (typeof initial === 'undefined')
        return getValue(key, true);
    var value = getValue(key, false);
    return typeof value === 'undefined' ? initial : value;
}
exports.getStringFromEnv = getStringFromEnv;
/**
 * Load number value
 */
function getNumberFromEnv(key, initial) {
    if (typeof initial === 'undefined')
        return castToNumber(key, getValue(key, true));
    var value = getValue(key, false);
    return typeof value === 'undefined' ? initial : castToNumber(key, value);
}
exports.getNumberFromEnv = getNumberFromEnv;
/**
 * Load boolean value
 */
function getBooleanFromEnv(key, initial) {
    if (typeof initial === 'undefined')
        return castToBoolean(key, getValue(key, true));
    var value = getValue(key, false);
    return typeof value === 'undefined' ? initial : castToBoolean(key, value);
}
exports.getBooleanFromEnv = getBooleanFromEnv;
/**
 * Extract raw env values from raw config function
 */
function getRawEnvValueMap(configFunction) {
    var result = {};
    // Do basic existence check
    var checkKeyIfRequired = function (key, initial) {
        if (typeof initial !== 'undefined')
            return;
        if (!process.env[key])
            throw new Error("Required env variable " + key + " is undefined!");
    };
    configFunction({
        getStringFromEnv: function (key, initial) {
            checkKeyIfRequired(key, initial);
            result[key] = process.env[key];
            return '';
        },
        getNumberFromEnv: function (key, initial) {
            checkKeyIfRequired(key, initial);
            result[key] = process.env[key];
            return 0;
        },
        getBooleanFromEnv: function (key, initial) {
            checkKeyIfRequired(key, initial);
            result[key] = process.env[key];
            return false;
        },
    });
    return result;
}
exports.getRawEnvValueMap = getRawEnvValueMap;
