import React from 'react';
const SvgArrowAltIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path
        d="M17.586 13H3v-2h14.586l-7-7L12 2.586 21.414 12 12 21.414 10.586 20z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgArrowAltIcon;
