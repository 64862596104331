import React, { useState, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import { TextSize } from '../TextSize';
import { Colors } from '../Colors';
import MinusIcon from '../icons/MinusIcon';
import PlusIcon from '../icons/PlusIcon';
import { media } from '../MediaQuery';

interface Props {
  /**
   * Element or text next to toggle icon
   */
  title: React.ReactNode;
  /**
   * Open state when in controlled mode, initial open state in uncontrolled mode
   */
  open?: boolean;
  /**
   * Toggle callback - turns off internal state when passed
   */
  onToggle?: (open?: boolean) => void;
}

/**
 * Collapsible section with controlled and uncontrolled mode
 */
export const CollapsibleSection: FunctionComponent<Props> = ({
  title,
  open = false,
  children,
  onToggle,
}) => {
  const [isOpen, setOpen] = useState(open);
  const visible = onToggle ? open : isOpen;

  return (
    <Wrapper>
      <Header
        onClick={() => {
          if (onToggle) onToggle(!visible);
          setOpen(!isOpen);
        }}>
        {visible ? <MinusButton /> : <PlusButton />}
        {title}
      </Header>
      {visible && <Body>{children}</Body>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  ${TextSize.Medium};
  cursor: pointer;
  user-select: none;
  color: ${Colors.NavyBlue()};
`;

const Body = styled.div`
  margin-top: 16px;
  padding-left: 48px;

  ${media.mobile`
    padding-left: 0;
  `};
`;

const btnStyle = css`
  padding: 5px;
  margin-right: 24px;
  border-radius: 100%;
  background-color: ${Colors.LightBlue(0.4)};
`;

const MinusButton = styled(MinusIcon)`
  ${btnStyle};
`;

const PlusButton = styled(PlusIcon)`
  ${btnStyle};
`;
