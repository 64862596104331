import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from '@topia.com/shared/navigation-logo';
import { media } from '@topia.com/ui-kit';

import config from '../../config';

export const Navigation = () => (
  <Wrapper>
    <LogoLink to="/" data-testid="topia-logo-link">
      <Logo logoTag={config.logoTag} />
    </LogoLink>
  </Wrapper>
);

const Wrapper = styled.nav`
  position: fixed;
  left: 0;
  top: 0;
  padding: 32px;

  ${media.mobile`
    position: relative;
    padding: 24px 24px 32px 24px;
  `}
`;

const LogoLink = styled(Link)`
  &:hover svg path {
    fill: var(--topia-ui-color-slate);
  }
`;
