import React from 'react';
import styled from 'styled-components';
import { MenuButtonProps } from '@reach/menu-button';

import { Menu, MenuItem, MenuList, UnstyledMenuButton } from '../MenuButton';
import MoreIcon from '../../icons/small/MoreIcon';
import { BORDER_RADIUS } from '../../variables';
import { Colors } from '../../Colors';

import {
  ColumnDefinition,
  RowAction,
  RowActionDefinition,
  RowGroupAction,
  TableSizeVariant,
} from './types';
import { FontWeight } from '../../FontWeight';

interface Props<D> {
  size: TableSizeVariant;
  columns: ColumnDefinition<D>[];
  rowActions: RowActionDefinition<D>;
}

const instanceOfRowGroupActions = <D extends object>(
  object: any,
): object is RowGroupAction<D>[] => {
  return 'actions' in object?.[0];
};

/**
 * Table row action helper hook
 */
export const useRowAction = <D extends object>({ size, rowActions, columns }: Props<D>) => {
  if (rowActions.length === 0) return columns;

  const renderMenuItem = (row: D, action: RowAction<D>, idx?: number) => (
    <MenuItem
      key={typeof action.label === 'string' ? action.label : idx}
      onSelect={() => action.onClick(row)}
      disabled={action.disabled}
      onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
      {action.label}
    </MenuItem>
  );

  const renderMenuItems = (row: D, actions: RowGroupAction<D>[] | RowAction<D>[]) => {
    if (instanceOfRowGroupActions(actions)) {
      return actions.map((rowGroupAction, i) => (
        <RowGroup key={`${rowGroupAction.label}-${i}`} data-testclass="row-actions-group">
          <RowGroupLabel onClick={e => e.stopPropagation()}>
            <span>{rowGroupAction.label}</span>
          </RowGroupLabel>
          {rowGroupAction.actions.map((action, idx) => renderMenuItem(row, action, idx))}
        </RowGroup>
      ));
    }
    return (actions as RowAction<D>[]).map(action => renderMenuItem(row, action));
  };

  return [
    ...columns,
    {
      label: '',
      cellStyle: {
        padding: 0,
      },
      render(row: D) {
        const actions = typeof rowActions === 'function' ? rowActions(row) : rowActions;
        return (
          <Wrapper data-testid="row-menu-button">
            {actions && actions.length > 0 && (
              <Menu>
                <MenuButton
                  size={size}
                  onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
                  <MoreIcon width={24} height={24} />
                </MenuButton>
                <MenuList>{renderMenuItems(row, actions)}</MenuList>
              </Menu>
            )}
          </Wrapper>
        );
      },
    },
  ];
};

const Wrapper = styled.div`
  position: relative;
  height: 32px;
`;

const RowGroup = styled.div`
  padding: 8px 0;
  font-weight: ${FontWeight.SemiBold};

  > :not(:first-child) {
    font-weight: ${FontWeight.Regular};
  }
`;

const RowGroupLabel = styled.div`
  border-bottom: 1px solid ${Colors.Separator()};
  line-height: 0;
  margin: 10px 0 20px;

  > span {
    margin: 4px 32px 8px 8px;
    background: white;
    padding: 0 8px;
  }
`;

type RowActionMenuButtonProps = React.FC<
  MenuButtonProps & {
    compact?: boolean;
  }
>;

const UnstyledMenuButtonWrapper: RowActionMenuButtonProps = ({ compact, ...props }) => (
  <UnstyledMenuButton {...props} />
);

/**
 * Row action menu button
 */
export const MenuButton = styled(UnstyledMenuButtonWrapper)`
  position: absolute;
  left: 8px;
  top: 50%;
  width: 32px;
  height: ${(props: { size: TableSizeVariant }) => (props.size === 'compact' ? '24px' : '32px')};
  margin-top: ${(props: { size: TableSizeVariant }) =>
    props.size === 'compact' ? '-12px' : '-16px'};
  cursor: pointer;
  padding: 0;
  line-height: 1;
  opacity: 0;
  background: ${Colors.White()};
  border: none;
  border-radius: ${BORDER_RADIUS};

  tr:hover &,
  &[aria-expanded='true'] {
    opacity: 1;
  }

  tr:not(:hover) &[aria-expanded='true'] {
    background: ${Colors.DarkGray(0.04)};
  }
`;
