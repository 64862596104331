import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import CheckIcon from '../icons/CheckIcon';
import MinusIcon from '../icons/MinusIcon';
import { Colors } from '../Colors';
import { TextSize } from '../TextSize';

interface Props {
  id?: string;
  className?: string;
  disabled?: boolean;
  /**
   * Label shown on the right side
   */
  label?: string | React.ReactNode;
  /**
   * Pass in null explicitly to set checkbox indeterminate
   */
  value: boolean | null;
  onChange: (value: boolean, pressedShift: boolean) => void;
  /**
   * Test attribute for test automation
   */
  testId?: string;
}

/**
 * Styled <input type="checkbox"> component
 */
export const Checkbox = ({
  id,
  className,
  value,
  disabled,
  onChange = () => undefined,
  label,
  testId,
}: Props) => {
  const elRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (elRef.current && value === undefined) elRef.current.indeterminate = true;
  }, [value]);

  return (
    <Container id={id} className={className} data-testid={testId || label}>
      <HiddenCheckbox
        ref={elRef}
        checked={value || false}
        disabled={disabled}
        onChange={event => {
          const pressedShift: boolean = (event.nativeEvent as any).shiftKey;
          onChange(event.target.checked, pressedShift);
        }}
      />
      <StyledCheckbox>
        {value === true && <CheckIconWrapper width="16" height="16" disabled={disabled} />}
        {value === null && <MinusIconWrapper width="16" height="16" disabled={disabled} />}
      </StyledCheckbox>
      {label && <Label>{label}</Label>}
    </Container>
  );
};

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  background: ${Colors.White()};
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 ${Colors.DarkGray(0.16)};
  transition: all 150ms;
`;

const CheckIconWrapper = styled(CheckIcon)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};
`;

const MinusIconWrapper = styled(MinusIcon)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};
`;

const Label = styled.label`
  margin-left: 12px;
  flex: 1;

  ${TextSize.Default};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;
