import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Heading, Spacer } from '@topia.com/topia-ui';
import { media } from '@topia.com/ui-kit';

const openApp = (shortSessionToken: string) => {
  // Opens the mobile app if using the in-app browser
  window.sessionId = shortSessionToken;

  // if ipcrenderer exists, this is the laptop app in an in-app browser
  if (window.ipcRenderer) {
    window.ipcRenderer.invoke('NativeSessionManager', { eventAction: 'setSessionId', sessionId: window.sessionId });
  } else {
    // Using an external browser, we need to know if it's a mobile browser.
    const userAgent = window.navigator.userAgent.toLowerCase();
    const mobileKeywords = ['iphone', 'ipod', 'android'];
    
    window.location.href = mobileKeywords.some(keyword => userAgent.includes(keyword))
      ? `https://link.monaeo.com/sso_handoff?sessionId=${shortSessionToken}`
      : `compassauth://?sessionId=${shortSessionToken}`;
  }
};

interface Props {
  shortSessionToken: string;
}

export const CompassLoginPanel = ({ shortSessionToken }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      openApp(shortSessionToken);
    }, 3000);    
  }, []);

  return (
    <Panel>
      <PanelItem>
        <Heading variant="heading1" color="inherit">
          <PanelItemTitle>You are being redirected to the Topia Compass App</PanelItemTitle>
        </Heading>
        <Spacer size={16} />
        <Heading variant="heading3" color="heading-secondary">
          If the app doesn't open, follow this <a onClick={() => openApp(shortSessionToken)}>link</a>.
        </Heading>
      </PanelItem>
    </Panel>
  );
};

const Panel = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 64px;
  position: relative;
  min-height: 192px;
  min-width: 392px;
  padding: 32px;
  border-radius: 24px;
  background: var(--topia-ui-color-background);
  transition: width, height 0.3s ease-in-out;
  overflow: hidden;

  ${media.mobile`
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-width: 0;
    min-height: 100%;
    padding: 64px 0 0 0;
    border-radius: 0;
  `};
`;

const PanelItemTitle = styled.span``;

const PanelItem = styled.span`
  width: 328px;
  min-width: 0;
  padding: 0;
  text-align: left;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;

  ${media.mobile`
    width: 100%;
    padding: 0;
  `};
`;
