import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { media } from '../MediaQuery';
import { TextSize } from '../TextSize';
import { InlineFormField, Props as InlineFormFieldProps } from './InlineFormField';

export type FormFieldVariant = 'columns' | 'inline' | 'inline-label';

interface Props {
  className?: string;
  /**
   * Label text/element
   */
  label: React.ReactNode;
  /**
   * Associate label with input
   */
  labelFor?: string;
  /**
   * Help text/element underneath label
   */
  help?: string | React.ReactNode;
  /**
   * Show optional field label
   */
  optional?: boolean;
  /**
   * Test attribute for test automation
   */
  testId?: string;
  /**
   * Test attribute for test automation
   */
  testClass?: string;
  labelSize?: 'default' | 'large';
  variant?: FormFieldVariant;
}

/**
 * Form input wrapper with label and other help texts
 */

export const FormField: React.FC<Props & Omit<InlineFormFieldProps, 'className'>> = ({
  label,
  labelFor,
  help,
  className,
  optional,
  testId,
  testClass,
  labelSize = 'default',
  variant = 'columns',
  ...props
}) => {
  return (
    <Wrapper
      $variant={variant}
      className={className}
      data-testid={testId}
      data-testclass={testClass}>
      {variant !== 'inline' && (
        <LabelContainer $labelSize={labelSize}>
          <Label htmlFor={labelFor}>
            {label}
            {optional && <OptionalLabel>optional</OptionalLabel>}
          </Label>
          {help && <Help isText={typeof help === 'string'}>{help}</Help>}
        </LabelContainer>
      )}
      <StyledInlineFormField {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $variant: FormFieldVariant }>`
  display: flex;
  flex-wrap: ${({ $variant }) => ($variant === 'columns' ? 'nowrap' : 'wrap')};
  margin-bottom: 24px;

  ${media.mobile`
    flex-wrap: wrap;
  `};
`;

export const LabelContainer = styled.div<{ $labelSize: 'default' | 'large' }>`
  flex-shrink: 0;
  flex-basis: ${({ $labelSize }) => ($labelSize === 'default' ? 152 : 240)}px;
  margin-right: 24px;
  margin-bottom: 8px;

  ${media.mobile`
    flex-basis: 100%;
  `};
`;

const Label = styled.label`
  display: block;
  margin-top: 8px;

  ${TextSize.Default};
  font-weight: ${FontWeight.SemiBold};
`;

const OptionalLabel = styled.sup`
  font-style: italic;
  color: ${Colors.DarkGray(0.4)};
  margin-left: 4px;
  font-size: 10px;
  line-height: 1;
`;

const Help = styled.div<{ isText: boolean }>`
  margin-top: 8px;

  ${TextSize.Small};
  ${({ isText }) =>
    isText &&
    css`
      color: ${Colors.DarkGray(0.56)};
    `}
`;

const StyledInlineFormField = styled(InlineFormField)`
  flex: 1;
`;
