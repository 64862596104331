import React from 'react';
import styled from 'styled-components';

import { getTranslationWithDefault } from '../../utility-components/getTranslationWithDefault';
import { Button } from '../Button';
import { Menu, MenuList, MenuButton, MenuItem } from '../MenuButton';
import { SelectionDefinition } from './types';

interface Props<D, SC extends keyof D> {
  selectedIds: Set<unknown>;
  selection: SelectionDefinition<D, SC> | undefined;
}

/**
 * Selection actions MenuButton
 */
export const SelectionMenu = <D extends object, SC extends keyof D>({
  selectedIds,
  selection,
}: Props<D, SC>) => {
  if (
    selectedIds.size === 0 ||
    !selection ||
    !selection.actions ||
    selection.actions.length === 0
  ) {
    return null;
  }

  if (selection.actions.length === 1) {
    return <SingleActionButton>{selection.actions[0].label}</SingleActionButton>;
  }
  return (
    <Menu>
      <SelectionMenuButton>
        {selectedIds.size} {getTranslationWithDefault('shared.packages.selected')}
      </SelectionMenuButton>
      <MenuList>
        {selection.actions.map((s, idx) => (
          <MenuItem key={idx} onSelect={() => s.onClick(Array.from(selectedIds) as SC[])}>
            {s.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const SelectionMenuButton = styled(MenuButton)`
  margin: 16px 0;
  position: absolute;
`;

const SingleActionButton = styled(Button).attrs({ secondary: true })`
  margin: 16px 0;
  position: absolute;
`;
