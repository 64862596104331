import React from 'react';
import { GroupProps } from 'react-select';
import styled from 'styled-components';

import { FontWeight } from '../../../FontWeight';
import { Colors } from '../../../Colors';
import { HorizontalMenuPadding } from '../constants';

const LabelSideMargins = 8;

/**
 * Multi-select option group heading component
 */
export const GroupHeading = (props: GroupProps<any>) => (
  <Root>
    <LeftLine />
    <Label>{props.children}</Label>
    <RightLine />
  </Root>
);

const Root = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 12px;
`;

const Label = styled.div`
  font-weight: ${FontWeight.SemiBold};
  margin: 0 ${LabelSideMargins}px;
`;

const LeftLine = styled.div`
  height: 1px;
  width: ${HorizontalMenuPadding - LabelSideMargins + 1}px;
  background-color: ${Colors.Separator()};
`;

const RightLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.Separator()};
`;
