import React, { ReactNode } from 'react';
import { FormikErrors } from 'formik';

import { Text } from '@topia.com/topia-ui';

export const labels: Record<string, ReactNode> = {
  pw_formatRules_upperCase: 'A capital letter',
  pw_formatRules_lowerCase: 'A lowercase letter',
  pw_formatRules_number: 'A number',
  pw_formatRules_specialChar: (
    <>
      A symbol <Text variant="secondary">(e.g. !@#$%^)</Text>
    </>
  ),
};

export const formatCustomerPasswordCriteria = (customerCriteria: PasswordRules) => {
  const rules: FormattedRule[] = [];

  if (customerCriteria.pw_formatRules && customerCriteria.pw_formatRules.numberOfValidRules > 0) {
    Object.entries(customerCriteria.pw_formatRules).forEach(([key, value]) => {
      if (typeof value === 'number') return;

      rules.push({
        label: labels[key],
        regex: `(?=.*${value.pattern})`,
      });
    });
  } else {
    rules.push({
      label: `${customerCriteria.pw_min_length.minLength} characters minimum`,
      regex: `.{${customerCriteria.pw_min_length.minLength},}`,
    });
  }

  return rules;
};

export const validatePasswordForm = (
  password: string,
  passwordRepeat: string,
  passwordCriteria: FormattedRule[],
  customerPaswordCriteria: PasswordRules,
) => {
  const errors: FormikErrors<{ password: string; passwordRepeat: string }> = {};

  const numberOfPassedRules = passwordCriteria.reduce(
    (acc, { regex }) => (password.match(regex) ? acc + 1 : acc),
    0,
  );
  if (
    (customerPaswordCriteria.pw_formatRules &&
      customerPaswordCriteria.pw_formatRules.numberOfValidRules > 0 &&
      numberOfPassedRules < customerPaswordCriteria.pw_formatRules.numberOfValidRules) ||
    password.length < customerPaswordCriteria.pw_min_length.minLength
  ) {
    errors.password = 'Password does not meet all requirements.';
  }

  if (password && passwordRepeat && password !== passwordRepeat) {
    errors.passwordRepeat = 'Passwords do not match.';
  }

  return errors;
};

interface Rule {
  message: string;
  pattern: string;
}

interface FormattedRule {
  label: string | ReactNode;
  regex: string;
}

export interface PasswordRules {
  pw_formatRules?: {
    numberOfValidRules: number;
    pw_formatRules_upperCase: Rule;
    pw_formatRules_lowerCase: Rule;
    pw_formatRules_number: Rule;
    pw_formatRules_specialChar: Rule;
  };
  pw_min_length: {
    message: string;
    minLength: number;
  };
}
