import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { animated, SpringValue } from 'react-spring';
import { darken } from 'polished';

import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../../variables';
import { Button } from '../Button';
import { Colors } from '../../Colors';
import InfoIcon from '../../icons/small/InfoIcon';
import CheckIcon from '../../icons/small/CheckIcon';
import CloseIcon from '../../icons/CloseIcon';

import { AlertItem, AlertStatus } from './types';
import { FontWeight } from '../../FontWeight';

interface Props {
  item: AlertItem;
  style?: { opacity: SpringValue<number> };
  onClose?: () => void;
}

/**
 * Alert item
 */
export const Alert = ({ item, style, onClose }: Props) => {
  const Icon = StatusStyling[item.status].icon;

  useEffect(() => {
    if (item.duration && onClose) {
      const timeoutId = setTimeout(onClose, item.duration);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  return (
    <Item status={item.status} style={style || {}}>
      <Icon />
      {item.message}
      {item.button && (
        <Button
          size="small"
          secondary={item.status !== AlertStatus.Critical}
          onClick={() => {
            item.button!.onClick();
            if (onClose) onClose();
          }}>
          {item.button.label}
        </Button>
      )}
      {item.closeable && (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Item>
  );
};

// TODO: Fix the infinite type instantiation bug
const Item = styled(animated.div as any)`
  display: flex;
  align-items: center;
  gap: 8px;
  transform: translate3d(0, 0, 0);
  padding: 8px 12px;
  box-shadow: ${DEFAULT_BOX_SHADOW};
  border-radius: ${BORDER_RADIUS};
  white-space: nowrap;
  font-weight: ${FontWeight.SemiBold};

  ${(props: { status: AlertStatus }) => StatusStyling[props.status].alertStyle}
`;

const iconStyle = css`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 4px;
`;

const StatusStyling = {
  [AlertStatus.Success]: {
    alertStyle: css`
      color: ${Colors.White()};
      background-color: ${Colors.Black()};
    `,
    icon: styled(CheckIcon)`
      ${iconStyle};
      background-color: ${Colors.Green()};
      color: white;
    `,
  },
  [AlertStatus.Error]: {
    alertStyle: css`
      color: ${Colors.White()};
      background-color: ${Colors.Black()};
    `,
    icon: styled(InfoIcon)`
      ${iconStyle};
      background-color: ${Colors.YellowSoda()};
      color: ${Colors.DarkGray()};
      transform: rotateZ(180deg);
    `,
  },
  [AlertStatus.Critical]: {
    alertStyle: css`
      color: ${Colors.Black()};
      background-color: ${Colors.YellowSoda()};
    `,
    icon: styled(InfoIcon)`
      ${iconStyle};
      background-color: ${Colors.DarkGray()};
      color: ${Colors.YellowSoda()};
      transform: rotateZ(180deg);
    `,
  },
};

const IconButton = styled.button`
  cursor: pointer;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: ${Colors.White()};

  &:hover {
    background-color: ${darken(0.04, Colors.White())};
  }
`;
