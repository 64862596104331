import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { media } from '@topia.com/ui-kit';
import PageTitle from '@topia.com/ui-kit/PageTitle';

import { Navigation } from '../shared/Navigation';
import { AccountDeactivatedView } from './components/AccountDeactivatedView';
import { AccountLockedView } from './components/AccountLockedView';
import { ActiveOrResetPasswordView } from './components/ActiveOrResetPasswordView';
import { AuthenticatedView } from './components/AuthenticatedView';
import { DynamicBackground } from './components/DynamicBackground';
import { ForgotPasswordView } from './components/ForgotPasswordView';
import { LoginRedirectView } from './components/LoginRedirectView';
import { SignInView } from './components/SignInView';
import { SignedOutView } from './components/SignedOutView';
import { SsoRedirectView } from './components/SsoRedirectView';

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to, { replace: true });
  }, []);

  return null;
};

export const LoginView = () => {
  return (
    <>
      <PageTitle>Login</PageTitle>
      <DynamicBackground>
        <Navigation />
        <Content>
          <Routes>
            <Route path="/" element={<SignInView />} />
            <Route path="/signed-out" element={<SignedOutView />} />
            <Route path="/forgot-password" element={<ForgotPasswordView />} />
            <Route path="/account-locked" element={<AccountLockedView />} />
            <Route path="/account-deactivated" element={<AccountDeactivatedView />} />
            <Route path="/authenticated" element={<AuthenticatedView />} />
            <Route path="/activate/:token" element={<ActiveOrResetPasswordView />} />
            <Route path="/reset-password/:token" element={<ActiveOrResetPasswordView />} />
            <Route path="/redirect" element={<LoginRedirectView />} />
            <Route path="/sso/:ssoId" element={<SsoRedirectView />} />
            <Route path="/*" element={<Redirect to="/" />} />
          </Routes>
        </Content>
      </DynamicBackground>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 40px 24px;

  ${media.mobile`
    padding: 0 24px 24px 24px;
    justify-content: unset;
    align-items: unset;
  `}
`;
