import groupBy from 'lodash/groupBy';
import { FilterDefinition, FilterValue } from './types';
import { DatePeriod, Operator, OperatorType } from './constants';
import {
  endOfDay,
  getMonth,
  getWeek,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isSameYear,
  isToday,
  isTomorrow,
  isValid,
  isYesterday,
  startOfDay,
} from 'date-fns';

/**
 * Test if both filters value lists are the same
 */
const areFilterValuesEqual = (aValues: FilterValue[], bValues: FilterValue[]) =>
  aValues.length !== bValues.length &&
  aValues.every(a =>
    bValues.some(b => a.field === b.field && a.operator === b.operator && a.value === b.value),
  );

/**
 * Are saved filter values and current values different
 */
export const hasUnsavedChanges = (filter?: FilterDefinition) => {
  if (!filter || !filter.savedFilters) return false;
  const { savedFilters } = filter;
  const currentSavedFilter = savedFilters.list.find(sv => sv.id === savedFilters.current);
  if (!currentSavedFilter) return false;

  return areFilterValuesEqual(currentSavedFilter.values, filter.values);
};

/**
 * Local comparison function to filtere out rows in data
 */
export const compareRowToFilters = (filterValues: FilterValue[]) => {
  const groupedValues = groupBy(filterValues, 'field');

  return <D extends object>(row: D) =>
    Object.entries(groupedValues).every(([field, values]: any) => {
      if (!(field in row)) return true;

      const rowValue = (row as any)[field];

      // tslint:disable-next-line:cyclomatic-complexity
      return values.some(({ operator, value, selectedOperatorType, rangeValues }: FilterValue) => {
        const arrayContainsValue = () => {
          if (typeof value === 'string') {
            return rowValue.some((val: string) => val.toLowerCase() === value.toLowerCase());
          }
          return rowValue.some((val: number | boolean) => val === value);
        };

        switch (operator) {
          case Operator.Contains:
            if (Array.isArray(rowValue)) {
              return arrayContainsValue();
            }
            return rowValue.toLowerCase().includes(String(value || '').toLowerCase());
          case Operator.NotContains:
            if (Array.isArray(rowValue)) {
              return !arrayContainsValue();
            }
            return !rowValue.toLowerCase().includes((value as string).toLowerCase());
          case Operator.Equals:
            if (isRelativeDatePeriod(value)) {
              return compareDatePeriods(rowValue, value);
            }
            if (typeof value === 'string') {
              if (isValid(new Date(value))) {
                const currentValue = value.split('T')[0];
                const currentRowVal = new Date(rowValue).toISOString().split('T')[0];
                return isEqual(new Date(currentValue), new Date(currentRowVal));
              }
              return rowValue.toLowerCase() === value.toLowerCase();
            }
            return rowValue === value;
          case Operator.NotEquals:
            return rowValue !== value;
          case Operator.GreaterThan:
            return rowValue > value;
          case Operator.GreaterThanOrEquals:
            return rowValue >= value;
          case Operator.LessThan:
            return rowValue < value;
          case Operator.LessThanOrEquals:
            return rowValue <= value;
          case Operator.Empty:
            return isEmpty(rowValue);
          case Operator.NotEmpty:
            return !isEmpty(rowValue);
          case Operator.Between:
            return isNumberBetween(rowValue, rangeValues);
          case Operator.NotBetween:
            return !isDateBetween(rowValue, rangeValues);
          case Operator.In:
          // TODO : implement based on relative values for Is In
          // https://docs.google.com/spreadsheets/d/1LfQSASVmJ7bPuboDN6mCJZrhSuo4JOGen21Dn1mIbQI/edit#gid=1201803448
          case Operator.InRange:
            return isDateBetween(rowValue, rangeValues);
          case Operator.InYear:
            return isSameYear(new Date(rowValue), new Date(value as string));
          case Operator.InPast:
          case Operator.InNext:
          case Operator.Before:
            return isDateBefore(rowValue, value as string, selectedOperatorType);
          case Operator.OnOrAfter:
            return isDateOnOrAfter(rowValue, value as string, selectedOperatorType);
        }
      });
    });
};

const isEmpty = (rowValue: any) => {
  return (
    typeof rowValue !== 'undefined' &&
    rowValue !== null &&
    (typeof rowValue !== 'string' || rowValue.trim() !== '')
  );
};

const isNumberBetween = (noToCheck: number, rangeValues: string[] | undefined) => {
  if (!rangeValues) return false;
  const noLow = parseFloat(rangeValues[0]);
  const noHigh = parseFloat(rangeValues[1]);
  return noToCheck >= noLow && noToCheck <= noHigh;
};

const isDateBetween = (date: string, rangeValues: string[] | undefined) => {
  if (!rangeValues) return false;
  const startDate = rangeValues[0];
  const endDate = rangeValues[1];
  return (
    (isEqual(new Date(getDateWithoutTime(date)), new Date(getDateWithoutTime(startDate))) ||
      isAfter(new Date(getDateWithoutTime(date)), new Date(getDateWithoutTime(startDate)))) &&
    (isEqual(new Date(getDateWithoutTime(date)), new Date(getDateWithoutTime(endDate))) ||
      isBefore(new Date(getDateWithoutTime(date)), new Date(getDateWithoutTime(endDate))))
  );
};

const isDateOnOrAfter = (
  dateToCheck: string,
  dateToBeCompared: string,
  selectedOperatorType?: OperatorType,
) => {
  if (!selectedOperatorType || selectedOperatorType === OperatorType.Absolute) {
    return (
      isEqual(endOfDay(new Date(dateToCheck)), endOfDay(new Date(dateToBeCompared))) ||
      isAfter(new Date(dateToCheck), endOfDay(new Date(dateToBeCompared)))
    );
  }
  // TODO: Add else part with relative dates based on values provided
  // https://docs.google.com/spreadsheets/d/1LfQSASVmJ7bPuboDN6mCJZrhSuo4JOGen21Dn1mIbQI/edit#gid=1201803448
};

const isDateBefore = (
  dateToCheck: string,
  dateToBeCompared: string,
  selectedOperatorType?: OperatorType,
) => {
  if (!selectedOperatorType || selectedOperatorType === OperatorType.Absolute) {
    return isBefore(new Date(dateToCheck), startOfDay(new Date(dateToBeCompared)));
  }
  // TODO: Add else part with relative dates based on values provided
  // https://docs.google.com/spreadsheets/d/1LfQSASVmJ7bPuboDN6mCJZrhSuo4JOGen21Dn1mIbQI/edit#gid=1201803448
};

/**
 * Converts to ISO string and gives only the date part
 */
export const getDateWithoutTime = (date: string) => {
  return new Date(date).toISOString().split('T')[0];
};

const isRelativeDatePeriod = (value: any): value is DatePeriod =>
  Object.values(DatePeriod).includes(value);

const getQuarterOfTheYear = (date: Date) => Math.ceil(getMonth(date) / 3);

const compareDatePeriods = (value: string, period: DatePeriod) => {
  const date = new Date(value);
  switch (period) {
    case DatePeriod.ThisYear:
      return getYear(date) === getYear(new Date());
    case DatePeriod.ThisQuarter:
      return getQuarterOfTheYear(date) === getQuarterOfTheYear(new Date());
    case DatePeriod.ThisMonth:
      return getMonth(date) === getMonth(new Date());
    case DatePeriod.ThisWeek:
      return getWeek(date) === getWeek(new Date());
    case DatePeriod.Today:
    case DatePeriod.TodayTitleCase:
      return isToday(date);
    case DatePeriod.Yesterday:
      return isYesterday(date);
    case DatePeriod.Tomorrow:
      return isTomorrow(date);

    default:
      console.error(`Date period '${period}' not supported!`);
      break;
  }
};
