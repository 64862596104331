import React from 'react';
import styled, { css } from 'styled-components';
import '../polyfills/focus-within-polyfill';

import { Colors } from '../Colors';
import { BORDER_RADIUS } from '../variables';

interface WrapperProps {
  stackVertically?: boolean;
}

interface Props extends WrapperProps {
  className?: string;
  hideSeparator?: boolean;
  children: React.ReactElement[];
  testId?: string;
}

/**
 * Input element - basis for more complex fields
 */
export const InputGroup = ({
  className,
  children,
  hideSeparator,
  stackVertically,
  testId,
}: Props) => (
  <InputGroupWrapper className={className} stackVertically={stackVertically} data-testid={testId}>
    {React.Children.map(children, (child, idx) => (
      <>
        {child}
        {idx < React.Children.count(children) - 1 &&
          !hideSeparator &&
          (stackVertically ? <VerticalSeparator /> : <HorizontalSeparator />)}
      </>
    ))}
  </InputGroupWrapper>
);

const verticalStyle = css`
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  > * {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  > select {
    background-position: calc(100% + 2px) 50%;
  }
`;

/**
 * Wrapper for input group elements.
 */
export const InputGroupWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  background: ${Colors.White()};
  border-radius: ${BORDER_RADIUS};
  overflow: hidden;
  box-shadow: 0 1px 4px 0 ${Colors.NavyBlue(0.16)};

  &.focus-within,
  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 2px ${Colors.LightBlue(0.4)}, 0 1px 4px 0 ${Colors.DarkGray(0.08)};
  }

  > * {
    height: 100%;
    box-shadow: none !important;
    border-radius: ${BORDER_RADIUS};

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:focus {
      box-shadow: none;
    }
  }

  ${(props: WrapperProps) => (props.stackVertically ? `${verticalStyle}` : '')}
`;

const HorizontalSeparator = styled.div`
  height: 24px;
  width: 1px;
  background: ${Colors.DarkGray(0.08)};
`;

const VerticalSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.DarkGray(0.08)};
`;
