import React from 'react';
import styled from 'styled-components';

import { inputStyle } from './Input';
import icon from '../icons/small/arrow-icon.svg';

/**
 * Inner select element
 */
export const SelectElement = styled.select`
  ${inputStyle};
  background-image: url("${icon}");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  padding-right: 32px;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }
`;

/**
 * Option definition for Dropdown
 */
export interface DropdownOption {
  value: string;
  label: string;
  disabled?: boolean;
}

/**
 * Option definition for Dropdown
 */
export interface GroupDropdownOption {
  groupLabel: string;
  options: DropdownOption[];
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  /**
   * Array of { value: string; label: string; disabled?: boolean; }
   */
  options: DropdownOption[];
}

interface GroupedProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  /**
   * Array of { value: string; label: string; disabled?: boolean; }
   */
  groupedOptions: GroupDropdownOption[];
}

/**
 * Dropdown component
 */
export const Dropdown = React.forwardRef<HTMLSelectElement, Props>(
  ({ placeholder, options, children, ...props }, ref) => (
    <SelectElement ref={ref} {...props}>
      {typeof placeholder === 'string' && <option value="">{placeholder}</option>}

      {options.map(({ label, value, ...opt }) => (
        <option {...opt} key={value} value={value} children={label} />
      ))}
      {children}
    </SelectElement>
  ),
);

/**
 * Group Option Dropdown component, which take Grouped prop
 */
export const GroupedDropdown = React.forwardRef<HTMLSelectElement, GroupedProps>(
  ({ placeholder, groupedOptions, children, ...props }, ref) => (
    <SelectElement ref={ref} {...props}>
      {typeof placeholder === 'string' && <option value="">{placeholder}</option>}
      {groupedOptions.map(({ groupLabel, options }) => (
        <optgroup key={groupLabel} label={groupLabel}>
          {options.map(({ label, value, ...opt }) => (
            <option {...opt} key={value} value={value} children={label} />
          ))}
        </optgroup>
      ))}
      {children}
    </SelectElement>
  ),
);
