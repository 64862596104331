import React from 'react';
import styled from 'styled-components';

import { inputStyle } from './Input';
import { Colors } from '../Colors';
import { TextSize } from '../TextSize';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Optional maximum length of characters to have inside textarea
   */
  maxLength?: number;
  /**
   * Textarea input value
   */
  value?: string;
  /**
   * Show characters left counter
   */
  useCount?: boolean;
  /**
   * Use maximum width and not fixed width
   */
  maxWidth?: boolean;
}

/**
 * Textarea
 */

export const Textarea = ({ maxLength = 240, value = '', useCount, maxWidth, ...props }: Props) => {
  const charactersLeft = Math.max(0, maxLength - value.length);
  return (
    <Wrapper maxWidth={maxWidth}>
      <StyledTextarea value={value} maxLength={useCount ? maxLength : undefined} {...props} />
      {useCount && (
        <CharactersLeftText>{`${charactersLeft} character${
          charactersLeft === 1 ? '' : 's'
        } remaining`}</CharactersLeftText>
      )}
    </Wrapper>
  );
};

interface WrapperProps {
  maxWidth?: boolean;
}

const Wrapper = styled.div`
  width: ${({ maxWidth }: WrapperProps) => (maxWidth ? '100%' : '240px')};
`;

/**
 * Textarea element
 */
export const StyledTextarea = styled.textarea`
  ${inputStyle}
  width: 100%;
  height: 104px;
`;

/**
 * Characters left text
 */
export const CharactersLeftText = styled.div`
  ${TextSize.Small};
  color: ${Colors.DarkGray()};
  text-align: right;
`;
