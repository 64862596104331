import { addMinutes } from 'date-fns';

/**
 * Use only when the date portion is needed e.g. for formatting or disabling days in date picker.
 *
 * Don't reuse this object for time-based calculations.
 * You can use it for date-based calculations e.g. "days between".
 */
export const toUtcDate = (localDate: Date) => addMinutes(localDate, localDate.getTimezoneOffset());

export const toMidnightUtcString = (localDate: Date) =>
  getSameDateAtMidnightUtc(toUtcDate(localDate));

export const getAsMidnightUtcString = (dateOrTimestamp: string) => {
  if (dateOrTimestamp.endsWith('00:00:00.000')) {
    return `${dateOrTimestamp}Z`;
  }

  if (dateOrTimestamp.endsWith('00:00:00')) {
    return `${dateOrTimestamp}.000Z`;
  }

  if (dateOrTimestamp.includes('-') && dateOrTimestamp.length === 10) {
    return `${dateOrTimestamp}T00:00:00.000Z`;
  }

  return dateOrTimestamp;
};

export const getSameDateAtMidnightUtc = (date: Date) =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
