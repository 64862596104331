import React from 'react';
import { OptionProps } from 'react-select';
import styled, { css } from 'styled-components';

import { Colors } from '../../../Colors';
import CheckIcon from '../../../icons/small/CheckIcon';
import { HorizontalMenuPadding, OptionVerticalPadding, SelectAllValue } from '../constants';

/**
 * Multi-select option component
 */
export const Option = (props: OptionProps<any>) => (
  <>
    <Root
      {...props.innerProps}
      disabled={props.isDisabled}
      focused={props.isFocused}
      selected={props.isSelected}>
      <Label>{props.children}</Label>
      {props.isSelected && <CheckIcon />}
    </Root>
    {props.data.value === SelectAllValue && <Separator />}
  </>
);

const Root = styled.div<{ disabled: boolean; focused: boolean; selected: boolean }>`
  width: 100%;
  min-height: 32px;
  padding: ${OptionVerticalPadding}px ${HorizontalMenuPadding}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;

  ${props =>
    props.focused &&
    css`
      background-color: ${Colors.Selection()};
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: auto;
    `}

  ${props =>
    (props.disabled || props.selected) &&
    css`
      color: ${Colors.Disabled()};
    `}
`;

const Label = styled.div``;

const Separator = styled.div`
  margin: 8px ${HorizontalMenuPadding}px;
  height: 1px;
  background-color: ${Colors.Separator()};
`;
