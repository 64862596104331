/**
 * Possible statuses for alerts
 */
export enum AlertStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Critical = 'CRITICAL',
}

/**
 * Alert Item
 */
export interface AlertItem {
  /**
   * key to make the alert unique
   */
  id: string;
  /**
   * Status of the alert
   */
  status: AlertStatus;
  /**
   * Display time in milliseconds
   */
  duration?: number;
  /**
   * Filter value for multiple alert renderers
   */
  channel?: string;
  /**
   * Message to be shown
   */
  message: string;
  /**
   * Button to trigger action and dismiss the button.
   */
  button?: {
    label: string;
    onClick: () => void;
  };
  /**
   * Renders a close button after the message, at the end of the alert
   */
  closeable?: boolean;
}
