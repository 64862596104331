import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { DatePickerInput } from '../../..';
import { Colors } from '../../../Colors';
import { FontWeight } from '../../../FontWeight';
import { TextSize } from '../../../TextSize';
import { useKeyPress } from '../../../hooks';
import { getTranslationWithDefault } from '../../../utility-components/getTranslationWithDefault';
import { Button } from '../../Button';
import { Dropdown, DropdownOption } from '../../Dropdown';
import { InlineFormField } from '../../InlineFormField';
import { Input } from '../../Input';
import { Radio, RadioGroup } from '../../Radio';
import {
  FilterColumnType,
  Operator,
  OperatorType,
  availableOperators,
  masterOperatorTypeMappings,
  DatePeriod,
} from './constants';
import { operatorLabels, datePeriodLabels } from './labels';
import { FilterColumnDefinition, FilterValue } from './types';

interface Props {
  filterColumns: FilterColumnDefinition[];
  currentValues: FilterValue[];
  onApply: (value: FilterValue) => void;
}

interface OperatorOption {
  value: Operator;
  label: string;
  operatorTypes?: OperatorType[] | undefined;
}

const filterErrorMessage = 'Filter already exists';

/**
 * Popover contents for editing filters
 */
export const FilterEditorPane = ({ filterColumns, onApply, currentValues }: Props) => {
  const columnOptions = filterColumns.map(({ label, field }) => ({ label, value: field }));
  const [activeField, setActiveField] = useState(filterColumns[0].field);
  const { type, predefinedValues, enabledOperatorMappings } = filterColumns.find(
    (c) => c.field === activeField,
  )!;
  const operatorsForField = enabledOperatorMappings
    ? availableOperators[type].filter((x) => enabledOperatorMappings.has(x))
    : availableOperators[type];

  const operatorOptions: OperatorOption[] = operatorsForField.map((value) => {
    const label = getTranslationWithDefault(operatorLabels[value]);
    const operatorTypes = enabledOperatorMappings
      ? enabledOperatorMappings.get(value)
      : masterOperatorTypeMappings.get(value);
    return { value, label, operatorTypes };
  });

  const createPredefinedOptions = (
    predefinedValues: any[] | undefined,
    type: FilterColumnType,
  ): (DropdownOption & { operatorTypes?: OperatorType[] })[] | undefined => {
    if (predefinedValues) {
      if (type === FilterColumnType.Date) {
        return predefinedValues.map((value) => ({
          value,
          label: getTranslationWithDefault(datePeriodLabels[value as DatePeriod]),
        }));
      }

      return predefinedValues.map((v) => {
        if (typeof v === 'object') return v;
        const value = v.toString();
        return { value, label: value };
      });
    }

    if (type === FilterColumnType.Boolean) {
      return [
        {
          label: getTranslationWithDefault('shared.packages.yes'),
          value: 'true',
          operatorTypes: [OperatorType.None],
        },
        {
          label: getTranslationWithDefault('shared.packages.no'),
          value: 'false',
          operatorTypes: [OperatorType.None],
        },
      ];
    }

    return undefined;
  };

  const predefinedOptions = createPredefinedOptions(predefinedValues, type);

  const [radioGroupValue, setRadioGroupValue] = React.useState(OperatorType.Absolute);

  const [filterValue, setFilterValue] = useState('');
  const [filterValueLow, setFilterValueLow] = useState('');
  const [filterValueHigh, setFilterValueHigh] = useState('');

  const [datePickerFilterValue, setDatePickerFilterValue] = useState('');
  const [datePickerFilterValueLow, setDatePickerFilterValueLow] = useState('');
  const [datePickerFilterValueHigh, setDatePickerFilterValueHigh] = useState('');
  const [yearFilterValue, setYearFilterValue] = useState(new Date().getFullYear().toString());

  const predefinedValueOperatorOptions = [
    {
      label: getTranslationWithDefault(operatorLabels[Operator.Equals]),
      value: Operator.Equals,
      operatorTypes: [],
    },
    {
      label: getTranslationWithDefault(operatorLabels[Operator.NotEquals]),
      value: Operator.NotEquals,
      operatorTypes: [],
    },
  ];

  const finalOperatorOptions = shouldUsePredefinedOperators(predefinedOptions, type)
    ? predefinedValueOperatorOptions
    : operatorOptions;

  const [activeOperator, setActiveOperator] = useState(finalOperatorOptions[0]);
  const [filterError, setFilterError] = useState(false);

  useEffect(() => {
    setFilterError(false);
    setActiveOperator(finalOperatorOptions[0]);
    setFilterValue(getPredefinedFilterValue(predefinedOptions, type, activeOperator.value));
  }, [activeField, predefinedValues?.length]);

  useEffect(() => {
    setRadioGroupValue(activeOperator.operatorTypes![0]);
    setFilterValue(getPredefinedFilterValue(predefinedOptions, type, activeOperator.value));
  }, [activeOperator]);

  const applyFilter = () => {
    const valueToApply = {
      field: activeField,
      operator: activeOperator.value,
      value: getCurrentFilterValue(
        yearFilterValue,
        filterValue,
        datePickerFilterValue,
        radioGroupValue,
        activeOperator,
        type,
        predefinedOptions,
      ),
      rangeValues: getRangeValues(
        activeOperator,
        type,
        filterValueLow,
        filterValueHigh,
        datePickerFilterValueLow,
        datePickerFilterValueHigh,
      ),
    };

    if (!isFilterPresent(currentValues, valueToApply)) {
      setFilterValue(valueToApply.value as string);
      onApply(valueToApply);
      setFilterError(false);
    } else {
      setFilterError(true);
    }
  };

  useKeyPress('enter', () => {
    const activeFieldValue = currentValues.find((e) => e.field === activeField)?.value;
    if (
      activeFieldValue &&
      !shouldDisableApply(
        yearFilterValue,
        filterValue,
        datePickerFilterValue,
        radioGroupValue,
        activeOperator,
        type,
        filterValueLow,
        filterValueHigh,
        datePickerFilterValueLow,
        datePickerFilterValueHigh,
      )
    ) {
      applyFilter();
    }
  });

  return (
    <Container data-testid="filter-window">
      <Title>{getTranslationWithDefault('shared.packages.filter')}</Title>
      <DropdownGroup>
        {renderDropdown({
          predefinedOptions: columnOptions,
          filterValue: activeField,
          onValueChange: ({ target: { value } }) => {
            setActiveField(value);
            if (predefinedValues) setActiveOperator(activeOperator);
          },
          testId: 'field-filter-dropdown',
          activeOperator: activeOperator.value,
        })}
        {type !== FilterColumnType.Boolean &&
          renderDropdown({
            predefinedOptions: finalOperatorOptions,
            filterValue: activeOperator.value,
            onValueChange: (event) => {
              const operator = event.target.value as Operator;
              setActiveOperator({
                value: operator,
                label: operator,
                operatorTypes: enabledOperatorMappings
                  ? enabledOperatorMappings.get(operator)
                  : masterOperatorTypeMappings.get(operator),
              });
            },
            error: (!shouldShowValueField(type, activeOperator) && filterError) || undefined,
            testId: 'operator-filter-dropdown',
            activeOperator: activeOperator.value,
          })}
        {shouldShowValueField(type, activeOperator) && (
          <>
            {hasRange(activeOperator.operatorTypes) ? (
              <>
                {type === FilterColumnType.Number && (
                  <>
                    {renderInput({
                      filterValue: filterValueLow,
                      onValueChange: (event) => {
                        setFilterValueLow(event.target.value);
                      },
                      numeric: true,
                      error: filterError,
                    })}
                    {renderInput({
                      error: filterError,
                      filterValue: filterValueHigh,
                      onValueChange: (event) => {
                        setFilterValueHigh(event.target.value);
                      },
                      numeric: true,
                    })}
                  </>
                )}
                {type === FilterColumnType.Date && (
                  <>
                    {renderDatePicker({
                      filterValue: datePickerFilterValueLow,
                      onValueChange: (date) => setDatePickerFilterValueLow(date ? date : ''),
                      error: filterError,
                    })}
                    {renderDatePicker({
                      filterValue: datePickerFilterValueHigh,
                      onValueChange: (date) => setDatePickerFilterValueHigh(date ? date : ''),
                      error: filterError,
                    })}
                  </>
                )}
              </>
            ) : (
              <>
                {type === FilterColumnType.Date ? (
                  <>
                    {predefinedOptions &&
                    shouldShowMultipleOptions(predefinedOptions, activeOperator) ? (
                      <RadioGroup
                        name="fieldGroup"
                        selectedValue={radioGroupValue}
                        onChange={(event) => {
                          if (event.target.value === OperatorType.Relative) {
                            setFilterValue(
                              filterPredefinedOptions(predefinedOptions, activeOperator.value)[0]
                                .value,
                            );
                          }
                          setRadioGroupValue(event.target.value as OperatorType);
                        }}>
                        <StyledRadio label="" value={OperatorType.Absolute} rootAs="span">
                          {renderDatePicker({
                            filterValue: datePickerFilterValue,
                            onValueChange: (date) => {
                              setDatePickerFilterValue(date ? date : '');
                              setRadioGroupValue(OperatorType.Absolute);
                            },
                            error: filterError && radioGroupValue === OperatorType.Absolute,
                          })}
                        </StyledRadio>
                        <StyledRadio label="" value={OperatorType.Relative}>
                          {renderDropdown({
                            filterValue,
                            predefinedOptions: filterPredefinedOptions(
                              predefinedOptions,
                              activeOperator.value,
                            ),
                            onValueChange: ({ target: { value } }) => {
                              setFilterValue(value);
                              setRadioGroupValue(OperatorType.Relative);
                            },
                            error: filterError && radioGroupValue === OperatorType.Relative,
                            activeOperator: activeOperator.value,
                          })}
                        </StyledRadio>
                      </RadioGroup>
                    ) : shouldShowOnlyRelative(predefinedOptions, activeOperator.operatorTypes) ? (
                      renderDropdown({
                        filterValue,
                        onValueChange: ({ target: { value } }) => setFilterValue(value),
                        predefinedOptions: filterPredefinedOptions(
                          predefinedOptions!,
                          activeOperator.value,
                        ),
                        error: filterError,
                        activeOperator: activeOperator.value,
                      })
                    ) : isInYearOperator(activeOperator) ? (
                      renderYearDropDown({
                        filterValue: yearFilterValue,
                        onValueChange: ({ target: { value } }) => setYearFilterValue(value),
                        error: filterError,
                      })
                    ) : (
                      renderDatePicker({
                        filterValue: datePickerFilterValue,
                        onValueChange: (date) => setDatePickerFilterValue(date ? date : ''),
                        error: filterError,
                      })
                    )}
                  </>
                ) : predefinedOptions ? (
                  renderDropdown({
                    predefinedOptions,
                    filterValue,
                    onValueChange: ({ target: { value } }) => setFilterValue(value),
                    error: filterError,
                    testId: 'predefined-filter-dropdown',
                    activeOperator: activeOperator.value,
                  })
                ) : (
                  renderInput({
                    filterValue,
                    onValueChange: ({ target: { value } }) => setFilterValue(value),
                    numeric: type === FilterColumnType.Number,
                    error: filterError,
                  })
                )}
              </>
            )}
          </>
        )}
      </DropdownGroup>
      <ApplyButton
        data-testid="filter-apply-button"
        disabled={shouldDisableApply(
          yearFilterValue,
          filterValue,
          datePickerFilterValue,
          radioGroupValue,
          activeOperator,
          type,
          filterValueLow,
          filterValueHigh,
          datePickerFilterValueLow,
          datePickerFilterValueHigh,
        )}
        onClick={applyFilter}>
        {getTranslationWithDefault('shared.packages.apply')}
      </ApplyButton>
    </Container>
  );
};

const renderDatePicker = (props: {
  filterValue: string;
  onValueChange: (date?: string) => void;
  error?: boolean;
}) => (
  <StyledInlineFormField error={props.error ? filterErrorMessage : undefined}>
    <DatePickerInput value={props.filterValue} onChange={props.onValueChange} />
  </StyledInlineFormField>
);

const renderDropdown = (props: {
  predefinedOptions: DropdownOption[];
  filterValue: string;
  onValueChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  activeOperator: string;
  error?: boolean;
  testId?: string;
}) => (
  <StyledInlineFormField error={props.error ? filterErrorMessage : undefined}>
    <Dropdown
      data-testid={props.testId}
      // destructing to filter unsupported <option> properties
      options={props.predefinedOptions.map(({ label, value, disabled }) => ({
        label,
        value,
        disabled,
      }))}
      value={props.filterValue}
      onChange={props.onValueChange}
      disabled={props.predefinedOptions && props.predefinedOptions.length === 1}
    />
  </StyledInlineFormField>
);

const filterPredefinedOptions = (predefinedOptions: DropdownOption[], operator: string) => {
  const equalsDatePeriods = [DatePeriod.Today, DatePeriod.Yesterday, DatePeriod.Tomorrow];
  const inDatePeriods = [
    DatePeriod.ThisWeek,
    DatePeriod.ThisMonth,
    DatePeriod.ThisQuarter,
    DatePeriod.ThisYear,
  ];

  switch (operator) {
    case Operator.Equals:
      return predefinedOptions.filter(({ value }) =>
        equalsDatePeriods.includes(value as DatePeriod),
      );
    case Operator.In:
      return predefinedOptions.filter(({ value }) => inDatePeriods.includes(value as DatePeriod));
    case Operator.Before:
    case Operator.OnOrAfter:
      return predefinedOptions.filter(({ value }) => value.includes(DatePeriod.Today));
    default:
      return [];
  }
};

const renderYearDropDown = (props: {
  filterValue: string;
  onValueChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
}) => {
  const range = 200;
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: range }, (_, i) => currentYear - range / 2 + i);

  return (
    <StyledInlineFormField error={props.error ? filterErrorMessage : undefined}>
      <Dropdown
        options={years.map((y) => ({ label: y.toString(), value: y.toString() }))}
        value={props.filterValue}
        onChange={props.onValueChange}
      />
    </StyledInlineFormField>
  );
};

const renderInput = (props: {
  filterValue: string;
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  numeric?: boolean;
}) => (
  <StyledInlineFormField error={props.error ? filterErrorMessage : undefined}>
    <Input
      type={props.numeric ? 'number' : undefined}
      value={props.filterValue}
      onChange={props.onValueChange}
    />
  </StyledInlineFormField>
);

const getPredefinedFilterValue = (
  predefinedOptions: DropdownOption[] | undefined,
  type: FilterColumnType,
  operator: Operator,
) => {
  if (predefinedOptions) {
    if (type === FilterColumnType.Date) {
      return filterPredefinedOptions(predefinedOptions, operator)[0]?.value;
    }
    return predefinedOptions[0]?.value;
  }
  return '';
};

const isFilterPresent = (currentValues: FilterValue[], valueToApply: FilterValue) => {
  if (!currentValues || currentValues.length === 0 || !valueToApply) {
    return false;
  }
  return currentValues.find((x) => {
    if (x.field === valueToApply.field && x.operator === valueToApply.operator) {
      if (
        x.rangeValues &&
        valueToApply.rangeValues &&
        x.rangeValues.length > 0 &&
        valueToApply.rangeValues.length > 0
      ) {
        return JSON.stringify(x.rangeValues) === JSON.stringify(valueToApply.rangeValues);
      }
      return x.value === valueToApply.value;
    }
    return false;
  });
};

const shouldUsePredefinedOperators = (
  predefinedOptions: DropdownOption[] | undefined,
  type: FilterColumnType,
) => predefinedOptions && ![FilterColumnType.Date, FilterColumnType.Array].includes(type);

const shouldPickDatepickerValue = (
  type: FilterColumnType,
  radioGroupValue: string,
  predefinedOptions: DropdownOption[] | undefined,
  activeOperator: OperatorOption,
) => {
  if (type === FilterColumnType.Date) {
    if (shouldShowMultipleOptions(predefinedOptions, activeOperator)) {
      return radioGroupValue === OperatorType.Absolute;
    }
    if (
      activeOperator.operatorTypes &&
      activeOperator.operatorTypes.includes(OperatorType.Absolute) &&
      activeOperator.value !== Operator.InYear
    ) {
      return true;
    }
  }
  return false;
};

const shouldShowValueField = (type: FilterColumnType, activeOperator: OperatorOption) => {
  if (type === FilterColumnType.Boolean) return true;
  if (activeOperator.operatorTypes && activeOperator.operatorTypes.includes(OperatorType.None)) {
    return false;
  }
  return true;
};

const shouldShowMultipleOptions = (
  predefinedOptions: DropdownOption[] | undefined,
  activeOperator: OperatorOption,
) => predefinedOptions && hasAbsoluteAndRelative(activeOperator.operatorTypes);

const hasAbsoluteAndRelative = (operatorTypes: OperatorType[] | undefined) =>
  operatorTypes &&
  [OperatorType.Absolute, OperatorType.Relative].every((x) => operatorTypes.includes(x));

const hasOnlyRelative = (operatorTypes: OperatorType[] | undefined) =>
  operatorTypes &&
  !operatorTypes.includes(OperatorType.Absolute) &&
  operatorTypes.includes(OperatorType.Relative);

const shouldShowOnlyRelative = (
  predefinedOptions: DropdownOption[] | undefined,
  operatorTypes: OperatorType[] | undefined,
) => predefinedOptions && hasOnlyRelative(operatorTypes);

const isInYearOperator = (activeOperator: OperatorOption | undefined) =>
  activeOperator && activeOperator.value === Operator.InYear;

const hasRange = (operatorTypes: OperatorType[] | undefined) =>
  operatorTypes && operatorTypes.includes(OperatorType.Range);

const getCurrentFilterValue = (
  yearFilterValue: string,
  filterValue: string,
  dateFilterValue: string,
  radioGroupValue: string,
  activeOperator: OperatorOption,
  type: FilterColumnType,
  predefinedOptions: DropdownOption[] | undefined,
) => {
  if (hasRange(activeOperator.operatorTypes)) {
    return '';
  }
  if (shouldPickDatepickerValue(type, radioGroupValue, predefinedOptions, activeOperator)) {
    return dateFilterValue;
  }
  if (type === FilterColumnType.Number) {
    return parseFloat(filterValue);
  }
  if (activeOperator.operatorTypes && activeOperator.operatorTypes.includes(OperatorType.None)) {
    return '';
  }
  if (activeOperator.value === Operator.InYear) {
    return yearFilterValue;
  }
  return filterValue;
};

const getRangeValues = (
  activeOperator: OperatorOption,
  type: FilterColumnType,
  filterValueLow: string,
  filterValueHigh: string,
  dateFilterValueLow: string,
  dateFilterValueHigh: string,
) => {
  return hasRange(activeOperator.operatorTypes)
    ? type === FilterColumnType.Number
      ? [filterValueLow, filterValueHigh]
      : [dateFilterValueLow, dateFilterValueHigh]
    : undefined;
};

const shouldDisableApply = (
  yearFilterValue: string,
  filterValue: string,
  dateFilterValue: string,
  radioGroupValue: string,
  activeOperator: OperatorOption,
  type: FilterColumnType,
  filterValueLow: string,
  filterValueHigh: string,
  dateFilterValueLow: string,
  dateFilterValueHigh: string,
) => {
  if (activeOperator.operatorTypes && activeOperator.operatorTypes.includes(OperatorType.None)) {
    return false;
  }
  if (activeOperator.operatorTypes && activeOperator.operatorTypes.includes(OperatorType.Range)) {
    if (type === FilterColumnType.Number) {
      return !filterValueLow.trim() || !filterValueHigh.trim();
    }
    if (type === FilterColumnType.Date) {
      return !dateFilterValueLow.trim() || !dateFilterValueHigh.trim();
    }
  }
  if (type === FilterColumnType.Date && radioGroupValue === OperatorType.Absolute) {
    if (activeOperator.value === Operator.InYear) {
      return !yearFilterValue;
    }
    return !dateFilterValue;
  }
  return !filterValue;
};

const Container = styled.div`
  padding: 24px;
  padding-bottom: 32px;
  width: 288px;
`;

const DropdownGroup = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  row-gap: 8px;
  margin: 0 0 16px 0;
`;

const Title = styled.div`
  ${TextSize.SemiMedium};
  font-size: 20px;
  font-weight: ${FontWeight.Bold};
  color: ${Colors.NavyBlue()};
  margin-bottom: 16px;
`;

const StyledRadio = styled(Radio)`
  display: grid;

  /* 2nd column needs exact value as width and can't have auto because then it can grow with flex children */
  grid-template-columns: 20px calc(100% - 28px);
  column-gap: 8px;
  margin: 0 0 8px 0;

  > * {
    margin: 0;
  }
`;

const StyledInlineFormField = styled(InlineFormField)`
  > * {
    width: 100%;
  }
`;

const ApplyButton = styled(Button).attrs({ secondary: true })`
  width: 100%;
`;
