import React from 'react';
const SvgCalendarIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path
        d="M19 18V7h-3v2h-2V7h-4v2H8V7H5v11zm2-13v15H3V5h5V3h2v2h4V3h2v2zM5 10h14v2H5z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgCalendarIcon;
