import React from 'react';
import { isValid } from 'date-fns';
import { groupBy } from 'lodash';
import styled from 'styled-components';

import { Colors } from '../../../Colors';
import { FontWeight } from '../../../FontWeight';
import { media } from '../../../MediaQuery';
import PlusIcon from '../../../icons/small/PlusIcon';
import DateFormatter from '../../../utility-components/DateFormatter';
import { getTranslationWithDefault } from '../../../utility-components/getTranslationWithDefault';
import { Button } from '../../Button';
import { IconButton } from '../../IconButton';
import { Tag } from '../../Tag';
import { FilterColumnType, Operator, DatePeriod } from './constants';
import { datePeriodLabels, operatorLabels } from './labels';
import { FilterDefinition, FilterValue } from './types';

interface Props {
  filter: FilterDefinition;
  onAdd: () => void;
}

/**
 * Shows all applied filters as tags
 */
export const AppliedFiltersBar = ({ onAdd, filter }: Props) => {
  const appliedFilterColumns = filter.columns.filter((x) =>
    filter.values.find((y) => y.field === x.field),
  );
  const defaultFilters = filter.values.filter(({ field: filterField }) =>
    appliedFilterColumns.every(({ field: columnFiled }) => filterField !== columnFiled),
  );

  const getDisplayValue = (
    value: string | number | boolean,
    rangeValues?: string[],
    operator?: Operator,
    isDate?: boolean,
  ) => {
    if (rangeValues && rangeValues.length === 2) {
      return isDate ? (
        <>
          <DateFormatter date={rangeValues[0]} /> - <DateFormatter date={rangeValues[1]} />
        </>
      ) : (
        `${rangeValues[0]} - ${rangeValues[1]}`
      );
    }

    if (typeof value === 'boolean') {
      return getTranslationWithDefault(value ? 'shared.packages.yes' : 'shared.packages.no');
    }

    if (isDate) {
      const date = new Date(value as string);
      if (isValid(date)) {
        if (operator === Operator.InYear) {
          return date.getFullYear().toString();
        }
        return <DateFormatter date={value as string} />;
      }

      if (datePeriodLabels[value as DatePeriod]) {
        return getTranslationWithDefault(datePeriodLabels[value as DatePeriod]);
      }
    }

    return value.toString();
  };

  return (
    <Container data-testid="applied-filters-bar">
      <FilterList>
        {appliedFilterColumns.map((filterCol, filterColIdx) => {
          const groupedFilterValues = groupBy(
            filter.values.filter((x) => x.field === filterCol.field),
            'operator',
          );
          return (
            <React.Fragment key={filterColIdx}>
              {Object.entries(groupedFilterValues).map(([key, operatorFilterValues]) => (
                <React.Fragment key={key}>
                  {!showMultiTag(operatorFilterValues) ? (
                    <StyledSingleTag
                      testId="filter-tag"
                      color={filterCol && filterCol.color ? filterCol.color : Colors.LightBlue()}
                      onRemove={() =>
                        filter.onChange(
                          filter.values.filter(
                            (appliedFilterValue) =>
                              !(
                                appliedFilterValue.field === filterCol.field &&
                                operatorFilterValues.find(
                                  (val) => val.operator === appliedFilterValue.operator,
                                ) &&
                                appliedFilterValue.value === operatorFilterValues[0].value
                              ),
                          ),
                        )
                      }>
                      <GroupLabel>
                        {filterCol.label}
                        {showOperatorLabel(operatorFilterValues[0].operator) && (
                          <OperatorLabel>
                            {' '}
                            {getTranslationWithDefault(
                              operatorLabels[operatorFilterValues[0].operator],
                            ).toLowerCase()}
                          </OperatorLabel>
                        )}
                        {showValue(operatorFilterValues[0]) && ':'}
                      </GroupLabel>
                      {showValue(operatorFilterValues[0]) && (
                        <>
                          {getDisplayValue(
                            operatorFilterValues[0].value,
                            operatorFilterValues[0].rangeValues,
                            operatorFilterValues[0].operator,
                            filterCol.type === FilterColumnType.Date,
                          )}
                        </>
                      )}
                    </StyledSingleTag>
                  ) : (
                    <StyledMultiTag
                      color={filterCol && filterCol.color ? filterCol.color : Colors.LightBlue()}>
                      <MultiGroupLabel>
                        {filterCol.label}
                        {showOperatorLabel(operatorFilterValues[0].operator) && (
                          <OperatorLabel>
                            {' '}
                            {getTranslationWithDefault(
                              operatorLabels[operatorFilterValues[0].operator],
                            ).toLowerCase()}
                          </OperatorLabel>
                        )}
                        {': '}
                      </MultiGroupLabel>
                      {(operatorFilterValues || []).map((filterVal) => (
                        <StyledInnerTag
                          key={`${filterVal.field}-${filterVal.operator}-${filterVal.value}`}
                          color={Colors.White(0.64)}
                          onRemove={() =>
                            filter.onChange(
                              filter.values.filter(
                                (appliedFilterValue) =>
                                  !(
                                    appliedFilterValue.field === filterCol.field &&
                                    appliedFilterValue.operator === filterVal.operator &&
                                    checkRangeValuesOrValues(appliedFilterValue, filterVal)
                                  ),
                              ),
                            )
                          }>
                          {getDisplayValue(
                            filterVal.value,
                            filterVal.rangeValues,
                            filterVal.operator,
                            filterCol.type === FilterColumnType.Date,
                          )}
                        </StyledInnerTag>
                      ))}
                    </StyledMultiTag>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        })}
        <StyledIconButton onClick={onAdd}>
          <PlusIcon />
        </StyledIconButton>
        <StyledAddButton onClick={onAdd} tertiary>
          {getTranslationWithDefault('shared.packages.addFilter')}
        </StyledAddButton>
      </FilterList>

      <ClearButton
        secondary
        color={Colors.DarkGray(0.04)}
        onClick={() => filter.onChange(defaultFilters)}>
        {getTranslationWithDefault('shared.packages.clear')}
      </ClearButton>
    </Container>
  );
};

const showMultiTag = (filterValues: FilterValue[]) => {
  return filterValues.length > 1;
};

const showOperatorLabel = (operator: Operator) => {
  return ![Operator.Equals, Operator.Between, Operator.InRange].some((x) => x === operator);
};

const showValue = (filterValue: FilterValue) => {
  return filterValue.value === false || filterValue.value || filterValue.rangeValues;
};

const checkRangeValuesOrValues = (appliedFilterValue: FilterValue, filterVal: FilterValue) => {
  if (
    appliedFilterValue.rangeValues &&
    appliedFilterValue.rangeValues.length > 1 &&
    filterVal.rangeValues &&
    filterVal.rangeValues.length > 1
  ) {
    return JSON.stringify(appliedFilterValue.rangeValues) === JSON.stringify(filterVal.rangeValues);
  }
  return appliedFilterValue.value === filterVal.value;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: solid 2px ${Colors.DarkGray(0.08)};
  padding: 24px 0;

  ${media.mobile`
    width: max-content;
  `};
`;

const StyledIconButton = styled(IconButton)`
  ${media.mobile`
    display: none;
  `};
`;

const StyledAddButton = styled(Button)`
  display: none;

  ${media.mobile`
    display: inline;
    margin-right: 16px;
    flex-shrink: 0;
    background-color: ${Colors.White()};
    border: 2px solid ${Colors.DarkGray(0.08)};
    line-height: 28px;
  `};
`;

const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > div {
    margin-bottom: 8px;
  }

  > * {
    margin-bottom: 8px;
  }
`;

const GroupLabel = styled.span`
  margin-right: 4px;
`;

const MultiGroupLabel = styled(GroupLabel)`
  margin-bottom: 4px;
`;

const StyledMultiTag = styled(Tag)`
  display: flex;
  margin-right: 8px;

  > * {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-right: -4px;
  }
`;

const StyledSingleTag = styled(Tag)`
  margin-right: 8px;
  min-height: 32px;
  align-items: center;

  > * {
    display: flex;
  }

  ${media.mobile`
    margin-right: 16px;
  `};
`;

const StyledInnerTag = styled(Tag)`
  margin-right: 4px;
  margin-bottom: 4px;

  &:last-child {
    margin-right: 0;
  }
`;

const OperatorLabel = styled.span`
  font-weight: ${FontWeight.Bold};
`;

const ClearButton = styled(Button)`
  flex-shrink: 0;
  align-self: flex-start;
`;
