import React from 'react';
import styled from 'styled-components';

import { media } from '@topia.com/ui-kit';

export const DynamicBackground = ({ children }: React.PropsWithChildren<{}>) => (
  <Background>
    <BackgroundGlow>{children}</BackgroundGlow>
  </Background>
);

const palette = [
  {
    before: 'var(--topia-ui-tint-arctic)',
    bgStart: '#d5e8f7',
    bgEnd: 'var(--topia-ui-tint-navy)',
  },
  {
    before: 'var(--topia-ui-tint-sea-shell)',
    bgStart: '#f4b8af',
    bgEnd: 'var(--topia-ui-tint-navy)',
  },
  {
    before: 'var(--topia-ui-color-slate-200)',
    bgStart: 'var(--topia-ui-color-slate-500)',
    bgEnd: 'var(--topia-ui-color-slate)',
  },
  {
    before: 'var(--topia-ui-tint-arctic)',
    bgStart: 'var(--topia-ui-tint-sky)',
    bgEnd: 'var(--topia-ui-tint-navy)',
  },
  {
    before: 'var(--topia-ui-tint-blonde)',
    bgStart: 'var(--topia-ui-color-c-minus)',
    bgEnd: 'var(--topia-ui-color-slate)',
  },
];
const paletteChoice = Math.floor(Math.random() * palette.length);

const Background = styled.div`
  --offset1: ${20 + Math.floor(Math.random() * 20)}%;
  --offset2: calc(var(--offset1) + ${20 + Math.floor(Math.random() * 20)}%);
  --angle1: ${-15 + Math.floor(Math.random() * 30)}deg;
  --angle2: ${80 + Math.floor(Math.random() * 30)}deg;
  --backgroundBefore: ${palette[paletteChoice].before};
  --backgroundStart: ${palette[paletteChoice].bgStart};
  --backgroundEnd: ${palette[paletteChoice].bgEnd};

  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    var(--angle2),
    var(--backgroundStart) var(--offset2),
    var(--backgroundEnd) var(--offset2)
  );

  :before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    transform: skew(var(--angle1)) translateX(calc(var(--offset1) - 100%));
    background-color: var(--backgroundBefore);
  }

  ${media.mobile`
    background-color: var(--topia-ui-color-background);
    background-image: none;

    :before {
      background-color: transparent;
    }
  `}
`;

const BackgroundGlow = styled.div`
  display: flex;
  flex-direction: column;
  background-image: radial-gradient(
    circle at 50% 100%,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0) 68%
  );
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`;
