import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '@topia.com/ui-kit';
import SvgInfoIcon from '@topia.com/ui-kit/icons/small/InfoIcon';
import { BORDER_RADIUS } from '@topia.com/ui-kit/variables';

interface Props {
  variant?: 'info' | 'warning';
  inline?: boolean;
  testId?: string;
}

export const Notification = ({ variant = 'info', ...props }: PropsWithChildren<Props>) => (
  <NotificationWrapper data-testid={props.testId} $inline={props.inline}>
    <span>{variant === 'info' ? <InfoIcon /> : <WarningIcon />}</span>
    <ChildrenWrapper>{props.children}</ChildrenWrapper>
  </NotificationWrapper>
);

const NotificationWrapper = styled.div<{ $inline?: boolean }>`
  display: ${(props) => (props.$inline ? 'inline-flex' : 'flex')};
  align-items: center;
  background-color: ${Colors.DarkGray(0.04)};
  border-radius: ${BORDER_RADIUS};
  padding: 8px 12px;
`;

const ChildrenWrapper = styled.div`
  width: 100%;
`;

const commonIconStyle = css`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 8px;
  padding: 4px;
`;

const WarningIcon = styled(SvgInfoIcon)`
  ${commonIconStyle};
  transform: rotateZ(180deg);
  background-color: ${Colors.YellowSoda()};
  color: ${Colors.DarkGray()};
`;

const InfoIcon = styled(SvgInfoIcon)`
  ${commonIconStyle};
  background-color: ${Colors.LightBlue()};
  color: ${Colors.NavyBlue()};
`;
