import React from 'react';
import { Link } from 'react-router-dom';
import { Field as FormikField, FieldProps, Formik, Form } from 'formik';
import styled from 'styled-components';

import { useErrorHandler } from '@topia.com/shared/error-handler';
import { Button, Heading, TextField, FormField, Stack, Text } from '@topia.com/topia-ui';
import { useAsyncAction } from '@topia.com/ui-kit/hooks';

import { sendForgotPassword } from '../../../api';
import { forgotPasswordValidationSchema } from '../../data/validation-schema';

interface Props {
  onSuccess: () => void;
}

export const EmailPanel = ({ onSuccess }: Props) => {
  const [forgotPassword] = useAsyncAction(sendForgotPassword);
  const { onAsyncError } = useErrorHandler();

  return (
    <Formik
      initialValues={{ username: '' }}
      onSubmit={async (values) => {
        try {
          await forgotPassword({ email: values.username });
          onSuccess();
        } catch (error) {
          onAsyncError(error);
        }
      }}
      validationSchema={forgotPasswordValidationSchema}>
      {({ isSubmitting }) => (
        <ForgotPasswordForm>
          <Heading as="h1" variant="huge" color="heading-secondary">
            Forgot your
            <br />
            <Heading color="heading-primary">password?</Heading>
          </Heading>
          <Stack direction="vertical" spacing={24}>
            <FormikField name="username">
              {({ field, meta }: FieldProps) => (
                <Stack direction="vertical" spacing={8}>
                  <Text as="label" htmlFor={field.name}>
                    Enter your email and we'll help you recover it.
                  </Text>
                  <FormField
                    variant="inline"
                    error={meta.error}
                    touched={meta.touched}
                    labelFor={field.name}>
                    <TextField
                      {...field}
                      id={field.name}
                      placeholder="Email"
                      type="email"
                      size="large"
                      autoFocus
                      isFullWidth
                    />
                  </FormField>
                </Stack>
              )}
            </FormikField>
            <Button type="submit" size="large" disabled={isSubmitting} isFullWidth>
              Recover Password
            </Button>
            <Stack distribution="center">
              <Link to="/">Back to Sign In</Link>
            </Stack>
          </Stack>
        </ForgotPasswordForm>
      )}
    </Formik>
  );
};

const ForgotPasswordForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: 100%;
`;
