import styled, { css } from 'styled-components';

import { media } from '../MediaQuery';
import { Colors } from '../Colors';

const COLUMNS = 12;
const GUTTER = '24px';

interface RowProps {
  columns?: number;
}

interface ItemProps {
  offset?: number;
  size: number;
  mobileOffset?: number;
  mobileSize?: number;
  testId?: string;
}

const GridItem = styled.div.attrs({'data-testclass':'item'})`
  flex: 1;
  grid-column: ${({ offset }: ItemProps) => (offset ? `col-start ${offset + 1} /` : '')} span
    ${({ size }: ItemProps) => size};

  ${media.mobile`
    grid-column: ${({ mobileOffset, offset }: ItemProps) =>
      getOffset(mobileOffset, offset) ? `col-start ${getOffset(mobileOffset, offset)} /` : ''} span
      ${({ mobileSize, size }: ItemProps) => mobileSize || size};
  `};
`;

const getOffset = (mobile: number | undefined, def: number | undefined) => {
  if (typeof mobile === 'number') return mobile + 1;
  if (typeof def === 'number') return def + 1;
  return '';
};

const debugOverlay = css`
  &,
  ${/* sc-selector */ GridItem} {
    position: relative;
  }

  &::after,
  ${/* sc-selector */ GridItem}::after {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    z-index: 10000;
    content: '';
    width: 100%;
    height: 100%;
  }

  &::after {
    background: repeating-linear-gradient(
      to right,
      ${Colors.SeaShell(0.24)},
      ${Colors.SeaShell(0.24)} calc(6.12%),
      transparent calc(6.12%),
      transparent calc(6.12% + ${GUTTER})
    );
  }

  ${/* sc-selector */ GridItem}::after {
    z-index: 10001;
    border: solid 1px ${Colors.SkyBlue(0.8)};
  }
`;

const GridRow = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: repeat(${({ columns = COLUMNS }: RowProps) => columns}, [col-start] 1fr);
  grid-column-gap: ${GUTTER};
  grid-row-gap: 24px;

  body.debug-grid & {
    ${debugOverlay};
  }
`;

/**
 *  12 column grid
 */
export const Grid = GridRow as typeof GridRow & { Item: typeof GridItem };
Grid.Item = GridItem;
