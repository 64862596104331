import { getI18n } from 'react-i18next';

export const getTranslationWithDefault = (translationKey: string) => {
  const i18n = getI18n();
  // @ts-ignore
  return i18n.exists(translationKey) ? i18n.t(translationKey) : defaultLabels[translationKey];
};

export const defaultLabels = {
  'shared.packages.yes': 'Yes',
  'shared.packages.no': 'No',
  'shared.packages.cancel': 'Cancel',
  'shared.packages.fieldIsRequired': 'This field is required.',
  'shared.packages.notNow': 'Not Now',
  'shared.packages.delete': 'Delete',
  'shared.packages.save': 'Save',
  'shared.packages.addFilter': 'Add Filter',
  'shared.packages.clear': 'Clear',
  'shared.packages.savedViews': 'Saved Views',
  'shared.packages.savedViewExplanation':
    'A Saved View displays your chosen filters and columns, ordered and sorted as you need.',
  'shared.packages.setAsPreferredView': 'Set as preferred view',
  'shared.packages.revert': 'Revert to original Saved View',
  'shared.packages.saveAs': 'Save as…',
  'shared.packages.update': 'Update',
  'shared.packages.apply': 'Apply',
  'shared.packages.newSavedView': 'New Saved View',
  'shared.packages.name': 'Name',
  'shared.packages.error.nameInUse': 'This name is already in use.',
  'shared.packages.deleteThisView': 'Delete this saved view?',
  'shared.packages.savedFilters': 'Saved Filters',
  'shared.packages.deleteTitle': 'You are about to delete a saved filter:',
  'shared.packages.deleteFilter': 'Delete saved filter?',
  'shared.packages.newFilter': 'New Saved Filter',
  'shared.packages.updateFilter': 'Update Filter',
  'shared.packages.saveFilter': 'Save Filter',
  'shared.packages.filterName': 'Filter Name',
  'shared.packages.noFilters': 'No saved filters',
  'shared.packages.filter': 'Filter',
  'shared.packages.choose': 'Choose...',
  'shared.packages.selected': 'selected',
  'shared.packages.itemsNumber': 'items out of',
  'shared.packages.equals': 'Is equal to',
  'shared.packages.notEquals': 'Is not equal to',
  'shared.packages.empty': 'Is empty',
  'shared.packages.notEmpty': 'Is not empty',
  'shared.packages.contains': 'Contains',
  'shared.packages.notContains': 'Does not contain',
  'shared.packages.lessThan': 'Lesser than',
  'shared.packages.lessThanOrEquals': 'Lesser than or equal to',
  'shared.packages.greaterThan': 'Greater than',
  'shared.packages.greaterThanOrEquals': 'Greater than or equal to',
  'shared.packages.between': 'Is between',
  'shared.packages.notBetween': 'Is not between',
  'shared.packages.isIn': 'Is in',
  'shared.packages.inRange': 'Is in range',
  'shared.packages.inYear': 'Is in year',
  'shared.packages.inPast': 'Is in past',
  'shared.packages.inNext': 'Is in next',
  'shared.packages.before': 'Is before',
  'shared.packages.onOrAfter': 'Is on or after',
  'shared.packages.thisWeek': 'This week',
  'shared.packages.thisMonth': 'This month',
  'shared.packages.thisQuarter': 'This quarter',
  'shared.packages.thisYear': 'This year',
  'shared.packages.thisWeekToDate': 'This week to date',
  'shared.packages.thisMonthToDate': 'This month to date',
  'shared.packages.thisQuarterToDate': 'This quarter to date',
  'shared.packages.thisYearToDate': 'This year to date',
  'shared.packages.tomorrow': 'Tomorrow',
  'shared.packages.yesterday': 'Yesterday',
};
