import React from 'react';
import { Link } from 'react-router-dom';
import { Field as FormikField, FieldProps, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Button, Heading, Stack, Checkbox, FormField } from '@topia.com/topia-ui';

interface Props {
  customUrl?: string;
  customerName?: string;
  eulaRequired: boolean;
  onContinue: () => void;
}

export const TermsAndConditionsPanel = ({
  onContinue,
  customUrl,
  customerName,
  eulaRequired,
}: Props) => (
  <Formik
    initialValues={{
      customUrl,
      customerName,
      eulaRequired,
      accepts: false,
      privacy: false,
    }}
    onSubmit={onContinue}
    validationSchema={Yup.object().shape({
      accepts: Yup.boolean().when(['eulaRequired'], {
        is: true,
        then: Yup.boolean().oneOf([true], 'Please read and agree.'),
      }),
      privacy: Yup.boolean().when(['customUrl', 'customerName'], {
        is: (url, name) => url && name,
        then: Yup.boolean().oneOf([true], 'Please read and agree.'),
      }),
    })}>
    {() => (
      <TermsAndConditionsForm>
        <Heading as="h1" variant="huge" color="heading-secondary">
          Review our
          <br />
          <Heading color="heading-primary">policies.</Heading>
        </Heading>
        <Stack direction="vertical" spacing={24}>
          {eulaRequired && (
            <FormikField name="accepts">
              {({ field, meta }: FieldProps) => (
                <FormField variant="inline" error={meta.error} touched={meta.touched}>
                  <Checkbox {...field} checked={field.value}>
                    I have read Topia's{' '}
                    <a target="_blank" href="https://www.topia.com/privacy-policy/">
                      Privacy Policy
                    </a>{' '}
                    and agree to the{' '}
                    <a target="_blank" href="https://www.topia.com/terms-of-service/">
                      Terms of Service
                    </a>
                  </Checkbox>
                </FormField>
              )}
            </FormikField>
          )}
          {customUrl && customerName && (
            <FormikField name="privacy">
              {({ field, meta }: FieldProps) => (
                <FormField variant="inline" error={meta.error} touched={meta.touched}>
                  <Checkbox {...field} checked={field.value}>
                    I have read {customerName}{' '}
                    <a target="_blank" href={customUrl}>
                      Privacy Policy
                    </a>
                  </Checkbox>
                </FormField>
              )}
            </FormikField>
          )}
          <Button type="submit" size="large" isFullWidth>
            Submit
          </Button>
          <Stack distribution="center">
            <Link to="/">Back to Sign In</Link>
          </Stack>
        </Stack>
      </TermsAndConditionsForm>
    )}
  </Formik>
);

const TermsAndConditionsForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 48px;
  min-height: 100%;
`;
