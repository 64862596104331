import React from 'react';
import styled from 'styled-components';

import { Colors } from '../Colors';

interface Props {
  /**
   * Progress number between 0 and 1
   */
  progress: number;
  /**
   * Text to be shown along with the progress bar
   */
  label?: React.ReactElement;
  /**
   * Color of the progress bar element
   */
  color?: string;
}

/**
 * Progress Bar component
 */
export const ProgressBar: React.FC<Props> = ({ progress, label, color }) => {
  const progressPercent = Math.round(Math.min(1, Math.max(progress, 0)) * 100);
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <Bar>
        <Filled color={color} width={progressPercent} />
      </Bar>
    </Wrapper>
  );
};

interface ProgressBarProps {
  width: number;
  color?: string;
}

const Wrapper = styled.div`
  width: 100%;
`;

const Label = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Bar = styled.div`
  width: 100%;
  height: 2px;
  display: flex;
  background: ${Colors.DarkGray(0.08)};
`;

const Filled = styled.div<ProgressBarProps>`
  height: 100%;
  background: ${props => `${props.color || Colors.SkyBlue()}`};
  width: ${props => `${props.width}%`};
`;
