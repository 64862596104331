import { ResolvedFlags, FlagList } from './types';

interface Overrides {
  [key: string]: boolean | undefined;
}

const OVERRIDES_KEY = 'feature-flag-overrides';

/**
 * Load feature flag overridew
 */
export const loadOverrides = () => {
  try {
    return JSON.parse(sessionStorage.getItem(OVERRIDES_KEY) || '{}') as Overrides;
  } catch {
    return {};
  }
};

/**
 * Persist feature flag overridew
 */
export const saveOverrides = (overrides: Overrides) => {
  try {
    const existing = loadOverrides();
    sessionStorage.setItem(OVERRIDES_KEY, JSON.stringify({ ...existing, ...overrides }));
  } catch {}
};

/**
 * Convert flag list to final resolved flags object
 */
export const resolveFlags = <FL extends FlagList>(flags: FL) =>
  Object.fromEntries(
    Object.entries(flags).map(([key, flag]) => [
      key,
      typeof flag.overridden === 'boolean' ? flag.overridden : flag.isEnabled,
    ]),
  ) as ResolvedFlags<FL>;
