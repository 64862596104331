import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field as FormikField, FieldProps, Form, Formik } from 'formik';
import { capitalize } from 'lodash';

import { Notification } from '@topia.com/shared/components/Notification';
import { PasswordCriterion } from '@topia.com/shared/components/PasswordCriterion';
import { useErrorHandler } from '@topia.com/shared/error-handler';
import {
  formatCustomerPasswordCriteria,
  validatePasswordForm,
} from '@topia.com/shared/utils/passwordHelpers';
import { Button, Heading, Spacer, FormField, TextField, Text, Stack } from '@topia.com/topia-ui';
import { Spinner } from '@topia.com/ui-kit';
import { useAsyncAction, useAsync } from '@topia.com/ui-kit/hooks';

import { resetPassword, getPasswordRules, findToken, validatePassword } from '../../../api';
import { resetPasswordValidationSchema } from '../../data/validation-schema';

interface ValidationError {
  field: string;
  message: string;
}

interface Props {
  token: string;
  type: 'activation' | 'password-reset' | 'password-expired';
}

export const PasswordPanel = ({ token, type }: Props) => {
  const { onAsyncError } = useErrorHandler();
  const navigate = useNavigate();
  const [serverErrors, setServerErrors] = useState<ValidationError[]>();

  const [updatePassword] = useAsyncAction(resetPassword);
  const [validatePasswordAction] = useAsyncAction(validatePassword);
  const findTokenCall = useAsync(findToken, { params: { token } });
  const { group = '', topiaPersonId = 0 } = findTokenCall.data! || {};
  const getPasswordRulesCall = useAsync(getPasswordRules, {
    params: { code: group },
    defer: !findTokenCall.data || findTokenCall.isLoading,
  });

  if (
    findTokenCall.isLoading ||
    !findTokenCall.data ||
    getPasswordRulesCall.isLoading ||
    !getPasswordRulesCall.data
  ) {
    return <Spinner />;
  }

  const customerPaswordCriteria = getPasswordRulesCall.data;

  const passwordCriteria = formatCustomerPasswordCriteria(customerPaswordCriteria);
  const hasValidRules =
    customerPaswordCriteria.pw_formatRules &&
    customerPaswordCriteria.pw_formatRules.numberOfValidRules > 0;

  return (
    <Formik
      initialValues={{
        password: '',
        passwordRepeat: '',
      }}
      onSubmit={async (values) => {
        try {
          setServerErrors(undefined);
          await validatePasswordAction({ group, topiaPersonId, password: values.password });
          await updatePassword({ token, password: values.password });
          navigate('/');
        } catch (error) {
          if (error.response?.status === 401 || error.response?.status === 400) {
            const body: { errors: ValidationError[] } = await error.response.json();
            setServerErrors(body.errors);
          } else {
            onAsyncError(error);
          }
        }
      }}
      validate={({ password, passwordRepeat }) =>
        validatePasswordForm(password, passwordRepeat, passwordCriteria, customerPaswordCriteria)
      }
      validationSchema={resetPasswordValidationSchema}>
      {({ values, isSubmitting, isValid, dirty }) => (
        <Form>
          {type === 'password-expired' ? (
            <Heading as="h1" variant="huge" color="heading-secondary" data-testid="title">
              Password <br />
              <Heading color="heading-primary">expired.</Heading>
            </Heading>
          ) : (
            <Heading as="h1" variant="huge" color="heading-secondary" data-testid="title">
              {type === 'activation' ? 'Set' : 'Reset'} your <br />
              <Heading color="heading-primary">password.</Heading>
            </Heading>
          )}
          <Spacer size={48} />
          {type === 'password-expired' && (
            <>
              <Text>Create a new password to continue.</Text>
              <Spacer size={24} />
            </>
          )}
          {serverErrors && (
            <>
              <Stack direction="vertical" spacing={8}>
                {serverErrors.map((serverError) => (
                  <Notification variant="warning" key={serverError.message}>
                    <Text>{capitalize(serverError.message)}</Text>
                  </Notification>
                ))}
              </Stack>
              <Spacer size={32} />
              <Spacer size={8} />
            </>
          )}
          <FormikField name="password">
            {({ field, meta }: FieldProps) => (
              <FormField variant="inline" error={meta.error} touched={meta.touched}>
                <TextField
                  {...field}
                  size="large"
                  placeholder="Enter Password"
                  type="password"
                  autoFocus
                  isFullWidth
                />
              </FormField>
            )}
          </FormikField>
          <Spacer size={8} />
          <FormikField name="passwordRepeat">
            {({ field, meta }: FieldProps) => (
              <FormField variant="inline" error={meta.error} touched={meta.touched}>
                <TextField
                  {...field}
                  size="large"
                  placeholder="Repeat Password"
                  type="password"
                  isFullWidth
                />
              </FormField>
            )}
          </FormikField>
          <Spacer size={32} />
          <Stack direction="vertical" spacing={8} data-testid="password-criteria-panel">
            <Text as="p" weight="bold" data-testid="description">
              {hasValidRules
                ? `Your password must include ${customerPaswordCriteria.pw_min_length.minLength} characters and ${customerPaswordCriteria.pw_formatRules?.numberOfValidRules} of the following:`
                : 'Your password must include: '}
            </Text>
            {passwordCriteria.map((criterion) => (
              <PasswordCriterion
                key={criterion.regex}
                matches={!!values.password.match(criterion.regex)}>
                {criterion.label}
              </PasswordCriterion>
            ))}
          </Stack>
          <Spacer size={32} />
          <Button
            type="submit"
            size="large"
            disabled={isSubmitting || !isValid || !dirty}
            isFullWidth>
            {type === 'activation' ? 'Continue' : 'Change Password'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
