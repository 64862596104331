import React from 'react';
import styled, { css } from 'styled-components';
import { darken, shade, transparentize } from 'polished';

import { media } from '../MediaQuery';
import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';
import { TextSize } from '../TextSize';

/**
 * Custom button props
 */
export interface ButtonProps {
  secondary?: boolean;
  tertiary?: boolean;
  warning?: boolean;
  color?: string;
  size?: 'small' | 'normal' | 'large';
}

const regularStyle = css`
  font-size: 14px;
  line-height: 32px;
  padding: 0 24px;
`;

const sizeStyles = {
  small: {
    buttonStyle: css`
      font-size: 12px;
      line-height: 24px;
      padding: 0 16px;
    `,
    shadowSize: '0 0 0 2px',
  },
  normal: {
    buttonStyle: regularStyle,
    shadowSize: '0 0 0 4px',
  },
  large: {
    buttonStyle: css`
      ${TextSize.Medium};
      line-height: 48px;

      padding: 0 40px;

      ${media.mobile`
        ${regularStyle};
      `}
    `,
    shadowSize: '0 0 0 4px',
  },
};

const primaryStyle = css<ButtonProps>`
  color: ${Colors.SeaShell()};
  background-color: ${Colors.NavyBlue()};

  &:hover {
    color: ${shade(0.08, Colors.SeaShell())};
  }

  ${({ size = 'normal' }) => css`
    &:focus {
      box-shadow: ${sizeStyles[size].shadowSize} ${Colors.NavyBlue(0.16)};
    }
  `}
`;

const defaultSecondaryStyle = css<ButtonProps>`
  color: ${Colors.NavyBlue()};

  ${({ size = 'normal', color = Colors.LightBlue() }) => css`
    background-color: ${color};

    &:hover {
      background-color: ${darken(0.04, color)};
    }

    &:focus {
      box-shadow: ${sizeStyles[size].shadowSize} ${transparentize(0.4, color)};
    }
  `}
`;

const warningSecondaryStyle = css<ButtonProps>`
  color: ${Colors.NavyBlue()};

  ${({ size = 'normal', color = Colors.Blonde() }) => css`
    background-color: ${color};

    &:hover {
      background-color: ${shade(0.04, color)};
    }

    &:focus {
      box-shadow: ${sizeStyles[size].shadowSize} ${transparentize(0.4, color)};
    }
  `}
`;

const tertiaryStyle = css<ButtonProps>`
  color: ${Colors.NavyBlue()};
  background-color: ${Colors.DarkGray(0.04)};

  &:hover {
    background-color: ${Colors.DarkGray(0.08)};
  }

  &:focus {
    ${({ size = 'normal' }) => css`
      box-shadow: ${sizeStyles[size].shadowSize} ${Colors.DarkGray(0.08)};
    `}
  }
`;

/**
 * Button style
 */
export const buttonStyle = css<ButtonProps>`
  font-weight: ${FontWeight.SemiBold};
  border-radius: 24px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background-color 240ms ${Easings.ExpoOut}, color 240ms ${Easings.ExpoOut};

  ${({ size = 'normal' }) => sizeStyles[size].buttonStyle};
  ${props => {
    if (props.tertiary) {
      return tertiaryStyle;
    }

    if (props.secondary) {
      if (props.warning) {
        return warningSecondaryStyle;
      }

      return defaultSecondaryStyle;
    }

    return primaryStyle;
  }};

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }
`;

const ButtonEl = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ secondary, warning, color, type = 'button', ...props }, ref) => (
  <button ref={ref} type={type} {...props} />
));

/**
 * Rounded button
 */
export const Button = styled(ButtonEl)`
  ${buttonStyle};
`;
