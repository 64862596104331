import React from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

import { Colors } from '../Colors';
import { ASSETS_URL_ROOT } from '../variables';

interface Props {
  /**
   * Show loader
   */
  isLoading: boolean;
}

/**
 * Big fading T
 */
export const TLoader: React.FunctionComponent<Props> = ({ isLoading }) => {
  const transitions = useTransition(isLoading, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && <TLogo key={key} data-testid="app-loader" style={props} />,
      )}
    </>
  );
};

const TLogo = styled(animated.div as any)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: ${Colors.White()} center no-repeat url('${ASSETS_URL_ROOT}/big-t-logo.svg');
`;
