import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
  }

  #app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
`;
