import React from 'react';
const SvgCloseIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg data-testid="close-icon" height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path
        d="M12 11.086l7.778-7.778 1.414 1.414-7.778 7.778 7.778 7.778-1.414 1.414L12 13.914l-7.778 7.778-1.414-1.414 7.778-7.778-7.778-7.778 1.414-1.414z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgCloseIcon;
