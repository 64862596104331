import React from 'react';
const SvgReloadIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} color={color} {...props}>
      <path
        d="M12 5V2l5 4-5 4V7a6 6 0 106 6h2a8 8 0 11-8-8z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  ),
);
export default SvgReloadIcon;
