import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../Colors';
import { BORDER_RADIUS } from '../variables';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Large blocky style
   */
  large?: boolean;
}

/**
 * Round icon only buttons
 */
export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ large = false, ...props }, ref) => <Button ref={ref} {...props} large={large} />,
);

const Button = styled.button<{ large: boolean }>`
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  ${props =>
    props.large
      ? css`
          border-radius: ${BORDER_RADIUS};
          width: 32px;
          height: 32px;
          padding: 0;
          background-color: ${Colors.White()};
          &:hover {
            background-color: ${Colors.DarkGray(0.04)};
          }
        `
      : css`
          border-radius: 50%;
          width: 24px;
          height: 24px;
          padding: 4px;
          background-color: ${Colors.DarkGray(0.04)};
          &:hover {
            background-color: ${Colors.DarkGray(0.08)};
          }
        `};
`;
