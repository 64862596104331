import React from 'react';
import { Link } from 'react-router-dom';
import { Field as FormikField, FieldProps, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Button, FormField, TextField, Stack, Heading } from '@topia.com/topia-ui';

interface Props {
  onBack: () => void;
}

export const PasswordPanel = ({ onBack }: Props) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Wrapper>
      <Heading as="h1" variant="huge" color="heading-secondary">
        Sign in to <br />
        <Heading color="heading-primary">Topia.</Heading>
      </Heading>
      <Stack direction="vertical" spacing={24}>
        <>
          <FormikField name="username">
            {({ field }: FieldProps) => <UserNameInput {...field} />}
          </FormikField>
          <FormikField name="password">
            {({ field, meta }: FieldProps) => (
              <FormField variant="inline" error={meta.error} touched={meta.touched}>
                <TextField
                  {...field}
                  type="password"
                  placeholder="Password"
                  size="large"
                  autoFocus
                  isFullWidth
                />
              </FormField>
            )}
          </FormikField>
        </>
        <Button type="submit" size="large" disabled={isSubmitting} isFullWidth>
          Sign In
        </Button>
        <Stack distribution="center" spacing={24}>
          <Button onClick={onBack} variant="link">
            Change Email
          </Button>
          <Link to="/forgot-password">Forgot Password?</Link>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: 100%;
`;

const UserNameInput = styled.input`
  display: none;
`;
