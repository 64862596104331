const imageWithDot = (img: CanvasImageSource, color: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = canvas.height = 64;
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  ctx.beginPath();
  ctx.arc(48, 16, 14, 0, 2 * Math.PI);
  ctx.fillStyle = color;
  ctx.strokeStyle = 'white';
  ctx.lineWidth = 4;
  ctx.fill();
  ctx.stroke();

  return canvas.toDataURL('image/png');
};

// TODO: https://bugs.chromium.org/p/chromium/issues/detail?id=970159
const getFaviconHref = (link: HTMLLinkElement) => {
  const preferDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return (link.getAttribute('data-href') || link.href).replace(
    /favicon.*.ico/,
    preferDark ? 'favicon-white.ico' : 'favicon.ico',
  );
};

/**
 * Apply colored dot to favicon
 */
export const applyDotToFavicon = (link: HTMLLinkElement, color: string) => {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.onload = () => {
    const newFavicon = imageWithDot(img, color);
    if (newFavicon === null) return;
    link.href = newFavicon;
  };

  let href = link.getAttribute('data-href');
  if (!href) {
    link.setAttribute('data-href', link.href);
    href = link.href;
  }

  img.src = getFaviconHref(link);
};

/**
 * Reset favicon manually due to Chrome bug
 */
export const resetFavicon = () => {
  document.querySelectorAll<HTMLLinkElement>('link[rel="icon"]').forEach(icon => {
    icon.href = getFaviconHref(icon);
  });
};
