import React, { forwardRef, InputHTMLAttributes, Ref, useRef, useEffect } from 'react';
import { format as dateFnsFormat, parseISO, isValid, parse } from 'date-fns';
import styled, { css } from 'styled-components';
import { DayPickerInputProps, DayPickerProps } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useMergeRefs } from 'use-callback-ref';

import { Input } from '../Input';
import { Colors } from '../../Colors';
import CloseIcon from '../../icons/small/CloseIcon';
import CalendarIcon from '../../icons/CalendarIcon';

import { dayPickerStyles } from './styles';
import { YearMonthSwitcher } from './YearMonthSwitcher';
import { NavBarElement } from './NavbarElement';
import { toUtcDate, getAsMidnightUtcString, getSameDateAtMidnightUtc } from './utils';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../../variables';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  /**
   * Midnight UTC ISO string: YYYY-MM-DDT00:00:00.000Z
   */
  value?: string;
  /**
   * Midnight UTC ISO string: YYYY-MM-DDT00:00:00.000Z
   */
  onChange: (date?: string) => void;
  className?: string;
  dayPickerProps?: DayPickerProps;
  dayPickerInputProps?: Omit<DayPickerInputProps, 'onChange' | 'value' | 'disabled' | 'format'>;
  testId?: string;
}

const defaultDateFormat = 'dd MMM yyyy';

const DayPickerInputWrapper = ({
  className = '',
  name,
  value,
  autoFocus,
  autoComplete = 'off',
  onChange,
  onBlur,
  disabled,
  dayPickerProps,
  dayPickerInputProps,
  testId,
  id,
}: Props) => {
  const dayPickerInputRef = useRef<DayPickerInput>(null);

  const onMonthOrYearChange = (date: Date) => {
    if (!dayPickerInputRef.current) {
      return;
    }
    const dayPicker = dayPickerInputRef.current.getDayPicker();
    dayPicker.showMonth(date);
  };

  const placeholder =
    dayPickerInputProps && dayPickerInputProps.placeholder
      ? dayPickerInputProps.placeholder
      : dateFnsFormat(toUtcDate(new Date()), defaultDateFormat);

  useEffect(() => {
    /*
      `keepFocus={true}` prevents the month and year picker from opening in a custom overlay on iOS.
      And `keepFocus={false}` has an issue with overlay closing on click from outside
      https://github.com/gpbl/react-day-picker/issues/1266.
      Had to add custom detection for click outside since `react-day-picker` v7 is no longer maintained
      and v8 has several breaking changes.
    */
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!dayPickerInputRef.current || !dayPickerInputRef.current.state.showOverlay) {
        return;
      }

      const datePickerWrapper: HTMLElement | null = dayPickerInputRef.current
        .getInput()
        .closest('div[class^="DatePickerInput-"]');

      if (
        dayPickerInputRef.current.state.showOverlay &&
        datePickerWrapper &&
        !datePickerWrapper.contains(event.target as Node)
      ) {
        dayPickerInputRef.current.hideDayPicker();
      }
    };

    document.addEventListener('mousedown', listener, { passive: true });
    document.addEventListener('touchstart', listener, { passive: true });

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);

  return (
    <DayPickerInput
      ref={dayPickerInputRef}
      {...dayPickerInputProps}
      format={defaultDateFormat}
      placeholder={placeholder}
      formatDate={(date, format, locale: any) => dateFnsFormat(date, format, { locale })}
      value={value ? toUtcDate(parseISO(getAsMidnightUtcString(value))) : undefined}
      onDayChange={(date, modifiers) => {
        if (!date || modifiers.disabled) {
          return;
        }
        onChange(getSameDateAtMidnightUtc(date));
      }}
      // call blur event handler on calendar close since typing into input is disabled
      // @ts-ignore // send required attributes for Formik
      onDayPickerHide={() => onBlur?.({ target: { name, id } })}
      parseDate={(dateStr, format, locale: any) => {
        const date = parse(dateStr, format, new Date(), { locale });
        if (isValid(date)) return date;
      }}
      dayPickerProps={{
        ...dayPickerProps,
        showOutsideDays: true,
        firstDayOfWeek: 1,
        weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        captionElement: ({ date, localeUtils }) => {
          return (
            <YearMonthSwitcher
              {...dayPickerProps}
              date={date}
              localeUtils={localeUtils}
              onChange={onMonthOrYearChange}
              disabledDays={dayPickerProps?.disabledDays}
            />
          );
        },
        navbarElement: NavBarElement,
      }}
      component={CustomInput}
      classNames={{
        container: className,
        overlay: 'DayPickerInput-Overlay',
        overlayWrapper: 'DayPickerInput-OverlayWrapper',
      }}
      inputProps={{
        id,
        autoFocus,
        autoComplete,
        name,
        disabled,
        onDayPickerInputChange: onChange,
        readOnly: true,
        'data-testid': testId,
      }}
      keepFocus={false}
    />
  );
};

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onDayPickerInputChange: (date?: string) => void;
}

const CustomInput = forwardRef(
  (
    { name, value, disabled, onDayPickerInputChange, ...props }: CustomInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const innerRef = React.useRef<HTMLInputElement>(null);

    return (
      <Wrapper disabled={disabled}>
        <InputElement
          {...props}
          ref={useMergeRefs([innerRef, ref])}
          name={name}
          value={value}
          disabled={disabled}
          suffix={
            <>
              {value && !disabled && (
                <CloseButton
                  onClick={() => {
                    onDayPickerInputChange(undefined);
                    if (!innerRef.current) {
                      return;
                    }
                    const descriptor = Object.getOwnPropertyDescriptor(
                      HTMLInputElement.prototype,
                      'value',
                    );
                    if (descriptor?.set) {
                      descriptor.set.call(innerRef.current, '');
                    }
                    innerRef.current.dispatchEvent(new Event('change', { bubbles: true }));
                  }}>
                  <CloseIcon />
                </CloseButton>
              )}
              <StyledCalendarIcon />
            </>
          }
        />
      </Wrapper>
    );
  },
);

/**
 * Topia style date picker with faux input
 */
export const DatePickerInput = styled(DayPickerInputWrapper)`
  ${dayPickerStyles}
  width: 176px;

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    top: 8px;
    left: 0;
    z-index: 1000;
    min-width: 244px;

    background-color: ${Colors.White()};
    border-radius: ${BORDER_RADIUS};
    box-shadow: ${DEFAULT_BOX_SHADOW};

    &::before {
      content: '';
      position: absolute;
      top: -7px;
      left: 16px;

      border-bottom: 8px solid ${Colors.White()};
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: 32px;

  ${({ disabled }) =>
    disabled &&
    css`
      ${StyledCalendarIcon} {
        opacity: 0.4;
      }

      ${InputElement} {
        cursor: not-allowed;
        color: ${Colors.DarkGray(0.4)};
      }
    `};
`;

const InputElement = styled(Input)`
  background: ${Colors.White()};
  color: ${Colors.DarkGray()};
  width: 100%;
  cursor: text;

  input {
    color: inherit !important;
  }
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  width: 24px;
  height: 24px;
  pointer-events: none;
`;

const CloseButton = styled.button.attrs({
  type: 'button',
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 8px 0 0;
  background: ${Colors.DarkGray(0.04)};
  border: none;
  border-radius: 100%;
  box-shadow: none;
  appearance: none;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.DarkGray(0.08)};
  }
`;
