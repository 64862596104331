import React from 'react';
const SvgCloseIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={16} viewBox="0 0 16 16" width={16} ref={ref} color={color} {...props} data-testid="close-icon">
      <path
        d="M9.202 8.07l3.606 3.607-1.131 1.131-3.606-3.606-3.677 3.677-1.132-1.131L6.94 8.07 3.192 4.323l1.131-1.131 3.748 3.747 3.677-3.677 1.131 1.132z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgCloseIcon;
