import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../../Colors';
import SettingsIcon from '../../../icons/SettingsIcon';
import { IconButton } from '../../IconButton';

interface SettingsButtonProps {
  active: boolean;
  isUnsaved: boolean;
}

/**
 * Filter applying button with status and dirty indicator
 */
export const SettingsButton = React.forwardRef<HTMLButtonElement, SettingsButtonProps>((props, ref) => (
  <SettingsButtonEl large {...props} ref={ref}>
    <SettingsIcon />
  </SettingsButtonEl>
));

const SettingsButtonEl = styled(IconButton)`
  position: relative;
  margin-right: 16px;
  outline: none;

  &:hover {
    background-color: ${Colors.DarkGray(0.04)};
  }

  &::after {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 16px;
    height: 16px;
    background-color: ${Colors.YellowSoda()};
    border-radius: 50%;
    border: solid 2px ${Colors.White()};
  }

  ${(props: SettingsButtonProps) =>
    props.active &&
    css`
      background-color: ${Colors.NavyBlue()};
      svg {
        color: ${Colors.White()};
      }

      &:hover {
        background-color: ${Colors.NavyBlue(0.8)};
      }
    `};

  ${(props: SettingsButtonProps) =>
    props.isUnsaved &&
    css`
      &::after {
        content: ' ';
      }
    `};
`;

