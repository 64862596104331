import React, { useState } from 'react';

import { FormPage } from '../FormPage';
import { EmailPanel } from './EmailPanel';
import { SuccessPanel } from './SuccessPanel';

enum TabType {
  Form = 'Form',
  Success = 'Success',
}

export const ForgotPasswordView = () => {
  const [tabType, setTabType] = useState(TabType.Form);

  return (
    <FormPage>
      {tabType === TabType.Form && <EmailPanel onSuccess={() => setTabType(TabType.Success)} />}
      {tabType === TabType.Success && <SuccessPanel />}
    </FormPage>
  );
};
