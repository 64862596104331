import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabProps,
  TabsProps,
  TabPanelProps,
  TabListProps,
} from '@reach/tabs';

import { Colors } from '../Colors';
import { TextSize } from '../TextSize';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';

interface WizNavStepProps extends TabProps, React.HtmlHTMLAttributes<HTMLButtonElement> {
  stepNumber?: number;
}

export const WizNavStep = React.forwardRef<HTMLButtonElement, WizNavStepProps>(
  ({ stepNumber, ...props }, ref) => (
    <StepRoot {...props} ref={ref}>
      <StepNumber>{stepNumber!}</StepNumber>
      <StepLabel>{props.children}</StepLabel>
    </StepRoot>
  ),
);

const StepNumber = styled.div`
  margin-right: 8px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: white;
  transition: background-color 560ms ${Easings.ExpoOut};
`;

const StepLabel = styled.div`
  transition: color 560ms ${Easings.ExpoOut};
`;

const StepRoot = styled(Tab)`
  display: flex;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-weight: ${FontWeight.SemiBold};
  ${TextSize.Default};

  &:not(:last-child) {
    margin-right: 32px;
  }

  ${StepNumber} {
    background-color: ${Colors.DarkGray(0.32)};
  }

  ${StepLabel} {
    color: ${Colors.SecondaryGray()};
  }

  &[data-selected] {
    & > {
      ${StepNumber} {
        background-color: ${Colors.NavyBlue()};
      }

      ${StepLabel} {
        color: ${Colors.NavyBlue()};
      }
    }
  }

  &:disabled {
    cursor: default;
  }

  &:disabled:not([data-selected]) {
    ${StepNumber} {
      background-color: ${Colors.DarkGray(0.16)};
    }

    ${StepLabel} {
      color: ${Colors.TertiaryGray()};
    }
  }
`;

interface WizNavStepsProps extends TabListProps, React.HtmlHTMLAttributes<HTMLDivElement> {}

export const WizNavSteps = React.forwardRef<HTMLDivElement, WizNavStepsProps>((props, ref) => (
  <StepsRoot {...props} ref={ref}>
    {
      React.Children.map(props.children, (child, index) =>
        React.cloneElement(child as ReactElement, { stepNumber: index + 1 }),
      ) as any
    }
  </StepsRoot>
));

const StepsRoot = styled(TabList)`
  display: flex;
`;

const NavWrapper = styled(Tabs)`
  & > ${StepsRoot} > ${StepRoot}:nth-child(-n+${({ index }) => index}) {
    ${StepNumber} {
      background-color: ${Colors.NavyBlue(0.4)};
    }

    ${StepLabel} {
      color: ${Colors.NavyBlue(0.4)};
    }
  }
`;

export const WizNav = NavWrapper as React.FC<TabsProps>;

interface WizNavPanelProps extends TabPanelProps, React.HTMLAttributes<HTMLDivElement> {}

export const WizNavPanel = React.forwardRef<HTMLDivElement, WizNavPanelProps>((props, ref) => (
  <PanelRoot {...props} ref={ref}>
    {props.children}
  </PanelRoot>
));

const PanelRoot = styled(TabPanel)`
  &:focus {
    outline: none;
  }
`;

export const WizNavPanels = TabPanels;
