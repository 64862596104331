import React from 'react';
import styled from 'styled-components';

import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';

interface Props {
  className?: string;
  name?: string;
  photo?: string;
}

const keys = [
  Colors.LightGray(),
  Colors.LightBlue(),
  Colors.SeaShell(),
  Colors.YellowSoda(),
  Colors.Blonde(),
  Colors.DarkGray(0.08),
  Colors.Green(0.4),
  Colors.Green(0.6),
];

const getRandomColor = (name: string) => {
  return keys[name.charCodeAt(0) % keys.length];
};

const getInitials = (name = '') => {
  const parts = (name || '').split(' ').map(n => n[0]);
  return [parts[0], parts[parts.length - 1]].filter(n => !!n).join('');
};

/**
 * Simple avatar component with letter fallback
 */
export const Avatar = ({ name = '', photo, className }: Props) => (
  <Wrapper
    data-testid="avatar"
    name={name}
    photo={photo}
    className={className}
    style={{ backgroundImage: photo ? `url(${photo})` : undefined }}>
    {!photo && getInitials(name)}
  </Wrapper>
);

const Wrapper = styled.div<{ name?: string; photo?: string }>`
  background-color: ${p => (p.name ? (p.photo ? 'initial' : getRandomColor(p.name)) : 'none')};
  border: ${p => (p.name ? 'none' : `2px solid ${Colors.LightGray(0.4)}`)};
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  color: ${Colors.NavyBlue()};
  font-size: 12px;
  font-weight: ${FontWeight.SemiBold};
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
`;
