import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Colors, FontWeight } from '@topia.com/ui-kit';

import { resetFavicon, applyDotToFavicon } from './utils';

const colorMap: { [key: string]: string } = {
  DEV: Colors.YellowSoda(),
  LOCAL: Colors.Orange(),
  TEST: Colors.LightGray(),
  UAT: Colors.LightBlue(),
};

// tslint:disable-next-line:completed-docs
export interface LogoTagProps {
  /**
   * DEV, LOCAL, TEST or UAT, otherwise doesn't show anything.
   */
  logoTag?: string;
}

/**
 * Environment indicator on top of logo
 */
export const LogoTag = ({ logoTag }: LogoTagProps) => {
  const color = colorMap[logoTag || ''];

  useEffect(() => {
    const match = window.matchMedia('(prefers-color-scheme: dark)');

    // Safari
    if (!match.addEventListener) {
      return () => null;
    }

    const handler = () => {
      resetFavicon();
      if (!color) return;

      document
        .querySelectorAll<HTMLLinkElement>('link[rel="icon"]')
        .forEach(icon => applyDotToFavicon(icon, color));
    };

    match.addEventListener('change', handler);
    handler();

    return () => {
      match.removeEventListener('change', handler);
    };
  }, []);

  return logoTag ? <BadgeWrapper color={color}>{logoTag}</BadgeWrapper> : null;
};

const BadgeWrapper = styled.div<{ color: string }>`
  position: absolute;
  left: calc(100% - 12px);
  bottom: calc(100% - 8px);
  background-color: ${p => p.color};
  color: ${Colors.White()};
  border-radius: 7px;
  border: solid 2px ${Colors.White()};
  padding: 1px 4px;
  font-size: 11px;
  line-height: 12px;
  font-weight: ${FontWeight.SemiBold};
  white-space: nowrap;
`;
