import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import Shared_English_Translation from '@topia.com/shared/locale/en.json';

// @ts-ignore
void i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: Shared_English_Translation,
      label: 'English',
      locale: 'en-IE',
    },
  },
  keySeparator: '.',
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
