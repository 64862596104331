import React from 'react';
import { useTransition } from 'react-spring';
import styled from 'styled-components';

import { useAlertContext } from './AlertProvider';
import { Alert } from './Alert';

interface Props {
  channel?: string;
  className?: string;
}

/**
 * Alerts renderer
 */
export const AlertRenderer = ({ className, channel = 'default' }: Props) => {
  const { alerts, dismissAlert } = useAlertContext();

  const transitions = useTransition(alerts.filter(a => a.channel === channel), item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <Wrapper className={className} data-testid="alert-popup">
      {transitions.map(({ item, key, props }) => (
        <Alert key={key} item={item} style={props} onClose={() => dismissAlert(item.id)} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  top: 40px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
