import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { Colors, Easings } from '@topia.com/ui-kit';
import QuicklookIcon from '@topia.com/ui-kit/icons/QuicklookIcon';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '@topia.com/ui-kit/variables';
import { useOnClickOutside, useKeyPress } from '@topia.com/ui-kit/hooks';

interface Props {
  active: boolean;
}

export const ManagerOverlay: React.FC<Props> = ({ children, active }) => {
  const node = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState(false);
  useOnClickOutside(node, () => setVisible(false));
  useKeyPress('escape', () => setVisible(false));

  return (
    <Container data-testid="flag-manager" ref={node}>
      {isVisible && <Body>{children}</Body>}
      <ToggleButton onClick={() => setVisible(!isVisible)} className={active ? 'active' : ''}>
        <QuicklookIcon color={Colors.DarkGray(0.24)} />
      </ToggleButton>
    </Container>
  );
};

const ToggleButton = styled.div`
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 240ms ${Easings.ExpoOut};

  svg {
    transition: color 240ms ${Easings.ExpoOut};
  }

  &:hover {
    background-color: ${Colors.DarkGray(0.04)};
  }

  &:hover svg,
  &.active svg {
    color: ${Colors.NavyBlue()};
  }
`;

const Body = styled.div`
  position: absolute;
  bottom: 100%;
  padding: 12px;
  margin-bottom: 4px;
  border-radius: ${BORDER_RADIUS};
  background-color: ${Colors.White()};
  box-shadow: ${DEFAULT_BOX_SHADOW};
  z-index: 1;
`;

const Container = styled.div`
  position: fixed;
  left: 20px;
  bottom: 16px;
  z-index: 1000000;
`;
