import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../Colors';
import { TextSize } from '../../TextSize';

interface ProgressBarProps {
  label: string;
  value: number;
}

/**
 * ProgressBar style loading indicator
 */
export const ProgressBar = ({ label, value }: ProgressBarProps) => (
  <ProgressContainer>
    <ProgressInner>
      <Bar>
        <div style={{ width: `${value * 100}%` }} />
      </Bar>
      <div>
        <span>{label}</span>
        <span>{formatPercentage(value)}</span>
      </div>
    </ProgressInner>
  </ProgressContainer>
);

const ProgressContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 44px;
  background-color: ${Colors.White()};
`;

const ProgressInner = styled.div`
  width: 100%;
  margin-bottom: -24px;
  ${TextSize.Small};
  color: ${Colors.DarkGray(0.4)};

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

const Bar = styled.div`
  width: 100%;
  height: 8px;
  margin-bottom: 8px;
  background-color: ${Colors.DarkGray(0.08)};

  &,
  > div {
    border-radius: 4px;
  }

  > div {
    height: 100%;
    background-color: ${Colors.NavyBlue()};
  }
`;

const formatPercentage = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  style: 'percent',
}).format;
