import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Stack, TileIcon, Heading } from '@topia.com/topia-ui';

export const ExpiredPanel = () => {
  const navigate = useNavigate();

  return (
    <Root data-testid="expired-panel">
      <ExpiredIcon>
        <TileIcon icon="unavailable" data-testid="cross-icon" />
      </ExpiredIcon>
      <Stack direction="vertical" spacing={32}>
        <Heading as="p" variant="heading2" data-testid="description">
          This link has expired.
        </Heading>
        <Button onClick={() => navigate('/forgot-password')} size="large" isFullWidth>
          Request New Link
        </Button>
      </Stack>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: 100%;
  text-align: center;
`;

const ExpiredIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 152px;
  width: 152px;
  margin: 0 auto;
  color: var(--topia-ui-tint-navy);
  border-radius: 50%;
  background-color: var(--topia-ui-tint-limestone);
  background-image: linear-gradient(225deg, hsla(0, 0%, 100%, 0.56), hsla(0, 0%, 100%, 0) 100%);

  svg {
    --icon-color: inherit;
  }
`;
