import { Colors } from './Colors';

/**
 * Default border radius
 */
export const BORDER_RADIUS = '6px';

/**
 * Large border radius
 */
export const BORDER_RADIUS_LARGE = '16px';

/**
 * URL root for static assets
 */
export const ASSETS_URL_ROOT = 'https://ui-kit.topia.com/1.6.0';

/**
 * Standard shadow
 */
export const DEFAULT_BOX_SHADOW = `0 1px 2px 0 ${Colors.DarkGray(
  0.16,
)}, 0 32px 48px -8px ${Colors.DarkGray(0.08)}, 0 4px 32px 0 ${Colors.DarkGray(0.12)};`;

/**
 * Large Z-Index
 */
export const Z_INDEX_LARGE = 999;
