import React, { useRef, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import styled, { css } from 'styled-components';

import { Colors } from '../../Colors';
import { MediaQuery } from '../../MediaQuery';
import FileUploadIcon from '../../icons/large/FileUploadIcon';
import FileUploadMultipleIcon from '../../icons/large/FileUploadMultipleIcon';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { AttachedFile, AttachedFilePreview } from './AttachedFile';
import { ProgressBar } from './ProgressBar';

interface Props extends DropzoneOptions {
  className?: string;
  /**
   * Files that have been uploaded to this input
   */
  attachedFiles?: AttachedFile[];
  /**
   * Progress bar or spinner
   */
  progress?: boolean | number;

  /**
   * Remove attachment callback
   */
  onRemove?: (index: number, file: AttachedFile) => void;

  /**
   * Download attachment callback
   */
  onRequestDownload?: (index: number, file: AttachedFile) => void;

  /**
   * Use button instead of dropzone
   */
  noDropZone?: boolean;

  /**
   * File validation schemaa
   */
  validationSchema?: ValidationSchema;

  /**
   * Test attribute for test automation
   */
  testId?: string;

  maxWidth?: boolean;
}

/**
 * File validation schema (single)
 */
export interface ValidationSchema {
  /**
   * Is file size accepted
   */
  fileSize: (fileSize: number, maxSize: number) => { validate: boolean; errorText: string };

  /**
   * Is file type accepted
   */
  fileType: (
    fileType: string,
    acceptedFileTypes: string[],
  ) => { validate: boolean; errorText: string };

  /**
   * Is image(file) has valid aspect ratio
   */
  imageAspectRatio?: (
    naturalWidth: number,
    naturalHeight: number,
    acceptedRatio: number,
  ) => { validate: boolean; errorText: string };
}

/**
 * react-dropzone based file upload input
 */
// tslint:disable-next-line: cyclomatic-complexity
export const FileUpload = ({
  className,
  progress,
  attachedFiles = [],
  onRemove,
  onRequestDownload,
  noDropZone = false,
  onDrop,
  onDropAccepted,
  onDropRejected,
  validationSchema,
  accept,
  maxSize,
  testId,
  maxWidth,
  ...passedOptions
}: Props) => {
  const isUploading = progress === true || typeof progress === 'number';
  const options = {
    ...passedOptions,
    validationSchema,
    accept,
    onDrop,
    maxSize,
    onDropAccepted: (event: any[]) => {
      if (!passedOptions.multiple && validationSchema) {
        const img = new Image();
        img.src = window.URL.createObjectURL(event[0]);
        img.onload = (ev: any) => {
          if (validationSchema.imageAspectRatio) {
            const evPath = (ev.composedPath ? ev.composedPath() : ev.path)[0];
            const imgWidth = evPath.naturalWidth;
            const imgHeight = evPath.naturalHeight;
            const imageAspectRatioValidate = validationSchema.imageAspectRatio(
              imgWidth,
              imgHeight,
              imgWidth / imgHeight,
            );
            if (!imageAspectRatioValidate.validate) {
              setValidateUploadFile(true);
              setErrorCode(imageAspectRatioValidate.errorText);
            }
          }
        };
      }
    },
    onDropRejected: (event: any[]) => {
      if (!passedOptions.multiple && validationSchema) {
        const fileType = event.map((e) => e.type)[0];
        const fileSize = event.map((e) => e.size)[0];
        const { validate: sizeValidation, errorText: sizeErrorMessage } = validationSchema.fileSize(
          fileSize,
          maxSize || fileSize,
        );
        const { validate: typeValidation, errorText: typeErrorMessage } = validationSchema.fileType(
          fileType,
          Array.isArray(accept) ? accept : [accept || ''],
        );
        if (!sizeValidation) {
          setValidateUploadFile(true);
          setErrorCode(sizeErrorMessage);
        }
        if (!typeValidation) {
          setValidateUploadFile(true);
          setErrorCode(typeErrorMessage);
        }
      }
    },
    multiple: passedOptions.multiple,
    disabled: isUploading || passedOptions.disabled || false,
  };
  const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone(options);
  const { multiple, disabled } = options;
  const [validateUploadFile, setValidateUploadFile] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const inputFile = useRef<HTMLInputElement>(null);
  const label = multiple ? 'Choose Files' : 'Choose a File';

  const addFileButton = (
    <AddFileButton
      secondary
      disabled={options.disabled}
      color={Colors.DarkGray(0.04)}
      type="button"
      onClick={() => {
        if (!inputFile.current) {
          return;
        }

        inputFile.current.click();
      }}>
      {multiple && 'Add Files...'}
      {!multiple && (attachedFiles.length > 0 ? 'Replace File' : 'Add a File')}
    </AddFileButton>
  );

  return (
    <Wrapper className={className} data-testid={testId} $maxWidth={maxWidth}>
      {attachedFiles.length > 0 && (
        <AttachedFilesWrapper>
          {attachedFiles.map((f, idx) => (
            <AttachedFilePreview
              key={idx}
              {...f}
              onRemove={onRemove ? () => onRemove(idx, f) : undefined}
              onRequestDownload={onRequestDownload ? () => onRequestDownload(idx, f) : undefined}
            />
          ))}
        </AttachedFilesWrapper>
      )}
      <input
        hidden
        accept={Array.isArray(accept) ? accept.join(',') : accept}
        multiple={multiple}
        ref={inputFile}
        type="file"
        onChange={(event) => {
          if (onDrop) {
            const files = Array.from(event.target.files || []);
            onDrop(files, [], event);
          }
        }}
      />
      {noDropZone ? (
        addFileButton
      ) : (
        <>
          <MediaQuery.Default>
            <Container
              {...getRootProps()}
              active={isDragActive}
              focused={isFocused}
              loading={isUploading}
              disabled={disabled}
              isValidated={validateUploadFile}>
              <input {...getInputProps()} />
              {multiple ? <FileUploadMultipleIcon /> : <FileUploadIcon />}

              <Button secondary>
                {multiple && label}
                {!multiple && (attachedFiles.length > 0 ? 'Replace File' : label)}
              </Button>

              <Label>or drag {!multiple && 'one '}here</Label>

              {progress === true && <Spinner label="Uploading..." overlayOpacity={1} />}

              {typeof progress === 'number' && (
                <ProgressBar value={progress} label="Uploading..." />
              )}
            </Container>
          </MediaQuery.Default>
          <MediaQuery.Mobile>{addFileButton}</MediaQuery.Mobile>
          {validateUploadFile && <ErrorCode>{errorCode || ''}</ErrorCode>}
        </>
      )}
    </Wrapper>
  );
};

interface WrapperProps {
  active: boolean;
  focused: boolean;
  loading: boolean;
  disabled: boolean;
  isValidated: boolean;
}

const Wrapper = styled.div<{ $maxWidth?: boolean }>`
  width: ${({ $maxWidth }) => ($maxWidth ? '100%' : '328px')};
};
`;

const AttachedFilesWrapper = styled.div`
  margin-bottom: 24px;
`;

const AddFileButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: dashed 2px ${Colors.DarkGray(0.08)};
  text-align: center;
  padding: 16px;
  cursor: pointer;

  ${({ disabled }: WrapperProps) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};

  ${({ loading }: WrapperProps) =>
    loading &&
    css`
      cursor: progress;
    `};

  border-color: ${({ active, focused, isValidated }: WrapperProps) =>
    (active && !isValidated) || (focused && !isValidated)
      ? Colors.SkyBlue()
      : Colors.DarkGray(0.08)};

  border-color: ${({ isValidated }: WrapperProps) =>
    isValidated ? Colors.Orange() : Colors.DarkGray(0.08)};

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: ${({ disabled }: WrapperProps) =>
      disabled ? Colors.DarkGray(0.08) : Colors.SkyBlue()};
  }

  > svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 8px;
  }
`;

const Label = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${Colors.DarkGray(0.4)};
`;

const ErrorCode = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: ${Colors.Orange()};
`;
