import { css } from 'styled-components';

import { FontWeight } from '../../FontWeight';
import { Colors } from '../../Colors';
import { BORDER_RADIUS } from '../../variables';

/**
 * DayPicker Calendar body styles
 */
const bodyStyles = css`
  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid ${Colors.DarkGray(0.24)};
    color: ${Colors.DarkGray(0.24)};
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  .DayPicker-TodayButton {
    appearance: none;
    border: none;
    margin: 0 auto;
    color: ${Colors.SkyBlue()};
    font-size: 14px;
    cursor: pointer;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 0.7em 0.5em;
    line-height: 1;
    border-radius: 100%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-weight: ${FontWeight.SemiBold};

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${Colors.LightBlue(0.4)}, 0 1px 4px 0 ${Colors.DarkGray(0.08)};
    }

    .DayPicker--interactionDisabled & {
      cursor: default;
    }

    &--today {
      color: ${Colors.Red()};
      font-weight: 700;
    }

    &--outside {
      opacity: 0.4;
      cursor: default;
    }

    &--disabled {
      opacity: 0.2;
      cursor: default;
    }

    &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      position: relative;
      background-color: ${Colors.SeaShell()};

      &:hover {
        background-color: ${Colors.SeaShell(0.4)};
      }
    }

    &:not(.DayPicker--interactionDisabled).DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: ${Colors.DarkGray(0.04)};
    }
  }
`;

/**
 * DayPicker weekday styles
 */
const weekdayStyles = css`
  .DayPicker-Weekdays {
    display: table-header-group;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    text-align: center;

    abbr[title] {
      border-bottom: none;
      text-decoration: none;
    }
  }
`;

/**
 * DayPicker wrapper styles
 */
const wrapperStyles = css`
  .DayPicker {
    display: inline-block;
    font-size: 12px;
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: 10px;
    user-select: none;
    border-radius: ${BORDER_RADIUS};

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${Colors.LightBlue(0.4)}, 0 1px 4px 0 ${Colors.DarkGray(0.08)};
    }
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    border-collapse: separate;
    margin: 0 8px;
    margin-top: 16px;
    border-spacing: 4px;
    user-select: none;
  }
`;

export const dayPickerStyles = css`
  display: inline-block;

  ${wrapperStyles};
  ${weekdayStyles};
  ${bodyStyles};

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 8px;
    padding: 0 8px;
    text-align: left;

    > div {
      font-weight: ${FontWeight.SemiBold};
      font-size: 20px;
      line-height: 24px;
    }
  }
`;
