import React, { useRef, forwardRef, InputHTMLAttributes, Ref, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useElementRect } from '../hooks/useElementRect';
import { Colors } from '../Colors';
import { BORDER_RADIUS } from '../variables';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  prefix?: ReactNode;
  suffix?: ReactNode;
}

const InputFieldControl = forwardRef(
  ({ className, prefix, suffix, ...rest }: Props, ref: Ref<HTMLInputElement>) => {
    const prefixElement = useRef<HTMLSpanElement | null>(null);
    const suffixElement = useRef<HTMLSpanElement | null>(null);

    const prefixRect = useElementRect(prefixElement);
    const suffixRect = useElementRect(suffixElement);

    return (
      <InputWrapper className={className}>
        {prefix && <InputPrefix ref={prefixElement}>{prefix}</InputPrefix>}
        <InputField
          ref={ref}
          prefixWidth={prefixRect ? prefixRect.width : null}
          suffixWidth={suffixRect ? suffixRect.width : null}
          {...rest}
        />
        {suffix && <InputSuffix ref={suffixElement}>{suffix}</InputSuffix>}
      </InputWrapper>
    );
  },
);

const inputWrapperStyles = css`
  color: ${Colors.DarkGray()};
  border: none;
  border-radius: ${BORDER_RADIUS};
  background-color: ${Colors.White()};
  box-shadow: 0 1px 4px 0 ${Colors.NavyBlue(0.16)};
`;

const inputFieldStyles = css`
  padding: 4px 12px;
  font-size: 14px;
  line-height: 24px;
  appearance: none;

  &::placeholder {
    color: ${Colors.DarkGray(0.4)};
  }

  &::-ms-clear {
    display: none;
  }

  &[readonly] {
    cursor: default;
    color: ${Colors.DarkGray(0.56)};
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${Colors.Disabled()};
    background-color: ${Colors.Separator()};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${Colors.LightBlue(0.4)}, 0 1px 4px 0 ${Colors.DarkGray(0.08)};
  }

  &[type='number'] {
    appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;

/**
 * Shared common inputs style
 */
export const inputStyle = css`
  ${inputWrapperStyles};
  ${inputFieldStyles};
`;

const InputWrapper = styled.label`
  ${inputWrapperStyles};
  display: inline-block;
  position: relative;
  color: ${Colors.DarkGray(0.4)};

  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 2px ${Colors.LightBlue(0.4)}, 0 1px 4px 0 ${Colors.DarkGray(0.08)};
  }
`;

const InputField = styled.input<{ prefixWidth: number | null; suffixWidth: number | null }>`
  ${inputFieldStyles};
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 100%;
  padding-left: ${props => (props.prefixWidth ? `${props.prefixWidth}px` : '12px')};
  padding-right: ${props => (props.suffixWidth ? `${props.suffixWidth}px` : '12px')};
  border: none;
  border-radius: inherit;
  background-color: inherit;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const InputPrefix = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

const InputSuffix = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

/**
 * Input element - basis for more complex fields
 */
export const Input = styled(InputFieldControl)``;
