import React, { useRef } from 'react';
import styled from 'styled-components';
// tslint:disable-next-line: no-implicit-dependencies
import { Instance } from 'tippy.js';

import { Colors } from '../../Colors';
import { media } from '../../MediaQuery';
import { TextSize } from '../../TextSize';
import { Popover } from '../Popover';
import { Sortable as SortableComponent } from '../Sortable/Sortable';
import { SortableProps } from '../Sortable/types';
import { AppliedFiltersBar } from './Filter/AppliedBar';
import { FilterButton } from './Filter/Button';
import { FilterEditorPane } from './Filter/EditorPane';
import { FilterSavePane } from './Filter/SavePane';
import { FilterDefinition } from './Filter/types';
import { hasUnsavedChanges } from './Filter/utils';
import { SavedViewButton } from './SavedView/Button';
import { SavedViewPane } from './SavedView/SavedViewPane';
import { SavedViewsDefinition } from './SavedView/types';
import { SettingsButton } from './Sorter/Button';

/**
 * Table header component properties
 */
export interface TableHeaderProps<T> {
  /**
   * Optional table title
   */
  title: React.ReactNode;
  filter?: FilterDefinition;
  savedViewDef?: SavedViewsDefinition;
  tableSorter?: SortableProps<T>;
  children?: React.ReactElement;
}

/**
 * Table header element
 * Used for filtering, custom actions, column selecting
 */
export const TableHeader = <T extends object>({
  children,
  title,
  filter,
  savedViewDef,
  tableSorter,
}: TableHeaderProps<T>) => {
  const hasActiveFilters = hasFilteredColumns(filter);
  const isUnsaved = hasUnsavedChanges(filter);

  const filterPopoverRef = useRef<Instance>();
  const sorterPopoverRef = useRef<Instance>();
  const saviewPopoverRef = useRef<Instance>();

  return (
    <>
      <Container>
        {typeof title === 'string' ? <Title>{title}</Title> : title}
        <Actions>
          {savedViewDef && (
            <Popover
              noPadding
              onCreate={(instance) => (saviewPopoverRef.current = instance)}
              placement="bottom-end"
              appendTo={typeof window !== 'undefined' ? window.document.body : undefined}
              content={
                <div>
                  <SavedViewPane
                    isChanged={savedViewDef.hasUnsavedChanges || false}
                    definition={savedViewDef}
                    onClose={() => {
                      if (saviewPopoverRef.current) saviewPopoverRef.current.hide();
                    }}
                  />
                </div>
              }>
              <SavedViewButton
                active={
                  !!savedViewDef.current &&
                  !(savedViewDef.current.isDefault && savedViewDef.current.isReadOnly)
                }
                isUnsaved={savedViewDef.hasUnsavedChanges || false}
              />
            </Popover>
          )}
          {filter && (
            <Popover
              noPadding
              onCreate={(instance) => (filterPopoverRef.current = instance)}
              placement="bottom-end"
              appendTo={typeof window !== 'undefined' ? window.document.body : undefined}
              content={
                <div>
                  <FilterEditorPane
                    filterColumns={filter.columns}
                    currentValues={filter.values}
                    onApply={(value) => {
                      if (filterPopoverRef.current) filterPopoverRef.current.hide();
                      filter.onChange([...filter.values, value]);
                    }}
                  />
                  {filter.savedFilters && (
                    <FilterSavePane
                      isUnsaved={isUnsaved}
                      currentValues={filter.values}
                      savedFilters={filter.savedFilters}
                      onReset={(values) => filter.onChange(values)}
                    />
                  )}
                </div>
              }>
              <FilterButton active={hasActiveFilters} isUnsaved={isUnsaved} />
            </Popover>
          )}
          {tableSorter && (
            <Popover
              noPadding
              onCreate={(instance) => (sorterPopoverRef.current = instance)}
              placement="bottom-end"
              appendTo={typeof window !== 'undefined' ? window.document.body : undefined}
              content={
                <SortableComponent
                  items={tableSorter.items}
                  title={tableSorter.title}
                  onApply={tableSorter.onApply}
                  onCancel={() =>
                    sorterPopoverRef.current ? sorterPopoverRef.current.hide() : undefined
                  }
                />
              }>
              <SettingsButton active={false} isUnsaved={false} />
            </Popover>
          )}
          <>{children}</>
        </Actions>
      </Container>
      {filter && hasActiveFilters && (
        <AppliedFiltersBar
          filter={filter}
          onAdd={() => filterPopoverRef.current && filterPopoverRef.current.show()}
        />
      )}
    </>
  );
};

const hasFilteredColumns = (filter?: FilterDefinition) => {
  if (filter) {
    return filter.columns.some(({ field: columnField }) =>
      filter.values.some(({ field: valueField }) => valueField === columnField),
    );
  }
  return false;
};

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
    row-gap: 32px;
  `};
`;

const Title = styled.h2`
  ${TextSize.Large};
  margin: 0;
  color: ${Colors.NavyBlue()};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;
