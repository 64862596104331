import React from 'react';
const SvgMinusIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path d="M2 13v-2h20v2z" fill="currentColor" />
    </svg>
  ),
);
export default SvgMinusIcon;
