import React, { useRef } from 'react';
import { Field as FormikField, Form, FieldProps, Formik } from 'formik';
import { sortBy } from 'lodash';
import styled from 'styled-components';

import { Heading, Text, Select, TextField, FormField, Stack, Spacer } from '@topia.com/topia-ui';
import { useAsync } from '@topia.com/ui-kit/hooks';

import { getSsoApplicationInfo } from '../../../api';
import { Product } from '../../../constants';
import { getLoginHostOverride } from '../../../shared/utils/loginHost';
import { UserProduct } from '../../../types';
import { FormPage } from '../FormPage';

const productionProperties = {
  [Product.Manage]: {
    title: 'Topia Suite',
    description: 'Manage your team’s mobile employees, business travelers, and remote workers.',
    color: 'var(--topia-ui-tint-navy)',
  },
  [Product.Go]: {
    title: 'Topia Go',
    description: 'Manage your own mobility and travel.',
    color: 'var(--topia-ui-tint-sky)',
  },
  [Product.Compass]: {
    title: 'Topia Go',
    description: 'Manage your own mobility and travel.',
    color: 'var(--topia-ui-tint-sky)',
  },
  [Product.RemoteWork]: {
    title: 'Remote Work',
    description: 'Explore remote work options.',
    color: 'var(--topia-ui-color-c-minus)',
  },
  [Product.TravelRequest]: {
    title: 'Pre-Travel',
    description: 'Submit a pre-travel assessment.',
    color: 'var(--topia-ui-color-d-minus)',
  },
};

interface Props {
  token: string;
  products: UserProduct[];
  redirectToStandardApp: (userProduct: UserProduct) => void;
}

type SsoFormsRefsObject = Record<UserProduct['product'], HTMLFormElement | null>;

const validate = (value?: string) => {
  if (!value) {
    return 'This field is required.';
  }
};

export const SsoProductPanel = ({ token, products, redirectToStandardApp }: Props) => {
  const getSsoApplicationInfoCall = useAsync(getSsoApplicationInfo, { params: { token } });
  const appInfos = getSsoApplicationInfoCall.data?.appInfos || [];

  const ssoFormsRefs = useRef<SsoFormsRefsObject>({} as unknown as SsoFormsRefsObject);

  const orderedProducts = sortBy(products, ({ product }) => [
    product === Product.Manage,
    product === Product.Go,
    product === Product.Compass,
    product === Product.RemoteWork,
    product === Product.TravelRequest,
  ]).reverse();

  const { hostOverride } = getLoginHostOverride();
  const hostParam = hostOverride ? `?host=${hostOverride}` : '';

  return (
    <FormPage>
      <Stack direction="vertical" spacing={32}>
        {orderedProducts.map((userProduct) => {
          const { title, description, color } = productionProperties[userProduct.product];

          if (![Product.RemoteWork, Product.TravelRequest].includes(userProduct.product)) {
            return (
              <PanelItem
                key={userProduct.product}
                type="button"
                onClick={() => redirectToStandardApp(userProduct)}
                $color={color}>
                <Heading variant="heading1" color="inherit">
                  <PanelItemTitle>{title}</PanelItemTitle>&nbsp;→
                </Heading>
                <Spacer size={8} />
                <Text as="p" variant="primary">
                  {description}
                </Text>
              </PanelItem>
            );
          }

          return (
            <Formik
              key={userProduct.product}
              initialValues={{
                userProduct,
                clientEmployeeId: '',
                appInfo: '',
              }}
              onSubmit={() => {
                ssoFormsRefs.current[userProduct.product]?.submit();
              }}>
              {({ values }) => (
                <>
                  <form
                    ref={(element) => {
                      ssoFormsRefs.current[userProduct.product] = element;
                    }}
                    method="POST"
                    action={`/api/v1/sso-application/redirect${hostParam}`}>
                    <input type="hidden" name="token" value={token} />
                    <input type="hidden" name="appType" value={values.userProduct.product} />
                    {userProduct.product === Product.RemoteWork && (
                      <input
                        type="hidden"
                        name="clientEmployeeId"
                        value={values.clientEmployeeId}
                      />
                    )}
                    {userProduct.product === Product.TravelRequest && (
                      <input type="hidden" name="appInfo" value={values.appInfo} />
                    )}
                  </form>
                  <Form>
                    <PanelItem type="submit" $color={color}>
                      <Heading variant="heading1" color="inherit">
                        <PanelItemTitle>{title}</PanelItemTitle>&nbsp;→
                      </Heading>
                      <Spacer size={8} />
                      <Text as="p" variant="primary">
                        {description}
                      </Text>
                    </PanelItem>
                    <Spacer size={16} />
                    {userProduct.product === Product.TravelRequest && (
                      <FormikField name="appInfo" validate={validate}>
                        {({ field, meta, form }: FieldProps) => (
                          <FormField variant="inline" error={meta.error} touched={meta.touched}>
                            <Select
                              {...field}
                              placeholder="Task"
                              onChange={(value) => form.setFieldValue(field.name, value, true)}
                              options={appInfos.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                            />
                          </FormField>
                        )}
                      </FormikField>
                    )}
                    {userProduct.product === Product.RemoteWork && (
                      <FormikField name="clientEmployeeId" validate={validate}>
                        {({ field, meta }: FieldProps) => (
                          <FormField variant="inline" error={meta.error} touched={meta.touched}>
                            <TextField {...field} placeholder="Employee ID" />
                          </FormField>
                        )}
                      </FormikField>
                    )}
                  </Form>
                </>
              )}
            </Formik>
          );
        })}
      </Stack>
    </FormPage>
  );
};

const PanelItemTitle = styled.span``;

const PanelItem = styled.button<{ $color: string }>`
  width: 328px;
  min-width: 0;
  max-width: 100%;
  padding: 0;
  text-align: left;
  color: ${(props) => props.$color};
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    ${PanelItemTitle} {
      text-decoration: underline;
    }
  }
`;
