import React, { useContext, useState, useEffect } from 'react';
import produce from 'immer';

import { ManagerOverlay } from './components/ManagerOverlay';
import { MiniToggle } from './components/MiniToggle';
import { Flag, FlagList, FlagContextState, FlagManagerProps, FlagProviderProps } from './types';
import { saveOverrides, loadOverrides, resolveFlags } from './utils';

/**
 * Register feature flags
 */
export function registerFlags<FL extends FlagList>(flagList: FL) {
  const FlagContext = React.createContext<FlagContextState<FL> | null>(null);

  /**
   * React context to propagate flags registered throughout app
   */
  const FlagProvider = ({ children }: FlagProviderProps<FL>) => {
    const [flags, setFlags] = useState(flagList);

    const update = (key: keyof FL, updater: (flag: Flag) => void) =>
      setFlags(old =>
        produce(old, draft => {
          updater((draft as any)[key]);
        }),
      );

    const set = (key: keyof FL, value: boolean) => update(key, f => (f.isEnabled = value));

    return (
      <FlagContext.Provider value={{ flags, update, set }}>
        {typeof children === 'function' ? children(resolveFlags(flags)) : children}
      </FlagContext.Provider>
    );
  };

  /**
   * Hook to expose flag context
   */
  const useFlagContext = () => {
    const context = useContext(FlagContext);
    if (context === null) throw new Error('Missing flag context, did you use <FlagProvider>?');
    return context;
  };

  /**
   * Hook to expose flags with their final values
   */
  const useFlags = () => {
    const context = useFlagContext();
    return resolveFlags(context.flags);
  };

  /**
   * Debug UI for feature flags
   */
  const FlagManager: React.FC<FlagManagerProps<FL>> = ({ children, onChange }) => {
    const flagContext = useContext(FlagContext);
    if (!flagContext) throw new Error('Missing flag context');

    const hasOverrides = Object.values(flagContext.flags).some(
      f => f.isEnabled !== f.overridden && typeof f.overridden === 'boolean',
    );

    const resolvedFlags = useFlags();

    useEffect(() => {
      Object.entries(loadOverrides()).forEach(([key, value]) => {
        flagContext.update(key, f => f && (f.overridden = value));
      });
    }, []);

    useEffect(() => {
      if (onChange) onChange(resolvedFlags);
    }, [flagContext.flags]);

    return (
      <ManagerOverlay active={hasOverrides}>
        {children}

        {Object.entries(flagContext.flags).map(([key, { label, overridden, isEnabled }]) => (
          <MiniToggle
            key={key}
            label={label}
            value={typeof overridden === 'boolean' ? overridden : isEnabled}
            onChange={value =>
              flagContext.update(key, f => {
                saveOverrides({ [key]: f.isEnabled === value ? undefined : value });
                f.overridden = value;
              })
            }
          />
        ))}
      </ManagerOverlay>
    );
  };

  return {
    FlagProvider,
    FlagManager,
    useFlagContext,
    useFlags,
  };
}
