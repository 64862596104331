import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { Heading, Stack } from '@topia.com/topia-ui';
import { Spinner } from '@topia.com/ui-kit';

import { FormPage } from './FormPage';

interface StateProps {
  loaded: boolean;
  formAction?: string;
  formToken?: string;
}

export const LoginRedirectView = () => {
  const removeCookie = (cookieName: string): string | undefined => {
    const value = Cookies.get(cookieName);
    Cookies.remove(cookieName);
    return value;
  };

  const [{ loaded, formAction, formToken }, setState] = useState<StateProps>({ loaded: false });

  useEffect(() => {
    setState({
      loaded: true,
      formAction: removeCookie('T-LOGIN-URL'),
      formToken: removeCookie('T-LOGIN-TOKEN'),
    });
  }, []);

  const formRef = useCallback((node) => {
    if (node !== null) {
      node.submit();
    }
  }, []);

  if (!loaded) {
    return <Spinner label="Loading" />;
  }

  if (!formAction || !formToken) {
    console.warn('State cookies not defined');
    return (
      <FormPage>
        <Stack distribution="center">
          <Heading variant="heading2" as="p">
            Missing state parameters
          </Heading>
        </Stack>
      </FormPage>
    );
  }

  return (
    <FormPage>
      <Stack distribution="center">
        <Heading variant="heading2" as="p">
          Redirecting...
        </Heading>
      </Stack>
      <form ref={formRef} method="POST" action={formAction}>
        <input type="hidden" name="token" value={formToken} />
      </form>
    </FormPage>
  );
};
