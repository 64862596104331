import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';

import { Colors } from '@topia.com/ui-kit';

import { LogoTag, LogoTagProps } from './LogoTag';

interface Props extends LogoTagProps {
  /**
   * The target url, with context path added, if needed
   */
  target: string;

  /**
   * Optional customer logo path. If not provided 'Topia Logo' is displayed.
   */
  logoUrl?: string;
}

/**
 * Link with Topia logo
 */
export const NavigationLogo = ({ target, logoTag, logoUrl }: Props) => (
  <LogoLink to={target} data-testid="topia-logo-link">
    <Logo logoTag={logoTag} logoUrl={logoUrl} />
  </LogoLink>
);

export const Logo = ({ logoTag, logoUrl }: Omit<Props, 'target'>) => (
  <LogoImage>
    <LogoTag logoTag={logoTag} />
    {logoUrl ? (
      <img src={logoUrl} />
    ) : (
      <svg height="32" width="32" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.414 12.454a1.23 1.23 0 1 1 0 2.458 1.23 1.23 0 0 1 0-2.458zm0 4.866a3.637 3.637 0 1 0 0-7.274 3.637 3.637 0 0 0 0 7.274zm-4.907-2.372v-2.504H3.662v2.504h5.88v9.712h3.068v-9.712zM16 0C24.836 0 32 7.164 32 16c0 8.837-7.164 16-16 16-8.837 0-16-7.163-16-16C0 7.165 7.163 0 16 0z"
          fill="#003049"
        />
      </svg>
    )}
  </LogoImage>
);

const LogoLink = styled(Link)`
  &:hover svg path {
    fill: ${Colors.DarkGray()};
  }
`;

const LogoImage = styled.span`
  position: relative;
  display: inline-block;
  height: 32px;

  img,
  svg {
    max-height: 32px;
  }
`;
