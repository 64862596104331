import React from 'react';
import styled from 'styled-components';

export const FormPage = ({ children }: React.PropsWithChildren<{}>) => (
  <FormWrapper>{children}</FormWrapper>
);

const FormWrapper = styled.div`
  position: relative;
  min-height: 100%;

  @media screen and (min-width: 768px) {
    width: 408px;
    min-height: 440px;
    padding: 32px;
    background: var(--topia-ui-color-background);
    border-radius: 24px;
    box-shadow: var(--topia-ui-shadow-low);
  }
`;
