import React, { InputHTMLAttributes, useRef, PropsWithChildren } from 'react';
import DayPicker, { Modifier } from 'react-day-picker';
import styled from 'styled-components';
import { format as dateFnsFormat, parseISO } from 'date-fns';

import { dayPickerStyles } from './styles';
import { YearMonthSwitcher } from './YearMonthSwitcher';
import { Dropdown } from '../Dropdown';
import TimeIcon from '../../icons/TimeIcon';
import { NavBarElement } from './NavbarElement';
import { Colors } from '../../Colors';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../../variables';

const timeDropdownOptions = (length: number) =>
  Array.from(Array(length).keys()).map(value => ({
    value: `${value}`.padStart(2, '0'),
    label: `${value}`.padStart(2, '0'),
  }));

const TimePicker = ({
  value,
  onChange,
}: {
  value: { hour: string; minutes: string };
  onChange: (time: { hour: string; minutes: string }) => void;
}) => {
  return (
    <TimeWrapper>
      <TimeIcon />
      <DropdownWrapper>
        <Dropdown
          value={value.hour}
          onChange={event => {
            onChange({ hour: event.target.value, minutes: value.minutes });
          }}
          options={timeDropdownOptions(24)}
        />
        :
        <Dropdown
          value={value.minutes}
          onChange={event => {
            onChange({ hour: value.hour, minutes: event.target.value });
          }}
          options={timeDropdownOptions(60)}
        />
      </DropdownWrapper>
    </TimeWrapper>
  );
};

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  value: string;
  className?: string;
  disabledDays?: Modifier;
  onChange: (dateTime: string) => void;
  timePicker?: boolean;
}

export const DatePicker = ({
  value,
  className,
  onChange,
  disabledDays,
  timePicker,
  children,
}: PropsWithChildren<Props>) => {
  const dayPickerInputRef = useRef<DayPicker>(null);

  const onMonthOrYearChange = (date: Date) => {
    if (!dayPickerInputRef.current) {
      return;
    }
    dayPickerInputRef.current.showMonth(date);
  };

  return (
    <Wrapper className={className}>
      <DatePickerWrapper
        ref={dayPickerInputRef}
        showOutsideDays
        firstDayOfWeek={1}
        disabledDays={disabledDays}
        selectedDays={[parseISO(value)]}
        weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        captionElement={({ date, localeUtils }) => (
          <YearMonthSwitcher
            date={date}
            localeUtils={localeUtils}
            onChange={onMonthOrYearChange}
            disabledDays={disabledDays}
          />
        )}
        onDayClick={(date, modifiers) => {
          if (!date || modifiers.disabled) {
            return;
          }
          if (timePicker) {
            const preserveDate = parseISO(value);
            preserveDate.setFullYear(date.getFullYear());
            preserveDate.setMonth(date.getMonth());
            preserveDate.setDate(date.getDate());
            onChange(preserveDate.toISOString());
          } else {
            onChange(date.toISOString());
          }
        }}
        navbarElement={NavBarElement}
      />
      {timePicker && (
        <>
          <Separator />
          <TimePicker
            value={{
              hour: dateFnsFormat(parseISO(value), 'HH'),
              minutes: dateFnsFormat(parseISO(value), 'mm'),
            }}
            onChange={({ hour, minutes }) => {
              const date = parseISO(value);
              date.setMinutes(parseInt(minutes, 10));
              date.setHours(parseInt(hour, 10));
              onChange(date.toISOString());
            }}
          />
        </>
      )}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: ${BORDER_RADIUS};
  box-shadow: ${DEFAULT_BOX_SHADOW};
  background-color: ${Colors.White()};
`;

export const DatePickerWrapper = styled(DayPicker)`
  ${dayPickerStyles}
`;

const Separator = styled.div`
  border-bottom: 2px solid ${Colors.Separator()};
  width: 100%;
`;

const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
`;
