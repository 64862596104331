import 'core-js/stable';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Application from './modules/application/Application';

render(
  React.createElement(BrowserRouter, { basename: '/' }, React.createElement(Application)),
  document.getElementById('app'),
);

const appLoader = document.getElementById('t-loader');

if (appLoader && appLoader.parentElement) {
  appLoader.parentElement.removeChild(appLoader);
}
