import React, { useState, useEffect } from 'react';

import { ColumnDefinition, SelectionDefinition } from './types';
import { Checkbox } from '../Checkbox';

interface UseSelectionOptions<SC extends keyof D, D> {
  data: D[];
  selection?: SelectionDefinition<D, SC>;
  columns: ColumnDefinition<D>[];
}

/**
 * Enable selection for table
 */
export const useSelection = <D extends object, SC extends keyof D>({
  data,
  columns,
  selection,
}: UseSelectionOptions<SC, D>): [ColumnDefinition<D>[], Set<D[SC]>] => {
  const [internalSelected, setInternalSelected] = useState<Set<D[SC]>>(new Set());

  const setSelected = selection && selection.onChange ? selection.onChange : setInternalSelected;
  const selected = selection && selection.selected ? selection.selected : internalSelected;

  useEffect(() => {
    if (selection && !selection.onChange && !selection.selected) setSelected(new Set());
  }, [data, columns, selection]);

  if (!selection) return [columns, selected];

  const toggleSelection = (key: D[SC], value: boolean, pressedShift: boolean) => {
    const newSelectedIdx = new Set(selected);
    value ? newSelectedIdx.add(key) : newSelectedIdx.delete(key);

    if (pressedShift && selected.size > 0) {
      const previousKeys = Array.from(selected);
      const previousKey = previousKeys[previousKeys.length - 1];
      const keys = data.map(d => d[selection.column]);
      let previousIdx = keys.findIndex(k => k === previousKey);
      let nextIdx = keys.findIndex(k => k === key);
      [previousIdx, nextIdx] =
        previousIdx <= nextIdx ? [previousIdx, nextIdx] : [nextIdx, previousIdx];
      keys.slice(previousIdx, nextIdx).forEach(k => {
        newSelectedIdx.add(k);
      });
    }
    setSelected(newSelectedIdx);
  };

  const allColumns = [
    {
      label: () => (
        <Checkbox
          value={selected.size > 0 ? selected.size === data.length || null : false}
          onChange={value => setSelected(new Set(value ? data.map(r => r[selection.column]) : []))}
        />
      ),
      render(row: D) {
        const key = row[selection.column];

        return (
          <Checkbox
            value={selected.has(key)}
            onChange={(value, pressedShift) => toggleSelection(key, value, pressedShift)}
          />
        );
      },
    },
    ...columns,
  ];

  return [allColumns, selected];
};
