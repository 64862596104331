import React from 'react';
const SvgDropdownIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={16} viewBox="0 0 16 16" width={16} ref={ref} color={color} {...props}>
      <path d="M4 7l4 4 4-4z" fill="currentColor" />
    </svg>
  ),
);
export default SvgDropdownIcon;
