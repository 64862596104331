import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Grid, GridColumn, Stack, Heading, Button } from '@topia.com/topia-ui';

export const SignedOutView = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Grid>
        <GridColumn size={{ base: 12, m: 6 }}>
          <Stack direction="vertical" spacing={48}>
            <Heading as="h1" variant="huge">
              You have been signed out.
            </Heading>
            <Heading as="p" variant="heading2">
              To protect your account, please sign in again.
            </Heading>
            <Button size="large" onClick={() => navigate('/')}>
              Sign In
            </Button>
          </Stack>
        </GridColumn>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1032px;
  margin: 0 auto;
`;
