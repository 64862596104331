import React from 'react';
import styled from 'styled-components';

import {TextSize} from '../TextSize';
import {Colors} from '../Colors';
import {Easings} from '../Easings';

interface Props {
  id?: string;
  className?: string;
  disabled?: boolean;
  /**
   * Label shown on the right side
   */
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  testId?: string;
}

/**
 * Toggle
 */
export const Toggle = ({ id, className, value, onChange, label, disabled, testId }: Props) => (
  <ToggleContainer id={id} className={className} data-testclass="toggle-container" data-testid={testId}>
    <HiddenCheckbox
      onChange={event => onChange(event.target.checked)}
      checked={value}
      disabled={disabled}
    />
    <ToggleKnob />
    {label && <Label>{label}</Label>}
  </ToggleContainer>
);

// tslint:disable-next-line:completed-docs
export const ToggleContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  ${TextSize.Default};
`;

const Label = styled.label.attrs({
  'data-testclass': 'toggle-label',
})`
  margin-left: 16px;
  white-space: nowrap;
  flex: 1;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  width: 48px;
  height: 24px;

  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

const ToggleKnob = styled.div`
  position: relative;
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: ${Colors.DarkGray(0.08)};
  transition: background-color 240ms ${Easings.ExpoOut};

  &::after {
    content: ' ';
    position: absolute;
    width: calc(50% - 8px);
    height: calc(100% - 8px);
    border-radius: 100%;
    margin: 4px;
    background-color: ${Colors.White()};
    box-shadow: 0 1px 2px 0 ${Colors.DarkGray(0.16)}, 0 0 4px 0 ${Colors.DarkGray(0.16)};
    transition: right 240ms ${Easings.ExpoOut};
    right: 50%;
  }

  input:checked + & {
    background-color: ${Colors.Green()};

    &::after {
      right: 0;
    }
  }

  input[disabled]:checked + & {
    background-color: ${Colors.Green(0.4)};
  }
`;

Toggle.Knob = ToggleKnob;
