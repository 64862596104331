import React from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ErrorHandlerProvider } from '@topia.com/shared/error-handler';
import { ThemeProvider } from '@topia.com/topia-ui';
import {
  AlertProvider,
  AlertRenderer,
  ErrorBoundary,
  ErrorOverlay,
  Spinner,
} from '@topia.com/ui-kit';
import PageTitle, { HeadProvider } from '@topia.com/ui-kit/PageTitle';

import config from '../../config';
import { FlagProvider, FlagManager } from '../../features';
import i18next from '../../i18n';
import { LoginView } from '../login/LoginView';
import GlobalStyle from './GlobalStyle';

const globalFallback = (error?: Error) => (
  <ErrorOverlay
    error={error}
    buttonText="Refresh"
    onClose={() => {
      window.location.href = '/';
    }}
    title="An unexpected error has occurred.">
    Please reload the page.
  </ErrorOverlay>
);

const App = () => (
  <HeadProvider>
    <PageTitle />
    <GlobalStyle />

    <ErrorBoundary fallback={globalFallback}>
      <React.StrictMode>
        {/* @ts-ignore */}
        <I18nextProvider i18n={i18next}>
          <AppInner />
        </I18nextProvider>
      </React.StrictMode>
    </ErrorBoundary>
  </HeadProvider>
);

const AppInner = () => {
  const { i18n } = useTranslation();
  return (
    <ThemeProvider locale={i18n.store.data[i18n.resolvedLanguage].locale}>
      <FlagProvider>
        <AlertProvider>
          <ErrorHandlerProvider>
            <AlertRenderer />
            <React.Suspense fallback={<Spinner label="Loading page" />}>
              <Content>
                <LoginView />
              </Content>
            </React.Suspense>
            {config.uiDebugger && (
              <FlagManager
                onChange={(flagMap) => {
                  document.body.classList.toggle('debug-grid', flagMap.uiDebugGrid);
                }}
              />
            )}
          </ErrorHandlerProvider>
        </AlertProvider>
      </FlagProvider>
    </ThemeProvider>
  );
};

export default App;

const Content = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;
